import { Typography, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Section from "../../../kit/section";

import BigNumberCard from "./BigNumberCard";

export default function Section2() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  return (
    <Section
      bg="#262835"
      mwidth="1200px"
      tag="section"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
      sxcont={{
        position: "relative",
        zIndex: theme.zIndex.content,
      }}
    >
      <Typography variant="h3" sx={{ maxWidth: "460px", color: "white" }}>
        О наших приложениях
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
          gap: "40px",
          mt: isMobile ? "40px" : "50px",
        }}
      >
        <BigNumberCard
          indexText="1"
          text="Хорошая адаптируемость к изменениям нагрузки"
          sx={{ maxWidth: isTablet ? (isMobile ? "302px" : "199px") : "260px" }}
          sxHeader={{maxWidth: isMobile ? undefined : "250px"}}
        />
        <BigNumberCard
          indexText="2"
          text="Хорошо интегрируются в Kubernetes"
          sx={{ maxWidth: isTablet ? (isMobile ? "302px" : "199px") : "260px" }}
          sxHeader={{maxWidth: isMobile ? undefined : "230px"}}
        />
        <BigNumberCard
          indexText="3"
          text="Образуют более устойчивую систему"
          sx={{ maxWidth: isTablet ? (isMobile ? "302px" : "199px") : "260px" }}
          sxHeader={{maxWidth: isMobile ? undefined : "250px"}}
        />
        <BigNumberCard
          indexText="4"
          text="Легкий процесс миграции"
          sx={{ maxWidth: isTablet ? (isMobile ? "342px" : "199px") : "260px" }}
        />
      </Box>
    </Section>
  );
}
