import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export default function BubblesMobile({ style }: Props) {
  return (
    <svg
      width="247"
      height="299"
      viewBox="0 0 247 299"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <path
        d="M188.033 121.328C163.35 139.518 170.928 152.894 158.54 159.636C154.043 162.393 150.657 160.922 140.7 160.524C130.744 160.125 102.71 166.048 98.86 193.428C95.0097 220.809 111.016 233.484 116.619 237.515C122.223 241.547 139.405 251.782 147.363 260.6C155.321 269.417 173.874 283.925 191.307 284.678C208.74 285.43 230.861 280.472 246.576 261.047C262.29 241.623 260.71 223.671 260.733 216.923C260.756 210.175 265.113 205.193 270.503 196.895C275.893 188.597 285.53 164.993 270.013 137.401C254.495 109.809 212.716 103.137 188.033 121.328Z"
        fill="url(#paint0_linear_1139_7759)"
      />
      <circle
        cx="37.733"
        cy="37.733"
        r="37.733"
        transform="matrix(-1 0 0 1 260.205 150)"
        fill="url(#paint1_linear_1139_7759)"
      />
      <g filter="url(#filter0_f_1139_7759)">
        <circle
          cx="33.5837"
          cy="33.5837"
          r="33.5837"
          transform="matrix(-1 0 0 1 254.115 156.164)"
          fill="url(#paint2_linear_1139_7759)"
        />
      </g>
      <g filter="url(#filter1_d_1139_7759)">
        <ellipse
          cx="31"
          cy="30.5"
          rx="31"
          ry="30.5"
          transform="matrix(-1 0 0 1 276.74 70)"
          fill="url(#paint3_linear_1139_7759)"
          fillOpacity="0.8"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter2_f_1139_7759)">
        <ellipse
          cx="27"
          cy="26.5"
          rx="27"
          ry="26.5"
          transform="matrix(-1 0 0 1 273.74 74)"
          fill="url(#paint4_linear_1139_7759)"
          fillOpacity="0.8"
        />
      </g>
      <g opacity="0.5">
        <circle
          cx="11.3715"
          cy="11.3715"
          r="11.3715"
          transform="matrix(-1 0 0 1 228.482 11)"
          fill="url(#paint5_linear_1139_7759)"
          fillOpacity="0.8"
        />
        <g filter="url(#filter3_f_1139_7759)">
          <circle
            cx="9.74702"
            cy="9.74702"
            r="9.74702"
            transform="matrix(-1 0 0 1 227.4 13.166)"
            fill="url(#paint6_linear_1139_7759)"
            fillOpacity="0.8"
          />
        </g>
      </g>
      <g opacity="0.5">
        <circle
          cx="23.0998"
          cy="23.0998"
          r="23.0998"
          transform="matrix(-1 0 0 1 56.3262 204.8)"
          fill="url(#paint7_linear_1139_7759)"
          fillOpacity="0.8"
        />
        <g filter="url(#filter4_f_1139_7759)">
          <circle
            cx="20.589"
            cy="20.589"
            r="20.589"
            transform="matrix(-1 0 0 1 54.3174 207.813)"
            fill="url(#paint8_linear_1139_7759)"
            fillOpacity="0.8"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_1139_7759"
          x="173.948"
          y="143.164"
          width="93.167"
          height="93.1675"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_7759" />
        </filter>
        <filter
          id="filter1_d_1139_7759"
          x="203.74"
          y="65"
          width="88"
          height="87"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="8" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_7759" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_7759" result="shape" />
        </filter>
        <filter
          id="filter2_f_1139_7759"
          x="206.74"
          y="61"
          width="80"
          height="79"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_7759" />
        </filter>
        <filter
          id="filter3_f_1139_7759"
          x="194.906"
          y="0.166016"
          width="45.4941"
          height="45.4941"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_7759" />
        </filter>
        <filter
          id="filter4_f_1139_7759"
          x="0.139648"
          y="194.813"
          width="67.1777"
          height="67.178"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_7759" />
        </filter>
        <linearGradient
          id="paint0_linear_1139_7759"
          x1="217.934"
          y1="276.529"
          x2="101.506"
          y2="254.296"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#AC71F8" stopOpacity="0.58" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1139_7759"
          x1="27.1992"
          y1="61.9448"
          x2="69.0638"
          y2="17.5926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#C9A3FA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1139_7759"
          x1="8.97783"
          y1="15.2956"
          x2="57.294"
          y2="57.7248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BF96F2" stopOpacity="0.75" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.53" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1139_7759"
          x1="8.28713"
          y1="13.8911"
          x2="39.2075"
          y2="56.3368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1139_7759"
          x1="7.21782"
          y1="12.0693"
          x2="34.063"
          y2="49.0105"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.447917" stopColor="#E9D8FF" stopOpacity="0.76" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1139_7759"
          x1="3.03991"
          y1="5.17911"
          x2="14.6244"
          y2="20.8251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1139_7759"
          x1="2.60564"
          y1="4.43924"
          x2="12.5352"
          y2="17.8501"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.447917" stopColor="#E9D8FF" stopOpacity="0.76" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1139_7759"
          x1="6.1752"
          y1="10.5207"
          x2="29.7076"
          y2="42.3036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1139_7759"
          x1="5.50399"
          y1="9.37716"
          x2="26.4786"
          y2="37.7054"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.447917" stopColor="#E9D8FF" stopOpacity="0.76" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
        </linearGradient>
      </defs>
    </svg>
  );
}
