import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

import TextImageIndexCard from "./TextImageIndexCard";

import phoneImage1 from "../../../image/img_mobile/phone2.png";
import phoneImage2 from "../../../image/img_mobile/phone3.png";
import phoneImage3 from "../../../image/img_mobile/phone4.png";

export default function Section3() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1200));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#F2F3F7"
      sxsect={{
        padding: isTablet ? (isMobile ? " 50px 0" : "100px 0 27px 0") : "46px 0 154px 0",
      }}
      sxcont={{padding: isTablet ? 0 : "0 0 0 32px"}}
    >
      <Typography variant="h3" sx={{ mb: isMobile ? "40px" : "50px", ml: isTablet ? "40px" : 0 }}>
        Почему именно наши приложения
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: isTablet ? "13px" : "40px",
          justifyContent: isTablet ? "center" : "none",
          flexWrap: "wrap",
        }}
      >
        <TextImageIndexCard
          image={phoneImage1}
          indexText="1"
          headerText="Чистый код"
          text="Грамотная архитектура, отсутствие костылей, документация и покрытие тестами, два уровня код-ревью."
          sx={{ maxWidth: isTablet ? (isMobile ? "343px" : "297px") : "360px", mb: isMobile ? "54px" : isTablet ? "50px" : "none" }}
        />
        <TextImageIndexCard
          image={phoneImage2}
          indexText="2"
          headerText="Продуманный дизайн"
          text="Дизайн увеличивающий конверсию на десятки процентов,за счет прозрачных маркетинговых коммуникаций внутри продукта."
          sx={{ maxWidth: isTablet ? (isMobile ? "343px" : "297px") : "360px", mb: isMobile ? "30px" : isTablet ? "50px" : "none" }}
        />
        <TextImageIndexCard
          image={phoneImage3}
          indexText="3"
          headerText="Высокая безопасность"
          text="Теперь можно не переживать за DoS - атаки или за то, что у вас уведут базу данных. О вашей безопасности заботится PENA."
          sx={{ maxWidth: isTablet ? (isMobile ? "343px" : "297px") : "360px" }}
        />
      </Box>
    </Section>
  );
}
