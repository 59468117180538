import { createTheme } from "@mui/material";
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    template: true;
    connect: true;
    form: true;
    mobileForm: true;
    orderService: true;
    orderServiceMobile: true;
    orderServiceWhite: true;
    orderServiceWhiteMobile: true;
    connectWithUs: true;
    calculate: true;
    connectIsMobile: true;
    menuButtons: true;
    links: true;
  }
}

declare module "@mui/material/styles/zIndex.d" {
  interface ZIndex {
    bubbles: number;
    content: number;
    header: number;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    t1: true;
    hero: true;
    heroMobile: true;
    tellOrder: true;
    tellOrderMobile: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 460,
      sm: 600,
      md: 680,
      lg: 900,
      xl: 1000,
      
    },
  },
  typography: {
    fontFamily: "Rubik",
  },

  components: {
    MuiButton: {
      variants: [
        {
          props: {
            variant: "template",
          },
          style: {
            background: "#7E2AEA",
            border: "1px solid #7E2AEA",
            borderRadius: "8px",
            padding: "15px 56px",
            textTransform: "none",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
          },
        },
        {
          props: {
            variant: "connect",
          },
          style: {
            background: "#7E2AEA",
            border: "1px solid #7E2AEA",
            borderRadius: "8px",
            padding: "15px 56px",
            textTransform: "none",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            "&:hover": {
              border: "1px solid white",
              background: "#7E2AEA",
            },
            "&:active": {
              borderColor: "#7E2AEA",
              background: "#7E2AEA",
              color: "white",
            },
          },
        },

        {
          props: {
            variant: "connectIsMobile",
          },
          style: {
            background: "white",
            border: "1px solid white",
            borderRadius: "8px",
            padding: "15px 56px",
            textTransform: "none",
            color: "black",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            "&:hover": {
              border: "1px solid #7E2AEA",
              background: "white",
            },
            "&:active": {
              borderColor: "white",
              background: "white",
              color: "black",
            },
          },
        },
        {
          props: {
            variant: "form",
          },
          style: {
            background: "#7E2AEA",

            borderRadius: "8px",
            padding: "15px 56px",
            textTransform: "none",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            "&:hover": {
              background: "white",
              outline: "1px solid white",
            },
            "&:active": {
              background: "white",
              outline: "1px solid #7E2AEA",
            },
          },
        },
        {
          props: {
            variant: "mobileForm",
          },
          style: {
            background: "#7E2AEA",

            borderRadius: "8px",
            padding: "15px 56px",
            textTransform: "none",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            "&:hover": {
              background: "white",
            },
            "&:active": {
              outline: "1px solid white",
            },
          },
        },

        {
          props: {
            variant: "orderService",
          },
          style: {
            background: "#7E2AEA",
            border: "1px solid #7E2AEA",
            borderRadius: "8px",
            padding: "15px 56px",
            textTransform: "none",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            "&:hover": {
              backgroundColor: "#7E2AEA",
              outline: " 1px solid #7E2AEA",
            },
            "&:active": {
              outline: " 1px solid #F2F3F7",
              color: "white",
            },
          },
        },
        {
          props: {
            variant: "orderServiceMobile",
          },
          style: {
            background: "#7E2AEA",
            border: "1px solid #7E2AEA",
            borderRadius: "8px",
            padding: "15px 56px",
            textTransform: "none",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            "&:hover": {
              backgroundColor: "#7E2AEA",
            },
            "&:active": {
              outline: " 1px solid #7E2AEA",
              color: "white",
            },
          },
        },
        {
          props: {
            variant: "orderServiceWhite",
          },
          style: {
            background: "#7E2AEA",
            border: "1px solid white",
            borderRadius: "8px",
            padding: "15px 56px",
            textTransform: "none",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            "&:hover": {
              outline: " 1px solid white",
              backgroundColor: "white",
            },
            "&:active": {
              outline: " 1px solid #7E2AEA",
            },
          },
        },
        {
          props: {
            variant: "orderServiceWhiteMobile",
          },
          style: {
            background: "#7E2AEA",
            border: "1px solid white",
            borderRadius: "8px",
            padding: "15px 56px",
            textTransform: "none",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            "&:hover": {
              backgroundColor: "white",
            },
            "&:active": {
              outline: " 1px solid white",
            },
          },
        },
        {
          props: {
            variant: "connectWithUs",
          },
          style: {
            "&:hover": {
              borderColor: "#7E2AEA",
            },
          },
        },
        {
          props: {
            variant: "calculate",
          },
          style: {
            background: "#7E2AEA",
            border: "1px solid #7E2AEA",
            borderRadius: "8px",
            padding: "15px 56px",
            textTransform: "none",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            "&:hover": {
              border: "1px solid white",
              backgroundColor: "#7E2AEA",
            },
            "&:active": {
              borderColor: "#7E2AEA",
              color: "white",
            },
          },
        },
        {
          props: {
            variant: "menuButtons",
          },
          style: {
            "&:hover": {
              background: "none",
              color: "#7E2AEA",
            },
          },
        },
        {
          props: {
            variant: "menuButtons",
          },
          style: {
            "&:hover": {
              background: "none",
              color: "#7E2AEA",
            },
          },
        },
      ],
    },

    MuiTypography: {
      variants: [
        {
          props: {
            variant: "h1",
          },
          style: {
            color: "#000000",
            fontWeight: 500,
            fontSize: "70px",
            lineHeight: "100%",
          },
        },
        {
          props: {
            variant: "h3",
          },
          style: {
            color: "#000000",
            fontWeight: 500,
            fontSize: "36px",
            lineHeight: "43px",
          },
        },
        {
          props: {
            variant: "h4",
          },
          style: {
            color: "#000000",
            fontWeight: 500,
            fontSize: "24px",
            lineHeight: "28px",
          },
        },
        {
          props: {
            variant: "t1",
          },
          style: {
            display: "block",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "21.33px",
          },
        },

        {
          props: {
            variant: "hero",
          },
          style: {
            color: "#000000",
            fontWeight: 500,
            fontSize: "70px",
            lineHeight: "100%",
          },
        },
        {
          props: {
            variant: "heroMobile",
          },
          style: {
            color: "#000000",
            fontWeight: 500,
            fontSize: "42px",
            lineHeight: "100%",
          },
        },
        {
          props: {
            variant: "tellOrder",
          },
          style: {
            color: "#000000",
            fontWeight: 500,
            fontSize: "70px",
            lineHeight: "100%",
          },
        },
        {
          props: {
            variant: "tellOrderMobile",
          },
          style: {
            color: "#000000",
            fontWeight: 500,
            fontSize: "42px",
            lineHeight: "100%",
          },
        },
      ],
    },
  },
  zIndex: {
    bubbles: 0,
    content: 1,
    header: 10,
  },
});

theme.typography.h1 = {
  [theme.breakpoints.down("md")]: {
    fontSize: "42px",
    lineHeight: "42px",
  },
};

theme.typography.h3 = {
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
    lineHeight: "28px",
  },
};

export default theme;
