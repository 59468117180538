import {Typography, Box, useMediaQuery, useTheme, Button} from "@mui/material";

import Section from "../../../kit/section";
import EmailForm from "../../../kit/EmailForm";

import Bubbles from "./Bubbles";
import BubblesMobile from "./BubblesMobile";

import penaLogo from "../../../image/img_design/penaLogoDarkBlob.svg";
import {setIsContactFormOpen} from "../../../stores/contactForm";

export default function Section7() {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isWrap = useMediaQuery(theme.breakpoints.down(998));

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#7E2AEA"
      sxsect={{
        padding: isMobile ? "70px 0" : "120px 0",
      }}
      sxcont={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: theme.zIndex.content,
          display: "flex",
          flexDirection: isWrap ? "column" : "row",
          alignItems: "start",
          gap: isMobile ? "24px" : "40px",
        }}
      >
        <Box>
          {isMobile ? (
            <Typography variant={isMobile ? "tellOrderMobile" : "tellOrder"} color="white" maxWidth="760px">
              Закажите дизайн в
              <img
                src={penaLogo}
                alt="pena logo"
                style={{
                  height: isMobile ? "42px" : "71px",
                  marginLeft: isMobile ? "0px" : "15px",
                  verticalAlign: "text-bottom",
                }}
              />
            </Typography>
          ) : (
            <Typography variant="h1" component="h1" color="white" maxWidth="760px">
              Закажите дизайн интерфейсов {isTablet ? <br /> : ""} в
              <img
                src={penaLogo}
                alt="pena logo"
                style={{
                  height: isMobile ? "42px" : "71px",
                  marginLeft: "15px",
                  verticalAlign: "text-bottom",
                }}
              />
            </Typography>
          )}

          <Typography variant="t1" mt="20px" color="white" maxWidth="670px">
            Напишите нам по поводу проекта, мы свяжемся с вами для детального обсуждения. Отвечаем на все заявки без
            исключения. Работаем вне рамок географии и часовых поясов
          </Typography>
        </Box>
          <Box
              zIndex={theme.zIndex.content}
              sx={{
                  position: "relative",
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  mt: isMobile ? 0 : "20px"
              }}
          >
              <Button
                  onClick={() => setIsContactFormOpen(true)}
                  disableRipple
                  variant={isMobile ? "mobileForm" : "form"}
                  sx={{
                      padding: "9px 50px",
                      backgroundColor: "white",
                      color: "black",
                      whiteSpace: "nowrap",
                  }}
              >
                  Заполнить форму
              </Button>
          </Box>
      </Box>
      {isMobile ? (
        <BubblesMobile
          style={{
            position: "absolute",
            top: "-167px",
            right: "-118px",
          }}
        />
      ) : (
        <Bubbles
          style={{
            position: "absolute",
            top: isTablet ? "-235px" : "-288px",
            right: isTablet ? "-604px" : "-576px",
            pointerEvents: "none",
          }}
        />
      )}
    </Section>
  );
}
