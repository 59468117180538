import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export default function BubblesMobile({ style }: Props) {
  return (
    <svg
      style={{ ...style }}
      width="600"
      height="336"
      viewBox="0 0 375 336"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="380.004"
        cy="27.19"
        r="5.93748"
        transform="rotate(-0.840987 380.004 27.19)"
        fill="url(#paint0_linear_1139_9039)"
        fillOpacity="0.4"
      />
      <path
        d="M-14.5644 68.3777C-25.4421 75.7016 -37.7983 109.671 -82.4923 124.163C-109.136 132.802 -92.7883 197.466 -62.9587 207.758C-27.585 219.963 -26.2698 280.563 -17.6888 281.476C2.04376 283.574 -25.4519 180.778 56.4882 231.516C138.428 282.255 203.19 209.421 216.13 155.499C229.07 101.576 156.803 111.954 146.685 71.9809C140.942 49.2929 119.852 33.5682 89.8485 63.8599C59.8449 94.1515 45.3267 81.2301 29.641 70.1169C13.9552 59.0037 -0.967191 59.2227 -14.5644 68.3777Z"
        fill="url(#paint1_linear_1139_9039)"
      />
      <path
        d="M372.274 52.084C385.027 55.1365 408.493 82.6308 455.432 80.5592C483.414 79.3243 490.738 145.62 466.399 165.703C437.537 189.519 457.519 246.745 449.801 250.604C432.051 259.477 421.821 153.56 362.828 229.773C303.835 305.986 217.674 260.432 186.676 214.452C162.291 178.281 153.805 122.165 166.923 99.2574C170.65 98.7715 183.287 105.216 197.058 97.6373C224.439 82.5698 234.177 66.533 272.886 84.4043C311.596 102.276 320.672 85.0895 331.474 69.1883C342.277 53.2871 356.332 48.2684 372.274 52.084Z"
        fill="url(#paint2_linear_1139_9039)"
      />
      <circle
        cx="16.9322"
        cy="99.6004"
        r="72.8291"
        transform="rotate(-0.840987 16.9322 99.6004)"
        fill="url(#paint3_linear_1139_9039)"
      />
      <circle
        cx="64.5156"
        cy="177.743"
        r="42.6385"
        transform="rotate(-0.840987 64.5156 177.743)"
        fill="url(#paint4_linear_1139_9039)"
      />
      <path
        d="M2.11793 100.099C-9.01075 98.6165 -18.2268 102.62 -24.73 108.804C-37.0638 120.532 -39.7984 144.234 -25.2048 157.106C-14.6312 169.459 -20.532 182.079 -17.6576 196.476C-14.7832 210.873 -6.14811 214.638 -1.66861 214.796C2.8109 214.954 23.3848 221.15 33.6901 157.855C34.6816 151.766 36.6673 141.757 32.313 125.366C27.9587 108.975 16.0288 101.951 2.11793 100.099Z"
        fill="url(#paint5_linear_1139_9039)"
      />
      <path
        d="M179.046 128.332C172.592 139.966 153.004 156.428 144.016 163.206C136.285 169.355 71.4157 229.582 36.7974 162.64C2.17914 95.6974 68.9993 79.1459 88.3533 87.2848C107.707 95.4236 110.249 98.7939 124.238 89.3023C138.228 79.8106 153.411 74.7058 169.643 83.6125C185.876 92.5193 187.113 113.791 179.046 128.332Z"
        fill="url(#paint6_linear_1139_9039)"
      />
      <circle
        cx="43.4746"
        cy="69.8362"
        r="15.521"
        transform="rotate(-0.840987 43.4746 69.8362)"
        fill="url(#paint7_linear_1139_9039)"
      />
      <circle cx="164.321" cy="61.7917" r="5.37264" transform="rotate(-0.840987 164.321 61.7917)" fill="#A260F7" />
      <circle
        cx="277.645"
        cy="65.4614"
        r="11.0823"
        transform="rotate(-0.840987 277.645 65.4614)"
        fill="url(#paint8_linear_1139_9039)"
        fillOpacity="0.4"
      />
      <path
        d="M282.483 172.925C292.861 174.406 312.521 169.934 321.054 167.513C328.594 165.582 412.763 142.801 371.707 99.8956C326.67 52.8284 300.627 72.9991 299.847 96.4295C299.297 112.964 296.66 115.658 284.042 119.93C271.424 124.202 260.995 131.31 258.867 145.743C256.739 160.177 269.512 171.074 282.483 172.925Z"
        fill="url(#paint9_linear_1139_9039)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1139_9039"
          x1="380.004"
          y1="21.2525"
          x2="380.004"
          y2="33.1275"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1139_9039"
          x1="124.229"
          y1="46.656"
          x2="64.9132"
          y2="194.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B379FD" />
          <stop offset="0.892358" stopColor="#5613AC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1139_9039"
          x1="234.658"
          y1="80.3251"
          x2="342.15"
          y2="198.509"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B379FD" />
          <stop offset="0.892358" stopColor="#5613AC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1139_9039"
          x1="75.4202"
          y1="54.0258"
          x2="16.9322"
          y2="172.43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9D9FF" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1139_9039"
          x1="98.758"
          y1="151.061"
          x2="64.5156"
          y2="220.381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B78EEC" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1139_9039"
          x1="16.1669"
          y1="101.711"
          x2="-31.1126"
          y2="212.761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BE99ED" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1139_9039"
          x1="181.406"
          y1="94.3037"
          x2="28.815"
          y2="112.091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3A1ED" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1139_9039"
          x1="43.4746"
          y1="54.3152"
          x2="43.4746"
          y2="85.3571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1139_9039"
          x1="277.645"
          y1="54.3791"
          x2="277.645"
          y2="76.5437"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1139_9039"
          x1="314.196"
          y1="78.8441"
          x2="338.985"
          y2="131.661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3A1ED" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
