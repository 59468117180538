import { Box, useMediaQuery, useTheme } from "@mui/material";

import Navbar from "../../kit/header";
import Footer from "../../kit/footer";
import MobileHeader from "../../kit/PhabletHeader";

import Section1 from "./Section1/Section1";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import Section4 from "./Section4/Section4";
import Section5 from "./Section5/Section5";
import Section6 from "./Section6/Section6";
import Section7 from "./Section7/Section7";

export default function MobileApps() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <>
      {isTablet ? <MobileHeader theme="light" /> : <Navbar theme="light" />}
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Box sx={{ width: "100%", height: isMobile ? "50px" : "100px", bgcolor: "#F2F3F7" }} />
      <Section7 />
      <Box sx={{ width: "100%", height: isMobile ? "50px" : "100px", bgcolor: "#F2F3F7" }} />
      <Footer />
    </>
  );
}
