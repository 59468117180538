import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export default function BubblesMobile({ style }: Props) {
  return (
    <svg
      style={{ ...style }}
      width="1917"
      height="336"
      viewBox="0 0 1917 1037"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="1475.17"
        cy="83.9127"
        r="18.3237"
        transform="rotate(-0.840987 1475.17 83.9127)"
        fill="url(#paint0_linear_1139_11235)"
        fill-opacity="0.4"
      />
      <path
        d="M257.492 211.021C223.922 233.623 185.79 338.457 47.8591 383.18C-34.3658 409.84 16.0845 609.402 108.142 641.164C217.309 678.83 221.368 865.849 247.85 868.665C308.747 875.141 223.892 557.901 476.768 714.484C729.644 871.068 929.506 646.295 969.44 479.886C1009.37 313.476 786.35 345.501 755.125 222.141C737.402 152.123 672.316 103.595 579.721 197.078C487.127 290.562 442.323 250.685 393.915 216.388C345.507 182.092 299.454 182.768 257.492 211.021Z"
        fill="url(#paint1_linear_1139_11235)"
      />
      <path
        d="M1451.32 160.737C1490.67 170.157 1563.09 255.008 1707.95 248.614C1794.31 244.803 1816.91 449.398 1741.8 511.378C1652.72 584.876 1714.39 761.482 1690.57 773.39C1635.8 800.775 1604.22 473.903 1422.17 709.104C1240.11 944.305 974.205 803.723 878.542 661.823C803.285 550.194 777.097 377.014 817.583 306.319C829.082 304.819 868.082 324.707 910.582 301.319C995.082 254.819 1025.13 205.328 1144.6 260.481C1264.06 315.634 1292.07 262.595 1325.4 213.522C1358.74 164.45 1402.12 148.961 1451.32 160.737Z"
        fill="url(#paint2_linear_1139_11235)"
      />
      <circle
        cx="354.693"
        cy="307.379"
        r="224.758"
        transform="rotate(-0.840987 354.693 307.379)"
        fill="url(#paint3_linear_1139_11235)"
      />
      <circle
        cx="501.541"
        cy="548.534"
        r="131.587"
        transform="rotate(-0.840987 501.541 548.534)"
        fill="url(#paint4_linear_1139_11235)"
      />
      <path
        d="M308.975 308.916C274.631 304.342 246.189 316.697 226.119 335.781C188.056 371.974 179.616 445.124 224.654 484.846C257.285 522.97 239.075 561.916 247.945 606.346C256.816 650.776 283.465 662.396 297.289 662.883C311.113 663.37 374.607 682.492 406.41 487.16C409.47 468.366 415.598 437.477 402.16 386.893C388.722 336.309 351.905 314.633 308.975 308.916Z"
        fill="url(#paint5_linear_1139_11235)"
      />
      <path
        d="M854.992 396.048C835.076 431.95 774.624 482.756 746.887 503.671C723.029 522.649 522.834 708.515 415.998 501.924C309.162 295.333 515.377 244.253 575.105 269.371C634.834 294.488 642.677 304.889 685.851 275.597C729.024 246.305 775.88 230.551 825.975 258.038C876.07 285.525 879.888 351.171 854.992 396.048Z"
        fill="url(#paint6_linear_1139_11235)"
      />
      <circle
        cx="436.605"
        cy="215.522"
        r="47.8993"
        transform="rotate(-0.840987 436.605 215.522)"
        fill="url(#paint7_linear_1139_11235)"
      />
      <circle cx="809.551" cy="190.697" r="16.5805" transform="rotate(-0.840987 809.551 190.697)" fill="#A260F7" />
      <circle
        cx="186.993"
        cy="687.679"
        r="53.6488"
        transform="rotate(-0.840987 186.993 687.679)"
        fill="url(#paint8_linear_1139_11235)"
      />
      <circle
        cx="1159.28"
        cy="202.021"
        r="34.2012"
        transform="rotate(-0.840987 1159.28 202.021)"
        fill="url(#paint9_linear_1139_11235)"
        fill-opacity="0.4"
      />
      <path
        d="M1174.21 533.665C1206.24 538.235 1266.91 524.434 1293.24 516.962C1316.52 511.005 1576.27 440.701 1449.57 308.289C1310.58 163.034 1230.21 225.283 1227.8 297.592C1226.1 348.62 1217.96 356.934 1179.02 370.117C1140.08 383.3 1107.9 405.237 1101.33 449.78C1094.76 494.323 1134.18 527.952 1174.21 533.665Z"
        fill="url(#paint10_linear_1139_11235)"
      />
      <circle
        cx="1611.24"
        cy="219.981"
        r="133.711"
        transform="rotate(-0.840987 1611.24 219.981)"
        fill="url(#paint11_linear_1139_11235)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1139_11235"
          x1="1475.17"
          y1="65.5889"
          x2="1475.17"
          y2="102.236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C4A4ED" />
          <stop offset="1" stop-color="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1139_11235"
          x1="685.824"
          y1="143.985"
          x2="502.768"
          y2="601.766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B379FD" />
          <stop offset="0.892358" stop-color="#5613AC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1139_11235"
          x1="1026.62"
          y1="247.892"
          x2="1358.35"
          y2="612.621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B379FD" />
          <stop offset="0.892358" stop-color="#5613AC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1139_11235"
          x1="535.194"
          y1="166.73"
          x2="354.693"
          y2="532.137"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E9D9FF" />
          <stop offset="1" stop-color="#7E2AEA" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1139_11235"
          x1="607.217"
          y1="466.19"
          x2="501.541"
          y2="680.121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B78EEC" />
          <stop offset="1" stop-color="#7E2AEA" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1139_11235"
          x1="352.332"
          y1="313.891"
          x2="206.422"
          y2="656.604"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BE99ED" />
          <stop offset="1" stop-color="#7E2AEA" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1139_11235"
          x1="862.276"
          y1="291.032"
          x2="391.363"
          y2="345.925"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3A1ED" />
          <stop offset="1" stop-color="#7E2AEA" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1139_11235"
          x1="436.605"
          y1="167.623"
          x2="436.605"
          y2="263.422"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C4A4ED" />
          <stop offset="1" stop-color="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1139_11235"
          x1="186.993"
          y1="634.03"
          x2="186.993"
          y2="741.328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#945CDB" />
          <stop offset="1" stop-color="#945CDB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1139_11235"
          x1="1159.28"
          y1="167.82"
          x2="1159.28"
          y2="236.222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C4A4ED" />
          <stop offset="1" stop-color="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1139_11235"
          x1="1272.08"
          y1="243.322"
          x2="1348.58"
          y2="406.319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3A1ED" />
          <stop offset="1" stop-color="#7E2AEA" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1139_11235"
          x1="1612.8"
          y1="125.71"
          x2="1611.24"
          y2="353.692"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E9D9FF" />
          <stop offset="1" stop-color="#7E2AEA" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
