import { Link as RouterLink } from "react-router-dom";
import { IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box, Button } from "@mui/material";

import SectionStyled from "../../kit/section";

import card1 from "../../image/img_home/block1.png";
import card2 from "../../image/img_home/block2.png";
import card3 from "../../image/img_home/block3.png";
import card4 from "../../image/img_home/block4.png";
import card5 from "../../image/img_home/block5.png";
import card6 from "../../image/img_home/block6.png";
import card7 from "../../image/img_home/block7.png";

import card1Mobile from "../../image/img_home/block1_mobile.png";
import card2Mobile from "../../image/img_home/block2_mobile.png";
import card3Mobile from "../../image/img_home/block3_mobile.png";
import card4Mobile from "../../image/img_home/block4_mobile.png";
import card5Mobile from "../../image/img_home/block5_mobile.png";
import card6Mobile from "../../image/img_home/block6_mobile.png";
import card7Mobile from "../../image/img_home/block7_mobile.png";
import { Arows } from "./Arows";

interface CardItemProps {
  image: string;
  mobileImage: string;
  title: any;
  text: string;
  button: string;
  href: string;
}

const CardArray: { image: string; mobileImage: any; href: string; title: any; text: string; button: string,}[] = [
  {
    image: card1,
    mobileImage: card1Mobile,
    href: "/landings",
    title: "Разработка Landing Page",
    text: "Landing Page с идеальным UX/UI от лучших дизайнеров в отрасли. Позволит догнать и перегнать ваших конкурентов (а позже еще и купить их фирмы). TOP landing для TOP продаж.",
    button: "Подробнее",
  },
  {
    image: card2,
    mobileImage: card2Mobile,
    href: "/mobileapps",
    title: "<p style='margin: 0'>Разработка <br/> мобильных приложений</p>",
    text: "Есть веб сервис? Но давно хотели бы удобное приложение, или может быть есть идея, но нет ТЗ? Не беда, наша команда специалистов поможет разобраться, собрать требования и сделать приложение, которого еще не видел мир.",
    button: "Подробнее",
  },
  {
    image: card3,
    mobileImage: card3Mobile,
    href: "/corporatesites",
    title: "<p style='margin: 0'>Разработка <br/> корпоративных сайтов</p>",
    text: "Годы идут, фирма растет, а сайта нет? Или может быть вы только планируете открытьсвой бизнес? Лучшая визитка, по которой вас запомнят, покажут знакомым и будут обсуждать в курилке - это корпоративный сайт от PENA.",
    button: "Подробнее",
  },
  {
    image: card4,
    mobileImage: card4Mobile,
    href: "/devops",
    title: "DevOps & CloudOps",
    text: "У вас большой и быстроразвивающийся бизнес, вам надоело что ваше WEB-Приложение постоянно тормозит и не держит нагрузки, а цена серверов начинает пугать? У нашей команды высококвалифицированных специалистов есть на это решение (и не одно).",
    button: "Подробнее",
  },
  {
    image: card5,
    mobileImage: card5Mobile,
    href: "/cloudnative",
    title: "<p style='margin: 0'>Разработка <br/> Cloud-Native приложений</p>",
    text: "Вы понимаете, что через 2 года ваш бизнес разрастется и хотите безопасно и без потери данных масштабироваться? Cloud-Native — это лучшее решение на рынке.",
    button: "Подробнее",
  },
  {
    image: card6,
    mobileImage: card6Mobile,
    href: "/design",
    title: "UX/UI дизайн",
    text: "Только у нас собраны лучшие специалисты отрасли, которые могут не только нарисовать лучший дизайн, но еще понять, что в голове у клиента, и придумать лучшие решения, основываясь на многолетнем опыте и отраслевых стандартах и нормах.",
    button: "Подробнее",
  },
  {
    image: card7,
    mobileImage: card7Mobile,
    href: "/crmsystems",
    title: "Разработка CRM-систем",
    text: "Разработка любых систем, любой сложности, в любых форматах. Будь это Telegram-бот, админ-панель, система приема и учета заказов или же суровый комбайн, который потеснит на рынке Bitrix24 и AmoCRM.",
    button: "Подробнее",
  },
];

function CardItem(props: CardItemProps) {
  const theme = useTheme();
    const markup = { __html: props.title };
  const isTablet = useMediaQuery(theme.breakpoints.down(1045));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: isMobile ? "70px" : "120px",
          zIndex: theme.zIndex.content
      }}>
        <Box
          sx={{
            width: "100%",
            height: isMobile ? "250px" : "350px",
            backgroundImage: `url(${isMobile ? props.mobileImage : props.image})`,
            bgcolor: "#252734",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            borderRadius: "35px",
            border: "1px solid rgba(126, 42, 234, 30%)",
            boxShadow:
              "rgba(126, 42, 234, 5%) 0 0 12px, rgba(126, 42, 234, 10%) 0 10px 25px, rgba(126, 42, 234, 10%) 0 -10px 25px",
            backgroundRepeat: "no-repeat",
          }}
        />
        <Box
          sx={{
            mt: "25px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <Box maxWidth={"870px"}>
            <div
              // variant="h4"
              dangerouslySetInnerHTML={markup}
              style={{
                fontSize: isMobile ? "24px" : "60px",
                fontWeight: "500",
                lineHeight: "100%",
                marginTop: isMobile ? 0 : "5px",
                color: "#ffffff",
              }}
            />
            <Typography
              variant="h5"
              sx={{
                marginTop: isMobile ? "18px" : "25px",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "21px",
                color: "#ffffff",
                letterSpacing: "0px"
              }}
            >
              {props.text}
            </Typography>
          </Box>
          <IconButton
            disableRipple
            sx={{
              height: "55px",
              mt: isTablet ? (isMobile ? "35px" : "40px") : "10px",
              background: "#7E2AEA",
              border: "1px solid #7E2AEA",
              borderRadius: "8px",
              padding: "15px 56px",
              textTransform: "none",
              color: "#ffffff",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "24px",
              "&:hover": {
                border: "1px solid white",
                background: "#7E2AEA",
              },
              "&:active": {
                borderColor: "#7E2AEA",
                background: "#7E2AEA",
                color: "white",
              },
            }}
            component={RouterLink}
            to={props.href}
          >
            {props.button} <Arows />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

export default function Services() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <SectionStyled
      tag={"section"}
      bg={"#333647"}
      mwidth={"1200px"}
      sxsect={{
        alignItems: "flex-start",
      }}
      sxcont={{
        marginTop: isMobile ? "50px" : "100px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: isTablet ? "0 40px" : "0 20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          marginBottom: isMobile ? "50px" : "75px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 500,
            fontSize: isMobile ? "32px" : "36px",
            lineHeight: "100%",
            color: "#A45EFF",
          }}
        >
          Наши услуги
        </Typography>
      </Box>
      {CardArray.map(({ title, text, image, button, href, mobileImage }) => (
        <CardItem
          key={title}
          title={title}
          text={text}
          image={image}
          mobileImage={mobileImage}
          button={button}
          href={href}
        />
      ))}
    </SectionStyled>
  );
}