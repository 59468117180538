import { useMediaQuery, useTheme } from "@mui/material";
import { CSSProperties } from "react";

interface Props {
  style: CSSProperties;
}

export default function Bubbles({ style }: Props) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  return (
    <svg
      width={isTablet ? "400" : "567"}
      height="560"
      viewBox="0 0 567 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <circle cx="407.108" cy="432.399" r="120.985" fill="url(#paint0_linear_202_1180)" />
      <g filter="url(#filter0_f_202_1180)">
        <circle cx="413.322" cy="438.861" r="107.681" fill="url(#paint1_linear_202_1180)" />
      </g>
      <g filter="url(#filter1_d_202_1180)">
        <path
          d="M254.509 79.1719C329.289 117.474 314.698 156.991 351.176 169.759C364.552 175.23 373.061 169.759 399.811 164.287C426.562 158.816 504.989 162.463 527.484 234.811C549.979 307.159 512.285 348.501 498.91 361.876C485.534 375.251 443.585 410.513 425.954 437.871C408.323 465.23 364.549 512.651 317.736 522.379C270.923 532.106 208.91 528.458 157.841 482.861C106.772 437.263 103.124 388.018 100.085 369.779C97.0448 351.54 83.0631 339.989 64.8241 319.926C46.5852 299.863 10.1073 240.283 39.8975 158.815C69.6878 77.348 179.73 40.8701 254.509 79.1719Z"
          fill="url(#paint2_linear_202_1180)"
        />
      </g>
      <path
        d="M466.083 56.0693C403.463 20.8073 360.904 38.4379 335.978 63.9729C311.053 89.5078 312.267 116.258 273.965 129.025C235.663 141.793 200.402 152.128 165.748 211.101C131.094 270.073 149.941 388.626 251.471 414.769C353.001 440.911 402.247 381.331 419.27 358.836C436.293 336.341 446.628 263.993 453.924 250.01C461.219 236.027 481.89 225.692 498.913 198.941C515.936 172.191 528.703 91.3313 466.083 56.0693Z"
        fill="url(#paint3_linear_202_1180)"
        fillOpacity="0.8"
      />
      <g filter="url(#filter2_f_202_1180)">
        <path
          d="M461.415 64.0674C396.074 32.1814 362.291 55.9637 340.143 78.7038C317.995 101.444 310.775 128.508 276.742 139.878C242.708 151.247 211.377 160.452 180.585 212.97C149.792 265.487 167.438 374.503 257.653 397.784C347.869 421.066 390.728 364.568 405.854 344.535C420.98 324.503 430.164 260.073 436.646 247.621C443.129 235.168 463.154 225.86 481.801 204.68C505.798 177.424 518.834 92.088 461.415 64.0674Z"
          fill="url(#paint4_linear_202_1180)"
          fillOpacity="0.6"
        />
      </g>
      <g filter="url(#filter3_d_202_1180)">
        <circle cx="178.514" cy="66.4045" r="61.4045" fill="url(#paint5_linear_202_1180)" fillOpacity="0.8" />
      </g>
      <g filter="url(#filter4_f_202_1180)">
        <circle cx="177.571" cy="67.2041" r="53.8405" fill="url(#paint6_linear_202_1180)" fillOpacity="0.8" />
      </g>
      <circle cx="97.046" cy="434.831" r="46.8133" fill="url(#paint7_linear_202_1180)" fillOpacity="0.35" />
      <circle cx="549.976" cy="186.173" r="17.023" fill="url(#paint8_linear_202_1180)" fillOpacity="0.45" />
      <circle
        opacity="0.8"
        cx="13.9832"
        cy="389.752"
        r="13.9832"
        fill="url(#paint9_linear_202_1180)"
        fillOpacity="0.6"
      />
      <defs>
        <filter
          id="filter0_f_202_1180"
          x="292.641"
          y="318.179"
          width="241.362"
          height="241.362"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_202_1180" />
        </filter>
        <filter
          id="filter1_d_202_1180"
          x="15.5964"
          y="50.9153"
          width="537.895"
          height="494.861"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="3" dy="3" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.406966 0 0 0 0 0.114236 0 0 0 0 0.783333 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_202_1180" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_202_1180" result="shape" />
        </filter>
        <filter
          id="filter2_f_202_1180"
          x="148.599"
          y="30.6133"
          width="371.604"
          height="390.702"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_202_1180" />
        </filter>
        <filter
          id="filter3_d_202_1180"
          x="106.109"
          y="0"
          width="148.809"
          height="148.809"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="8" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_202_1180" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_202_1180" result="shape" />
        </filter>
        <filter
          id="filter4_f_202_1180"
          x="110.731"
          y="0.363525"
          width="133.681"
          height="133.681"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_202_1180" />
        </filter>
        <linearGradient
          id="paint0_linear_202_1180"
          x1="318.466"
          y1="366.516"
          x2="492.524"
          y2="519.367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#BF96F2" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_202_1180"
          x1="334.427"
          y1="380.222"
          x2="489.345"
          y2="516.265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BF96F2" stopOpacity="0.75" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.53" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_202_1180"
          x1="162.705"
          y1="489.548"
          x2="512.893"
          y2="205.021"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#AC71F8" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_202_1180"
          x1="312.96"
          y1="156.067"
          x2="528.397"
          y2="224.129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C094FA" />
          <stop offset="1" stopColor="#8739EC" stopOpacity="0.68" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_202_1180"
          x1="219.12"
          y1="384.627"
          x2="447.595"
          y2="76.3143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8434EB" />
          <stop offset="1" stopColor="#FAF7FF" stopOpacity="0.896667" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_202_1180"
          x1="133.524"
          y1="32.9664"
          x2="196.079"
          y2="117.452"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_202_1180"
          x1="138.124"
          y1="37.885"
          x2="192.973"
          y2="111.964"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.447917" stopColor="#E9D8FF" stopOpacity="0.76" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_202_1180"
          x1="62.7471"
          y1="409.339"
          x2="110.437"
          y2="473.749"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#AC71F8" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_202_1180"
          x1="537.504"
          y1="176.903"
          x2="554.846"
          y2="200.325"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#AC71F8" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_202_1180"
          x1="3.73808"
          y1="382.137"
          x2="17.9832"
          y2="401.377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#AC71F8" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
}
