import { SxProps, Theme, Typography, Box } from "@mui/material";

interface Props {
  icon: string;
  title: string;
  text?: string;
  sxbox?: SxProps<Theme>;
  sxtext?: SxProps<Theme>;
  sxtitle?: SxProps<Theme>;
  colortitle?: string;
  colortext?: string;
}

export default function MiniIconCard(props: Props) {
  return (
    <>
      <Box
        sx={{
          padding: "20px 20px",
          background: "#ffffff",
          minHeight: "147px",
          maxWidth: "360px",
          borderRadius: "8px",
          ...props.sxbox,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "36px",
            height: "36px",
            mb: "14px",
            borderRadius: "6px",
            background: "#7E2AEA",
            fontSize: "24px",
            fontWeight: "500",
            color: "white",
          }}
        >
          {props.icon}
        </Box>
        <Typography
          color={props.colortitle}
          sx={{
            fontSize: "24px",
            fontWeight: 500,
            lineHeight: "28px",
            ...props.sxtitle,
          }}
        >
          {props.title}
        </Typography>
        <Typography color={props.colortext} sx={{ ...props.sxtext }}>
          {props.text}
          {/*{props.text !== undefined ? <Typography/>:null}*/}
        </Typography>
      </Box>
    </>
  );
}
