import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

import TextImageLinkCard from "./TextImageLinkCard";
import BigInfoCard from "./BigInfoCard";

import cardImage1 from "../../../image/img_design/card1.png";
import cardImage2 from "../../../image/img_design/card2.png";
import cardImage3 from "../../../image/img_design/card3.png";

export default function Section2() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  const cardSx = {
    minWidth: "299px",
    maxWidth: "360px",
    flex: "1 1 0",
  };

  return (
    <Section
      tag="section"
      bg="#262835"
      mwidth="1200px"
      sxsect={{
        mb: isMobile ? "-182px" : "-132px",
      }}
      sxcont={{
        mt: downLg ? "-37px" : "-94px",
          padding: "0 20px",
          [theme.breakpoints.down(600)]: {
              padding: "0 16px",
          },
      }}
    >
      <BigInfoCard
        info={[
          ["2 недели", "для реализации дизайна"],
          ["10", "специалистов трудятся над каждым проектом"],
          ["> 200", "дизайн - проектов реализовано"],
          ["5 лет", "опыта разработки интерфейсов"],
        ]}
      />
      <Box
        sx={{
          mt: isMobile ? "60px" : isTablet ? "78px" : "100px",
          display: "flex",
          gap: isTablet ? "21px" : "40px",
          flexDirection: downLg ? "column" : "row",
          padding: isMobile ? undefined : isTablet ? "0 20px" : undefined
        }}
      >
        <Typography variant={downMd ? "h4" : "h3"}  color="white" flexBasis={downLg ? undefined : "360px"}>
          Что мы разрабатываем:
        </Typography>
        <Typography color="white" flexBasis={downLg ? undefined : "720px"}>
            Все пожелания и требования будут учтены. Разработка ведется по Agile, больше ни одно ваше требование не будет утеряно или забыто.
        </Typography>
      </Box>
      <Box
        sx={{
          mt: isMobile ? "40px" : "70px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          gap: isTablet ? "20px 10px" : "40px",
          padding: isMobile ? undefined : isTablet ? "0 20px" : undefined
        }}
      >
        <TextImageLinkCard
          backgroundImage={cardImage1}
          headerText="Сайты"
          text="Сайты и Web-сервисы любой сложности, для нас не существует слово невозможно."
          href="#"
          sx={cardSx}
          LinkSx={{mt: isTablet ? (isMobile ? "40px" : "60px") : "80px"}}
        />
        <TextImageLinkCard
          backgroundImage={cardImage2}
          headerText="Мобильные приложения"
          text="Мобильные приложения — это отдельный мир, так как пользователь хочет получить максимальный уровень комфорта. И мы готовы его предоставить."
          href="#"
          sx={cardSx}
          LinkSx={{ mt: isTablet ? (isMobile ? "40px" : "15px") : "40px" }}
        />
        <TextImageLinkCard
          backgroundImage={cardImage3}
          headerText="СRM-системы"
          text="Нужна CRM система как внутренний продукт или же для широкой аудитории? Больше с этим нет проблем, самые лучшие решения к вашим услугам."
          href="#"
          sx={cardSx}
        />
      </Box>
    </Section>
  );
}
