import { CSSProperties } from "react";


interface Props {
    style?: CSSProperties;
}

export default function BubblesFront({ style }: Props) {

    return (
        <svg width="127" height="127" viewBox="0 0 127 127" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...style }}>
            <circle opacity="0.3" cx="63.2657" cy="63.2656" r="50.6081" transform="rotate(-72.8761 63.2657 63.2656)" fill="url(#paint0_linear_202_719)" fillOpacity="0.7" />
            <defs>
                <linearGradient id="paint0_linear_202_719" x1="63.2657" y1="12.6575" x2="63.2657" y2="113.874" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
            </defs>
        </svg>
    );
}