import { Box, useMediaQuery, useTheme } from "@mui/material";

import Bubbles from "./Bubbles";

import BubblesMobile from "./BubblesMobile";

export default function Section9() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  return (
    <Box
      component="section"
      sx={{
        width: "100%",
        height: "210px",
        [theme.breakpoints.down("md")]: {
          height: "71px",
        },
        backgroundColor: "#F2F3F7",
        position: "relative",
      }}
    >
      {isMobile ? (
        <BubblesMobile style={{ position: "absolute", left: "-700px", bottom: "-223px" }} />
      ) : (
        <Bubbles
          style={{
            position: "absolute",
            top: isTablet ? "-41px" : "-50px",
            left: isTablet ? "-227px" : "calc(50% - (1918px / 2) + 12px)",
          }}
        />
      )}
    </Box>
  );
}
