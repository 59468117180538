import { CSSProperties } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

interface Props {
  style: CSSProperties;
}

export default function Bubbles({ style }: Props) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  return (
    <svg
      width={isTablet ? "1055" : "1200"}
      height={isTablet ? "1100" : "1600"}
      viewBox="0 90 1055 1163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <path
        d="M216.393 724.988C227.539 763.892 315.5 832.5 315.5 977.5C315.5 1063.94 520.892 1077.5 579.5 999.727C649 907.5 828.153 961.322 839 937C863.943 881.069 535.997 863.939 762.942 671.688C989.888 479.436 837.717 219.991 691.738 130.677C545.759 41.3636 507.434 263.392 380.458 255.046C308.387 250.309 242.15 297.255 302.517 414.168C362.883 531.081 311.131 561.402 263.576 596.872C216.021 632.342 202.46 676.357 216.393 724.988Z"
        fill="url(#paint0_linear_202_2009)"
      />
      <circle
        cx="338.033"
        cy="662.246"
        r="224.758"
        transform="rotate(-72.8761 338.033 662.246)"
        fill="url(#paint1_linear_202_2009)"
      />
      <circle
        cx="612.724"
        cy="596.937"
        r="131.587"
        transform="rotate(-72.8761 612.724 596.937)"
        fill="url(#paint2_linear_202_2009)"
      />
      <path
        d="M325.394 706.208C310.45 737.467 313.431 768.333 325.394 793.311C348.082 840.681 415.063 871.271 466.74 840.681C513.07 821.4 544.5 850.734 589.5 856C634.5 861.266 653.773 839.5 658.5 826.5C663.227 813.5 701 759 525 668.5C508.067 659.793 480.573 644.436 428.311 641.617C376.049 638.798 344.074 667.134 325.394 706.208Z"
        fill="url(#paint3_linear_202_2009)"
      />
      <path
        d="M589.104 161.15C621.386 186.518 661.941 254.275 678.183 284.982C693.129 311.553 844.825 538.737 623.883 611.39C402.942 684.044 385.277 472.333 419.565 417.354C453.854 362.375 465.369 356.284 443.308 309.005C421.248 261.726 413.139 212.962 448.237 167.871C483.334 122.78 548.753 129.441 589.104 161.15Z"
        fill="url(#paint4_linear_202_2009)"
      />
      <circle
        cx="275.919"
        cy="555.996"
        r="47.8993"
        transform="rotate(-72.8761 275.919 555.996)"
        fill="url(#paint5_linear_202_2009)"
      />
      <circle
        cx="203.656"
        cy="382.39"
        r="34.2012"
        transform="rotate(-72.8761 203.656 382.39)"
        fill="url(#paint6_linear_202_2009)"
        fillOpacity="0.4"
      />
      <circle cx="367.333" cy="193.575" r="16.5805" transform="rotate(-72.8761 367.333 193.575)" fill="#A260F7" />
      <circle
        cx="648.067"
        cy="939.067"
        r="53.6488"
        transform="rotate(-72.8761 648.067 939.067)"
        fill="url(#paint7_linear_202_2009)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_202_2009"
          x1="284.738"
          y1="296.863"
          x2="663.739"
          y2="612.189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B379FD" />
          <stop offset="0.892358" stopColor="#5613AC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_202_2009"
          x1="518.533"
          y1="521.597"
          x2="338.033"
          y2="887.004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9D9FF" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_202_2009"
          x1="718.4"
          y1="514.593"
          x2="612.724"
          y2="728.524"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B78EEC" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_202_2009"
          x1="343.5"
          y1="666.5"
          x2="624.5"
          y2="911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BE99ED" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_202_2009"
          x1="486.58"
          y1="137.274"
          x2="465.952"
          y2="610.925"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3A1ED" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_202_2009"
          x1="275.919"
          y1="508.096"
          x2="275.919"
          y2="603.895"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_202_2009"
          x1="203.656"
          y1="348.188"
          x2="203.656"
          y2="416.591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_202_2009"
          x1="648.067"
          y1="885.418"
          x2="648.067"
          y2="992.716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#945CDB" />
          <stop offset="1" stopColor="#945CDB" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
