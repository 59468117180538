import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";
import IndexTextCard from "../../../kit/IndexTextCard";

const shiftedCard = {
  position: "relative",
  bottom: "96px",
};

export default function Section5() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmail = useMediaQuery(theme.breakpoints.down(340));

  const cardSx = {
    flex: downLg ? "auto" : "1 1 0",
    minWidth: downMd ? (isSmail ? "auto" : "300px") : "390px",
    height: downMd ? "274px" : "168px",
    flexDirection: downMd ? "column" : "row",
    gap: downMd ? "70px" : 0,
  };

  return (
    <Section
      tag="section"
      bg="#333647"
      mwidth="1200px"
      sxsect={{
        p: downMd ? "50px 0" : "100px 0",
      }}
    >
      <Typography variant={downMd ? "h4" : "h3"} sx={{color: "white", maxWidth: downMd ? "250px" : "860px"}}>
        Процесс разработки дизайна
      </Typography>
      <Typography
        variant="t1"
        sx={{
          color: "white",
          mt: "20px",
          maxWidth: "460px",
        }}
      >
          Представляем вашему вниманию
          технологический процесс.
      </Typography>
      <Box
        sx={{
          mt: downMd ? "60px" : "96px",
          display: "flex",
          flexDirection: downLg ? "column" : "row",
          flexWrap: "wrap",
          justifyContent: downLg ? "space-evenly" : "space-between",
          alignItems: "center",
          columnGap: "40px",
          rowGap: downLg ? (downMd ? "25px" : "40px") : "70px",
        }}
      >
        <IndexTextCard
          indexText="01"
          headerText="Аналитика"
          text="Тщательный сбор и анализ как ваших требований, так и тех вызовов, которые есть на вашем рынке."
          sx={{
            ...cardSx,
            order: 1,
          }}
        />
        <IndexTextCard
          indexText="02"
          headerText="Дизайн"
          text="Составление и утверждение концепта дизайна, первых макетов."
          sx={{
            ...cardSx,
            order: downLg ? 2 : 3,
          }}
        />
        <IndexTextCard
          indexText="03"
          headerText="Прототипы"
          text="Презентация готовых интерфейсов, которые требуют экспертного мнения клиента."
          sx={{
            ...cardSx,
            order: downLg ? 3 : 2,
            ...(!downLg && shiftedCard),
          }}
        />
        <IndexTextCard
          indexText="04"
          headerText="Финал"
          text="Показываем финальный результат, вносим последние правки."
          sx={{
            ...cardSx,
            order: 4,
            ...(!downLg && shiftedCard),
          }}
        />
      </Box>
    </Section>
  );
}
