import { Box, useMediaQuery, useTheme } from "@mui/material";

import Bubbles from "./Bubbles";
import BubblesMobile from "./BubblesMobile";

export default function Section7() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Box
      component="section"
      sx={{
        width: "100%",
        height: isTablet ? (isMobile ? "80px" : "208px ") : "219px",
        backgroundColor: "#F2F3F7",
        position: "relative",
      }}
    >
      {isMobile ? (
        <BubblesMobile
          style={{
            position: "absolute",
            top: "-57px",
            left: "-111px",
          }}
        />
      ) : (
        <Bubbles
          style={{
            position: "absolute",
            top: isTablet ? "-51px" : "-28px",
            left: isTablet ? "-226px" : "calc(50% - (1918px / 2) + 12px)",
          }}
        />
      )}
    </Box>
  );
}
