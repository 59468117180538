import {Typography, Box, useMediaQuery, useTheme, Button} from "@mui/material";

import Section from "../../../kit/section";
import EmailForm from "../../../kit/EmailForm";
import {setIsContactFormOpen} from "../../../stores/contactForm";

export default function Section8() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1100));
  const isWrap = useMediaQuery(theme.breakpoints.down(999));

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="white"
      sxsect={{
        p: isMobile ? "50px 0 68px 0" : "100px 0",
      }}
      sxcont={{
        display: "flex",
        gap: isTablet ? (isMobile ? "30px" : "34px") : "40px",
        flexWrap: isWrap ? "wrap" : "nowrap",
      }}
    >
      <Box>
        <Typography
          variant={isMobile ? "tellOrderMobile" : "tellOrder"}
          component="h1"
          sx={{ maxWidth: isMobile ? "270px" : isTablet ? "630px" : "760px" }}
        >
          Давайте обсудим ваш проект
        </Typography>
        <Typography
          variant="t1"
          sx={{
            maxWidth: "630px",
            mt: isMobile ? "20px" : "30px",
          }}
        >
            Напишите нам по поводу проекта, мы свяжемся с вами для детального обсуждения.
            Отвечаем на все заявки без исключения. Работаем вне рамок географии и часовых поясов.
        </Typography>
      </Box>
        <Box
            zIndex={theme.zIndex.content}
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: isTablet ? "auto" : "363px",
                alignItems: "flex-start",
                paddingTop: isWrap ? "0" : "20px",
            }}
        >
            <Button
                onClick={() => setIsContactFormOpen(true)}
                disableRipple
                variant={isMobile ? "orderServiceMobile" : "orderService"}
                sx={{
                    whiteSpace: "nowrap",
                    padding: "9px 50px"
                }}
            >
                Заполнить форму
            </Button>
        </Box>
      {/*<EmailForm*/}
      {/*  sx={{*/}
      {/*    display: "flex",*/}
      {/*    flexDirection: "column",*/}
      {/*    width: isTablet ? "auto" : "363px",*/}
      {/*    alignItems: isWrap ? "flex-start" : "center",*/}
      {/*    paddingTop: isWrap ? "0" : "20px",*/}
      {/*    gap: isMobile ? "20px" : "30px",*/}
      {/*  }}*/}
      {/*  isWrap={isWrap}*/}
      {/*  buttonSx={{ padding: "9px 50px" }}*/}
      {/*  buttonVariant= {isMobile ? "orderServiceMobile" : "orderService"}*/}
      {/*  EmailColor="#7E2AEA"*/}
      {/*/>*/}
    </Section>
  );
}
