import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import IconTextCard from "../../../kit/IconTextCard";
import NumberIcon from "../../../kit/NumberIcon";
import Section from "../../../kit/section";

export default function Section2() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isSmall = useMediaQuery(theme.breakpoints.down(300));

  const cardSx = {
    minWidth: isSmall ? "auto" : "299px",
    maxWidth: "360px",
    flex: "1 1 0",
    height: "235px",
    boxShadow: `
          0px 100px 80px rgba(126, 42, 234, 0.07),
          0px 41.7776px 33.4221px rgba(126, 42, 234, 0.0503198),
          0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275),
          0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035),
          0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725),
          0px 2.76726px 2.21381px rgba(126, 42, 234, 0.0196802)
      `,
  };

  return (
    <Section
      bg="#7E2AEA"
      mwidth="1200px"
      tag="section"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
      sxcont={{
        position: "relative",
        zIndex: theme.zIndex.content,
      }}
    >
      <Typography variant={downMd ? "h4" : "h3"} color="white">
        С нами удобно работать
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          gap: isMobile ? "25px 10px" : isTablet ? "20px 10px" : "40px",
          mt: "40px",
        }}
      >
        <IconTextCard
          icon={<NumberIcon number="1" color="white" sx={{ backgroundColor: "#7E2AEA" }} />}
          headerText="Всегда на связи"
          headerHeight="auto"
          text={`Работаем 7 дней в неделю без выходных и праздничных дней.`}
          textColor="#4D4D4D"
          headerColor="black"
          backgroundColor="white"
          skeletonDisabel={true}
          sx={cardSx}
        />
        <IconTextCard
          icon={<NumberIcon number="2" color="white" sx={{ backgroundColor: "#7E2AEA" }} />}
          headerText="SEO-услуги"
          headerHeight="auto"
          text={`Хочется клиентов, но не хочется тратить деньги? Наши лучшие специалисты по SEO готовы вам помочь.`}
          textColor="#4D4D4D"
          headerColor="black"
          backgroundColor="white"
          skeletonDisabel={true}
          sx={cardSx}
        />
        <IconTextCard
          icon={<NumberIcon number="3" color="white" sx={{ backgroundColor: "#7E2AEA" }} />}
          headerText="Поддержка"
          headerHeight="auto"
          text={`Боитесь получить сайт и не иметь возможности обновить информацию или изменить картинку? Мы готовы взять эту боль на себя и через 10 лет после завершения заказа`}
          textColor="#4D4D4D"
          headerColor="black"
          backgroundColor="white"
          skeletonDisabel={true}
          sx={cardSx}
        />
      </Box>
    </Section>
  );
}
