import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export default function BubblesMobile({ style }: Props) {
  return (
    <svg
      style={{ ...style }}
      width="600"
      height="801"
      viewBox="0 0 375 801"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103.996 479.758C99.8811 506.005 88.2412 513.65 92.6113 570.228C96.9814 626.805 187.277 686.542 243.671 656.43C310.547 620.72 402.351 702.285 415.532 690.8C445.843 664.389 255.297 563.549 443.571 511.794C631.846 460.04 612.941 263.49 550.7 169.96C488.46 76.4297 446.041 193.853 330.969 157.983C285.702 143.873 236.946 144.891 240.578 231.193C244.211 317.496 205.623 408.532 167.502 416.51C129.381 424.487 109.141 446.949 103.996 479.758Z"
        fill="url(#paint0_linear_1139_8076)"
      />
      <circle
        opacity="0.5"
        cx="218.913"
        cy="381.799"
        r="114.892"
        transform="rotate(-47.9776 218.913 381.799)"
        fill="url(#paint1_linear_1139_8076)"
      />
      <path
        d="M458.752 127.518C470.963 151.545 476.385 203.101 477.57 225.876C479.126 245.829 506.665 423.036 355.021 405.233C203.377 387.431 251.372 256.481 286.985 233.22C322.599 209.959 331.139 209.515 331.07 175.264C331.001 141.014 339.651 109.735 373.013 92.5853C406.375 75.4356 443.488 97.483 458.752 127.518Z"
        fill="url(#paint2_linear_1139_8076)"
      />
      <circle
        cx="34.249"
        cy="417.852"
        r="31.4452"
        transform="rotate(-47.9776 34.249 417.852)"
        fill="url(#paint3_linear_1139_8076)"
        fillOpacity="0.2"
      />
      <circle
        cx="337.523"
        cy="514.524"
        r="10.9916"
        transform="rotate(-47.9776 337.523 514.524)"
        fill="url(#paint4_linear_1139_8076)"
        fillOpacity="0.45"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1139_8076"
          x1="262.412"
          y1="156.428"
          x2="400.942"
          y2="448.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B379FD" />
          <stop offset="0.432274" stopColor="#7E2AEA" stopOpacity="0" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1139_8076"
          x1="311.181"
          y1="309.903"
          x2="121.254"
          y2="381.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AE70FF" stopOpacity="0.61" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1139_8076"
          x1="404.301"
          y1="84.9633"
          x2="261.107"
          y2="361.306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3A1ED" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1139_8076"
          x1="34.249"
          y1="386.407"
          x2="64.4778"
          y2="461.127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1139_8076"
          x1="337.523"
          y1="503.532"
          x2="337.523"
          y2="525.515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
      </defs>
    </svg>
  );
}
