import { Box } from "@mui/material";


export default function UsersIcon() {

    return (
        <Box sx={{
            backgroundColor: "#5A1EA8",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
        }}>
            <svg width="41" height="38" viewBox="0 0 41 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.91862" cy="7.91862" r="7.91862" transform="matrix(-1 0 0 1 23.9763 2.19995)" stroke="white" strokeWidth="2.6" />
                <path d="M2.19995 29.7861C2.19995 28.0829 3.27066 26.5636 4.87465 25.9907V25.9907C12.1062 23.408 20.0089 23.408 27.2404 25.9907V25.9907C28.8444 26.5636 29.9151 28.0829 29.9151 29.7861V32.3904C29.9151 34.7411 27.8331 36.5468 25.506 36.2144L24.7301 36.1035C18.9776 35.2817 13.1375 35.2817 7.38493 36.1035L6.60905 36.2144C4.28196 36.5468 2.19995 34.7411 2.19995 32.3904V29.7861Z" stroke="white" strokeWidth="2.6" />
                <path d="M27.9357 18.2561C31.3719 18.2561 34.1575 15.4705 34.1575 12.0343C34.1575 8.59808 31.3719 5.8125 27.9357 5.8125" stroke="white" strokeWidth="2.6" strokeLinecap="round" />
                <path d="M34.7501 32.451L35.3597 32.5381C37.1881 32.7993 38.824 31.3805 38.824 29.5335V27.4873C38.824 26.1491 37.9827 24.9553 36.7224 24.5052C35.4653 24.0562 34.1823 23.7066 32.885 23.4563" stroke="white" strokeWidth="2.6" strokeLinecap="round" />
            </svg>
        </Box>
    );
}