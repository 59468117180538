import { CSSProperties } from "react";
import { Box } from "@mui/material";

import image from "../../../image/img_devops/img_bubble_1.png";

interface Props {
  style?: CSSProperties;
}

export const BubblesMobile = ({ style }: Props) => {
  return <Box sx={{ ...style }} component="img" src={image} />;
};
