import { Box } from "@mui/material";


export default function DecisionIcon() {

    return (
        <Box sx={{
            backgroundColor: "#5A1EA8",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
        }}>
            <svg width="43" height="41" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.3623 10.509V20.7199" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M31.1677 25.8254L22.3623 20.72" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.4667 14.6998H2V6.19067" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.19671 33.9516C11.8011 36.5712 15.1201 38.3556 18.7337 39.0792C22.3474 39.8027 26.0934 39.4329 29.4979 38.0165C32.9024 36.6001 35.8124 34.2007 37.8598 31.1219C39.9072 28.043 41 24.4231 41 20.72C41 17.0169 39.9072 13.397 37.8598 10.3181C35.8124 7.23932 32.9024 4.83994 29.4979 3.42352C26.0934 2.0071 22.3474 1.63727 18.7337 2.36083C15.1201 3.08438 11.8011 4.8688 9.19671 7.48837L2 14.6998" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    );
}