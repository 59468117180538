import { Box, Button, SvgIcon, TextField, Typography, useMediaQuery, useTheme, Modal } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LockIcon } from "./LockIcon";
import {
  sendContactForm,
  setContactFormNameField,
  setContactFormPhoneNumberField,
  setContactFormTelegramField,
  setContactFormWhatsappField,
  setIsContactFormOpen,
  useContactFormStore,
} from "../stores/contactForm";
import { enqueueSnackbar } from "notistack";

export const ContactFormModal = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isModalOpen = useContactFormStore((state) => state.isModalOpen);
  const isSubmitDisabled = useContactFormStore((state) => state.isSubmitDisabled);
  const nameField = useContactFormStore((state) => state.nameField);
  const phoneNumberField = useContactFormStore((state) => state.phoneNumberField);
  const telegramField = useContactFormStore((state) => state.telegramField);
  const whatsappField = useContactFormStore((state) => state.whatsappField);

  async function handleSendForm() {
    const message = await sendContactForm();

    if (message) enqueueSnackbar(message);
  }
  
  return (
    <Modal
      disableScrollLock
      open={isModalOpen}
      onClose={() => setIsContactFormOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "340px" : "380px",
          bgcolor: "background.paper",
          borderRadius: "12px",

          boxShadow: 24,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%", display: "flex", justifyContent: "end", pt: "15px", pr: "15px" }}>
              <SvgIcon
                onClick={() => setIsContactFormOpen(false)}
                sx={{
                  cursor: "pointer",
                  color: "#9A9AAF",
                  "&:active": {
                    color: "#7E2AEA",
                  },
                }}
                component={ClearIcon}
              />
            </Box>
            <Box sx={{ padding: "0  40px 33px" }}>
              <Box>
                <Typography variant="h4">Оставьте ваши контактные данные</Typography>
                <Typography sx={{ mt: "12xp" }} component="p">
                  и мы скоро с вами свяжемся!
                </Typography>
              </Box>
              <Box>
                <TextField
                  value={nameField}
                  onChange={(e) => setContactFormNameField(e.target.value)}
                  placeholder="Имя"
                  name="name"
                  fullWidth
                  sx={{
                    width: isMobile ? "266px" : "300px",
                    mt: "25px",
                    mb: "10px",
                    "& .MuiInputBase-root": {
                      backgroundColor: "#F2F3F7",
                      height: "45px",
                      borderRadius: "10px",
                    },
                  }}
                  inputProps={{
                    sx: {
                      borderRadius: "10px",
                      fontSize: "18px",
                      lineHeight: "21px",
                      py: 0,
                    },
                  }}
                />
                <TextField
                  value={phoneNumberField}
                  onChange={(e) => setContactFormPhoneNumberField(e.target.value)}
                  placeholder="Номер телефона"
                  name="phoneNumber"
                  fullWidth
                  sx={{
                    width: isMobile ? "266px" : "300px",
                    "& .MuiInputBase-root": {
                      backgroundColor: "#F2F3F7",
                      height: "45px",
                      borderRadius: "10px",
                    },
                  }}
                  inputProps={{
                    sx: {
                      borderRadius: "10px",
                      fontSize: "18px",
                      lineHeight: "21px",
                      py: 0,
                    },
                  }}
                />
                <Typography sx={{ mt: "5px", mb: "5px" }} component="p">
                  или
                </Typography>
                <TextField
                  value={telegramField}
                  onChange={(e) => setContactFormTelegramField(e.target.value)}
                  placeholder="Telegram"
                  name="telegram"
                  fullWidth
                  sx={{
                    width: isMobile ? "266px" : "300px",
                    "& .MuiInputBase-root": {
                      backgroundColor: "#F2F3F7",
                      height: "45px",
                      borderRadius: "10px",
                    },
                  }}
                  inputProps={{
                    sx: {
                      borderRadius: "10px",
                      fontSize: "18px",
                      lineHeight: "21px",
                      py: 0,
                    },
                  }}
                />
                <Typography sx={{ mt: "5px", mb: "5px" }} component="p">
                  или
                </Typography>
                <TextField
                  value={whatsappField}
                  onChange={(e) => setContactFormWhatsappField(e.target.value)}
                  placeholder="Whatsapp"
                  name="whatsapp"
                  fullWidth
                  sx={{
                    width: isMobile ? "266px" : "300px",
                    "& .MuiInputBase-root": {
                      backgroundColor: "#F2F3F7",
                      height: "45px",
                      borderRadius: "10px",
                    },
                  }}
                  inputProps={{
                    sx: {
                      borderRadius: "10px",
                      fontSize: "18px",
                      lineHeight: "21px",
                      py: 0,
                    },
                  }}
                />
              </Box>
              <Button
                type="submit"
                disableRipple
                onClick={handleSendForm}
                disabled={isSubmitDisabled}
                sx={{
                  mt: "25px",
                  width: "188px",
                  height: "44px",
                  color: "white",
                  bgcolor: "#7E2AEA",
                  borderRadius: "8px",
                  "&:hover": {
                    background: "#7E2AEA",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#696969ca",
                    color: "white",
                  },
                }}
              >
                Отправить
              </Button>
              <Box sx={{ display: "flex", alignItems: "center", mt: "15px", position: "relative" }}>
                <LockIcon />
                <Typography sx={{ ml: "15px", color: "#9A9AAF" }} component="p">
                  Ваши данные никогда не будут переданы третьим лицам
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
