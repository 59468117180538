import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";
import IndexTextCard from "../../../kit/IndexTextCard";

export default function Section7() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMarginTop = useMediaQuery(theme.breakpoints.down(890));
  const isSmail = useMediaQuery(theme.breakpoints.down(340));

  const cardSx = {
    flex: downLg ? "auto" : "1 1 0",
    minHeight: isMobile ? (isSmail ? "auto" : "254px") : "auto",
    minWidth: downMd ? (isSmail ? "auto" : "300px") : "390px",
    flexDirection: downMd ? "column" : "row",
    gap: downMd ? "40px" : 0,
    height: "200px"
  };

  return (
    <Section
      tag="section"
      bg="#333647"
      mwidth="1200px"
      sxsect={{
        p: isMobile ? "50px 0" : "100px 0",
      }}
      sxcont={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant={downMd ? "h4" : "h3"} color="white" maxWidth="860px" mb={downLg ? 0 : "60px"}>
        Ключевые роли нашей командной разработки
      </Typography>
      <Box
        sx={{
          mt: isMobile ? "40px" : "100px",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          flexDirection: downLg ? "column" : "row",
          justifyContent: "space-between",
          columnGap: "40px",
          rowGap: downLg ? (isMobile ? "25px" : "40px") : "70px",
          "& > :nth-of-type(even)": !downLg
            ? {
                position: "relative",
                bottom: "96px",
              }
            : undefined,
        }}
      >
        <IndexTextCard
          indexText="01"
          headerText="Менеджер проекта"
          text="Сбор требований, риск-менеджмент, контроль разработки."
          sx={cardSx}
        />
        <IndexTextCard
          indexText="02"
          headerText="Frontend - разработчик"
          text="Все, что вы можете увидеть на сайте, написано этим прекрасным и ценным сотрудником."
          sx={cardSx}
        />
        <IndexTextCard
          indexText="03"
          headerText="Тестировщик"
          text="Ручное тестирование с целью соответствия логики работы функционала сайта заявленным требованиям."
          sx={cardSx}
        />
        <IndexTextCard
          indexText="04"
          headerText="UX/UI - дизайнер"
          text="Красивый и удобный дизайн появляется благодаря нашим дизайнерам."
          sx={cardSx}
        />
        <IndexTextCard
          indexText="05"
          headerText="Backend - разработчик"
          text="Настройка серверной архитектуры, работа баз данных, информационная безопасность и все, что находится под “капотом” сайта."
          sx={cardSx}
        />
        <IndexTextCard
          indexText="06"
          headerText="DevOps"
          text="Защита от падения серверов. Безболезненный процесс изменения функционала. Балансировка высоких нагрузок сервера."
          sx={cardSx}
        />
      </Box>
      <Box
        sx={{
          mt: isMarginTop ? "50px" : "0",
          maxWidth: "500px",
          width: downLg ? "auto" : "calc((100% - 40px) / 2)",
          alignSelf: downLg ? "center" : "end",
        }}
      >
        <Typography variant="h4" color="white">
          У нас сильная команда специалистов
        </Typography>
        <Typography variant="t1" color="white" mt="20px">
          Если вам сказали, что это невозможно... То наша команда просто берет и делает. Быстро и качественно
        </Typography>
      </Box>
    </Section>
  );
}
