import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

import NumberTextListCard from "./NumberTextListCard";

export default function Section3() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(650));

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#333647"
      sxsect={{
        paddingTop: isMobile ? "50px" : "100px",
        mb: "-62px",
      }}
    >
      <Typography variant="h3" sx={{ color: "white" }}>
        Cloud Native - это совокупность лучших практик
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: isTablet ? (isMobile ? "25px" : "19px 10px ") : "40px",
          mt: isMobile ? "40px" : "70px",
        }}
      >
        <NumberTextListCard
          indexText="1"
          headerText="Сloud Native эра"
          textItems={[
            "Система сама разберется, сколько ей нужно серверов под текущую нагрузку.",
            "Повышение доступности приложений за счёт автоматизируемой обработки инцидентов.",
            "Повышение безопасности развертывания за счёт исключения человеческого фактора.",
          ]}
          sxUl={{marginTop: isTablet ? "2px" : "auto"}}
          backgroundColor="#7E2AEA"
          sx={{
            width: isTablet ? (isMobile ? "360px" : "299px") : "360px",
            height: isTablet ? (isMobile ? "auto" : "563px") : "457px",
          }}
        />
        <NumberTextListCard
          indexText="2"
          headerText="Облачная эра"
          numbeBgcolor="#333647"
          textItems={[
            "Автоматизация вопросов интеграции и развертывания. ",
            "Более качественная утилизация серверных мощностей. ",
            "Уязвимость в одном приложении не даст злоумышленникам контроль над остальными.",
          ]}
          sx={{
            width: isTablet ? (isMobile ? "360px" : "299px") : "360px",
            height: isTablet ? (isMobile ? "437px" : "563px") : "457px",
          }}
        />
        <NumberTextListCard
          indexText="3"
          headerText="Дооблачная эра"
          numbeBgcolor="#333647"
          textItems={[
            "Не нужны дорогие специалисты, с высокой квалификацией. ",
            "Отсутствие программных абстракций над железом. ",
            "Полный контроль над размещением приложений.",
          ]}
          sx={{
            width: isTablet ? (isMobile ? "360px" : "299px") : "360px",
            maxHeight: isTablet ? (isMobile ? "437px" : "563px") : "457px",
            height: isMobile ? "433px" : "457px"
          }}
        />
      </Box>
    </Section>
  );
}
