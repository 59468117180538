import { useInView } from "react-intersection-observer";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";

import Hero from "./hero";
import Services from "./services";
import Questions from "./your_questions";
import { BubblesMobile } from "./BubblesMobile";

import Footer from "../../kit/footer";
import PhabletHeader from "../../kit/PhabletHeader";
import Navbar from "../../kit/header";

import bubbles from "../../image/img_home/img_bubbles_1.png";
import bubblesMobile from "../../image/img_home/img_bubbles_1_mobile.png";
import bubbles2 from "../../image/img_home/bubbles_footer_o.png";

export default function Home() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { ref, inView } = useInView({ rootMargin: "-80px 0px 0px 0px" });

  return (
    <>
      {isTablet ? (
        <PhabletHeader bgColor={inView ? "#252734" : "#333647"} />
      ) : (
        <Navbar bgColor={inView ? "#252734" : "#333647"} />
      )}
      <Hero sectionRef={ref} />
      <Box
        sx={{
          position: "relative",
          maxWidth: "1200px",
          width: "100%",
          margin: "0 auto",
          height: 0,
        }}
      >
        <Box
          component="img"
          sx={{
            width: isMobile ? "289px" : isTablet ? "690px" : "692px",
            position: "absolute",
            right: isMobile ? "-103px" : isTablet ? "-322px" : "-334px",
            bottom: isMobile ? "-115px" : isTablet ? "-284px" : "-287px",
          }}
          src={isMobile ? bubblesMobile : bubbles}
        />
      </Box>
      <Services />
      <Questions />
      <Box
        sx={{
          position: "relative",
          maxWidth: "1200px",
          width: "100%",
          margin: "0 auto",
          height: 0,
        }}
      >
        {isMobile ? (
          <BubblesMobile
            style={{
              position: "absolute",
              top: "-571px",
              right: "0",
              zIndex: theme.zIndex.bubbles
            }}
          />
        ) : (
          <Box
            component="img"
            sx={{
              position: "absolute",
              minWidth: isTablet ? "90%" : "75%",
              top: isTablet ? "-491px" : "-521px",
              right: isTablet ? "-390px" : "-289px",
              zIndex: theme.zIndex.bubbles
            }}
            src={bubbles2}
          />
        )}
      </Box>
      <Footer />
    </>
  );
}
