export const Arows = () => {
  return (
    <svg
      style={{ marginLeft: "10px" }}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.69993 12.0441L8.60593 7.56206H0.12793V5.43805H8.60593L4.69993 0.956055H7.93993L12.8719 6.50005L7.93993 12.0441H4.69993Z"
        fill="white"
      />
    </svg>
  );
};
