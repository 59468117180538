import { Typography, Box, SxProps, Theme } from "@mui/material";

import NumberIcon from "../../../kit/NumberIcon";

interface Props {
  indexText: string;
  headerText: string;
  textItems: string[];
  numbeBgcolor?: string;
  backgroundColor?: string;
  sx?: SxProps<Theme>;
  sxUl?: SxProps<Theme>
}

export default function NumberTextListCard({
  headerText,
  indexText,
  textItems,
  numbeBgcolor = "#5A1EA8",
  backgroundColor = "#262835",
  sx,
    sxUl
}: Props) {
  return (
    <Box
      sx={{
        backgroundColor,
        borderRadius: "8px",
        p: "20px",
        pb: "40px",
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      <NumberIcon
        number={indexText}
        color="white"
        sx={{
          backgroundColor: numbeBgcolor,
        }}
      />
      <Typography
        component="h3"
        color="white"
        mt="40px"
        mb="33px"
        fontSize="36px"
        sx={{ font: "Rubik", fontWeight: "500", lineHeight: "42px" }}
      >
        {headerText}
      </Typography>
      <Box
        component="ul"
        sx={{
          color: "white",
          pl: "27px",
          m: 0,
          // mt: "auto",
          "& li:not(:last-child)": {
            marginBottom: "14px",
          },
            ...sxUl
        }}
      >
        {textItems.map((textItem) => (
          <li key={textItem}>
            <Typography variant="t1" sx={{letterSpacing: 0}}>{textItem}</Typography>
          </li>
        ))}
      </Box>
    </Box>
  );
}
