import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";

import Section from "../../../kit/section";
import IconTextCard from "../../../kit/IconTextCard";

import ClockIcon from "./ClockIcon.svg";
import DocumentIcon from "./DocumentIcon.svg";

export default function Section5() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1200));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Section
      bg="#F2F3F7"
      mwidth="1200px"
      tag="section"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0 50px 0",
      }}
    >
      <Typography variant="h3">Из чего складывается стоимость</Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: isTablet ? "center" : "none",
          gap: "39px",
          mt: "40px",
        }}
      >
        <IconTextCard
          icon={DocumentIcon}
          headerText="Объем функционала"
          text="Собрать требования, разработать архитектуру, написать документацию - это время и деньги, но это всегда позволяет сэкономить на итоговой стоимости продукта. "
          textColor="#4D4D4D"
          headerColor="black"
          backgroundColor="white"
          headerHeight="30px"
          sx={{ maxWidth: isTablet ? "440px" : "560px", height: "auto"}}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />

        <IconTextCard
          icon={ClockIcon}
          headerText="Сроки разработки"
          text="Мы платим своим сотрудникам за фактически отработанные часы, поэтому вы можете не переживать, что вы оплачиваете чаепития или перекуры нашей команды. Цена зависит от РЕАЛЬНОГО объема разработки "
          textColor="#4D4D4D"
          headerColor="black"
          backgroundColor="white"
          headerHeight="30px"
          sx={{ maxWidth: isTablet ? "440px" : "560px", height: isTablet ? "auto" : "220px" }}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
      </Box>
    </Section>
  );
}
