import { Typography, Box, Button, useMediaQuery, useTheme } from "@mui/material";

import EmailTextButton from "../../../kit/EmailTextButton";
import Section from "../../../kit/section";

import Bubbles from "./Bubbles";
import BubblesMobile from "./BubblesMobile";
import {setIsContactFormOpen} from "../../../stores/contactForm";

export default function Section6() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  return (
    <Section
      tag="section"
      bg="white"
      mwidth="1200px"
      sxsect={{
        p: isMobile ? "50px 0 67px 0" : "100px 0",
        overflow: "hidden",
      }}
      sxcont={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: theme.zIndex.content,
        }}
      >
        <Typography variant="h1" sx={{ maxWidth: "860px" }}>
          Нужна консультация?
        </Typography>
        <Typography variant="t1" sx={{ maxWidth: "560px", mt: isMobile ? "20px" : "30px" }}>
          Напишите нам по поводу проекта, мы свяжемся с вами для детального обсуждения. Отвечаем на все заявки без
          исключения. Работаем вне рамок географии и часовых поясов
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: isMobile ? "20px" : "30px",
            mt: "40px",
            alignItems: "center",
          }}
        >
          <Button
        onClick={() => setIsContactFormOpen(true)}
            disableRipple
            variant="orderService"
            sx={{
              padding: isMobile ? "9px 50px" : "9px 42px",
            }}
          >
            Заполнить форму
          </Button>
        </Box>
      </Box>
      {isMobile ? (
        <BubblesMobile
          style={{
            position: "absolute",
            top: "200px",
            right: "0",
            pointerEvents: "none",
          }}
        />
      ) : (
        <Bubbles
          style={{
            position: "absolute",
            top: isTablet ? "-134px" : "-131px",
            right: isTablet ? "-629px" : "-502px",
            pointerEvents: "none",
            zIndex: theme.zIndex.bubbles,
          }}
        />
      )}
    </Section>
  );
}
