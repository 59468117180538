import { Box } from "@mui/material";


export default function AutomationIcon() {

    return (
        <Box sx={{
            backgroundColor: "#5A1EA8",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
        }}>
            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7505 29.375C14.9952 29.375 17.6255 26.7447 17.6255 23.5C17.6255 20.2553 14.9952 17.625 11.7505 17.625C8.50582 17.625 5.87549 20.2553 5.87549 23.5C5.87549 26.7447 8.50582 29.375 11.7505 29.375Z" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M32.3123 42.5935C35.5569 42.5935 38.1873 39.9632 38.1873 36.7185C38.1873 33.4738 35.5569 30.8435 32.3123 30.8435C29.0676 30.8435 26.4373 33.4738 26.4373 36.7185C26.4373 39.9632 29.0676 42.5935 32.3123 42.5935Z" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M32.3123 16.1562C35.5569 16.1562 38.1873 13.5259 38.1873 10.2812C38.1873 7.03658 35.5569 4.40625 32.3123 4.40625C29.0676 4.40625 26.4373 7.03658 26.4373 10.2812C26.4373 13.5259 29.0676 16.1562 32.3123 16.1562Z" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27.3734 13.4573L16.6882 20.3237" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.6882 26.6763L27.3734 33.5427" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </Box>
    );
}