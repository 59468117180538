import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";
import EmailForm from "../../../kit/EmailForm";

import Bubbles from "./Bubbles";
import BubblesMobile from "./BublesMobile";

export default function YourProduct() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const isWrap = useMediaQuery(theme.breakpoints.down(999));

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#7E2AEA"
      sxsect={{
        padding: isMobile ? "70px 0 50px" : "120px 0",
      }}
      sxcont={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: theme.zIndex.content,
          display: "flex",
          flexDirection: isWrap ? "column" : "row",
          alignItems: "start",
          gap: "40px",
          justifyContent: "space-between",
          padding: isMobile ? "" : isTablet ? "" : "0 106px 0 0",
        }}
      >
        <Box>
          <Typography
            variant={isMobile ? "tellOrderMobile" : "tellOrder"}
            component="h1"
            color="white"
            maxWidth="630px"
          >
            Расскажите нам о своем продукте
          </Typography>
          <Typography variant="t1" mt={isMobile ? "20px" : "30px"} color="white" maxWidth="660px">
            Напишите нам по поводу проекта, мы свяжемся с вами для детального обсуждения. Отвечаем на все заявки без
            исключения. Работаем вне рамок географии и часовых поясов
          </Typography>
        </Box>
        <EmailForm
          sx={{ ml: isTablet ? "0" : "95px", mt: isMobile ? "0" : "24px", alignItems: "center", gap: "30px" }}
          isWrap={isWrap}
          buttonSx={{ mb: "25px", padding: "9px 50px", backgroundColor: "white", color: "black", whiteSpace: "nowrap" }}
          buttonVariant={isMobile ? "mobileForm" : "form"}
        />
      </Box>
      {isMobile ? (
        <BubblesMobile
          style={{
            position: "absolute",
            top: "-174px",
            right: "-1px",
            pointerEvents: "none",
          }}
        />
      ) : (
        <Bubbles
          style={{
            position: "absolute",
            top: isTablet ? "-392px" : "-388px",
            right: isTablet ? "-679px" : "-593px",
            pointerEvents: "none",
          }}
        />
      )}
    </Section>
  );
}
