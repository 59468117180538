import { useState } from "react";
import { Typography, Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Section from "../../../kit/section";

import Bubbles from "./Bubbles";
import RoundedButton from "../RoundedButton";

import BubblesMobil from "../../../image/img_landings/img_bubbles_1_mobile.png";
import { setIsContactFormOpen } from "../../../stores/contactForm";

export default function Section1() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isRubberSmall = useMediaQuery(theme.breakpoints.down(500));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Section
      tag="section"
      bg="#F2F3F7"
      mwidth="1160px"
      sxsect={{
        height: isRubberSmall ? "calc(100vh - 30px)" : "auto",
          paddingTop: isMobile ? "65px" : undefined
      }}
      sxcont={{
        padding: isMobile ? "65px 16px 0 16px" : "160px 16px 199px 16px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          maxWidth: "760px",
          position: "relative",
          zIndex: theme.zIndex.content,
        }}
      >
        <Typography variant={isMobile ? "heroMobile" : "hero"} component="h1">
          Создадим лендинг для вашего бизнеса
        </Typography>
        <Typography
          variant="t1"
          sx={{
            pt: isMobile ? "20px" : "30px",
            maxWidth: "660px",
            color: "black",
          }}
        >
            Разработка включает в себя следующие этапы: <br/>
            Сбор требований, составление технического задания, создание дизайна страницы, утверждение дизайна, верстка и выгрузка на ваш домен и хостинг.
        </Typography>
        <Button
        onClick={() => setIsContactFormOpen(true)}
          disableRipple
          variant={isMobile ? "orderServiceMobile" : "orderService"}
          sx={{
            padding: "9px 50px",
            mt: isMobile ? "30px" : "40px",
          }}
        >
          Заказать
        </Button>
      </Box>
      {isMobile ? (
        <Box
          component="img"
          src={BubblesMobil}
          sx={{ ml: "-36px", width: "146%", display: "block" }}
        />
      ) : (
        <Bubbles
          style={{
            position: "absolute",
            top: isTablet ? "135px" : "12px",
            right: isTablet ? "-533px" : "-595px",
            pointerEvents: "none",
            height: isTablet ? "911px" : "1161px",
          }}
        />
      )}
    </Section>
  );
}
