import { CSSProperties } from "react";


interface Props {
    style?: CSSProperties;
}

export default function Bubbles({ style }: Props) {

    return (
        <svg width="1918" height="1038" viewBox="0 0 1918 1038" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...style }}>
            <circle cx="1475.59" cy="84.5933" r="18.3237" transform="rotate(-0.840987 1475.59 84.5933)" fill="url(#paint0_linear_202_912)" fillOpacity="0.4" />
            <path d="M257.91 211.702C224.34 234.304 186.208 339.137 48.277 383.86C-33.9478 410.521 16.5024 610.082 108.56 641.845C217.727 679.51 221.786 866.529 248.268 869.346C309.164 875.822 224.31 558.581 477.186 715.165C730.062 871.749 929.924 646.976 969.858 480.566C1009.79 314.157 786.768 346.182 755.543 222.822C737.82 152.804 672.734 104.276 580.139 197.759C487.545 291.242 442.741 251.366 394.333 217.069C345.925 182.772 299.872 183.448 257.91 211.702Z" fill="url(#paint1_linear_202_912)" />
            <path d="M1451.73 161.417C1491.09 170.838 1563.51 255.688 1708.37 249.295C1794.72 245.484 1817.33 450.079 1742.21 512.058C1653.14 585.557 1714.81 762.162 1690.99 774.071C1636.21 801.456 1604.64 474.584 1422.58 709.785C1240.52 944.986 974.622 804.403 878.959 662.504C803.702 550.874 777.514 377.695 818 307C829.499 305.5 868.499 325.388 910.999 302C995.499 255.5 1025.55 206.009 1145.01 261.161C1264.47 316.314 1292.48 263.276 1325.82 214.203C1359.16 165.13 1402.54 149.642 1451.73 161.417Z" fill="url(#paint2_linear_202_912)" />
            <circle cx="355.11" cy="308.059" r="224.758" transform="rotate(-0.840987 355.11 308.059)" fill="url(#paint3_linear_202_912)" />
            <circle cx="501.959" cy="549.214" r="131.587" transform="rotate(-0.840987 501.959 549.214)" fill="url(#paint4_linear_202_912)" />
            <path d="M309.393 309.596C275.048 305.022 246.607 317.378 226.537 336.461C188.474 372.655 180.034 445.804 225.072 485.527C257.703 523.651 239.493 562.597 248.363 607.027C257.234 651.457 283.883 663.077 297.707 663.564C311.531 664.051 375.025 683.173 406.828 487.84C409.888 469.047 416.016 438.157 402.578 387.574C389.14 336.99 352.323 315.314 309.393 309.596Z" fill="url(#paint5_linear_202_912)" />
            <path d="M855.41 396.729C835.494 432.631 775.042 483.436 747.305 504.351C723.447 523.33 523.252 709.195 416.416 502.604C309.58 296.014 515.795 244.934 575.523 270.051C635.252 295.169 643.095 305.57 686.269 276.278C729.442 246.985 776.298 231.231 826.393 258.719C876.488 286.206 880.306 351.852 855.41 396.729Z" fill="url(#paint6_linear_202_912)" />
            <circle cx="437.023" cy="216.203" r="47.8993" transform="rotate(-0.840987 437.023 216.203)" fill="url(#paint7_linear_202_912)" />
            <circle cx="809.97" cy="191.377" r="16.5805" transform="rotate(-0.840987 809.97 191.377)" fill="#A260F7" />
            <circle cx="187.411" cy="688.359" r="53.6488" transform="rotate(-0.840987 187.411 688.359)" fill="url(#paint8_linear_202_912)" />
            <circle cx="1159.7" cy="202.702" r="34.2012" transform="rotate(-0.840987 1159.7 202.702)" fill="url(#paint9_linear_202_912)" fillOpacity="0.4" />
            <path d="M1174.63 534.346C1206.66 538.916 1267.33 525.115 1293.66 517.643C1316.93 511.686 1576.69 441.382 1449.98 308.97C1310.99 163.715 1230.62 225.964 1228.22 298.273C1226.52 349.301 1218.38 357.615 1179.44 370.798C1140.5 383.981 1108.31 405.918 1101.75 450.461C1095.18 495.004 1134.6 528.633 1174.63 534.346Z" fill="url(#paint10_linear_202_912)" />
            <circle cx="1611.66" cy="220.661" r="133.711" transform="rotate(-0.840987 1611.66 220.661)" fill="url(#paint11_linear_202_912)" />
            <defs>
                <linearGradient id="paint0_linear_202_912" x1="1475.59" y1="66.2696" x2="1475.59" y2="102.917" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
                <linearGradient id="paint1_linear_202_912" x1="686.242" y1="144.666" x2="503.186" y2="602.446" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B379FD" />
                    <stop offset="0.892358" stopColor="#5613AC" />
                </linearGradient>
                <linearGradient id="paint2_linear_202_912" x1="1027.04" y1="248.572" x2="1358.77" y2="613.302" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B379FD" />
                    <stop offset="0.892358" stopColor="#5613AC" />
                </linearGradient>
                <linearGradient id="paint3_linear_202_912" x1="535.611" y1="167.411" x2="355.11" y2="532.818" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E9D9FF" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear_202_912" x1="607.635" y1="466.871" x2="501.959" y2="680.802" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B78EEC" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint5_linear_202_912" x1="352.75" y1="314.572" x2="206.84" y2="657.284" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BE99ED" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint6_linear_202_912" x1="862.693" y1="291.713" x2="391.781" y2="346.606" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C3A1ED" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint7_linear_202_912" x1="437.023" y1="168.304" x2="437.023" y2="264.102" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
                <linearGradient id="paint8_linear_202_912" x1="187.411" y1="634.711" x2="187.411" y2="742.008" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#945CDB" />
                    <stop offset="1" stopColor="#945CDB" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint9_linear_202_912" x1="1159.7" y1="168.501" x2="1159.7" y2="236.903" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
                <linearGradient id="paint10_linear_202_912" x1="1272.5" y1="244.002" x2="1349" y2="407" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C3A1ED" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint11_linear_202_912" x1="1613.22" y1="126.391" x2="1611.66" y2="354.373" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E9D9FF" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}