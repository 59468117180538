import { Box, IconButton, SxProps, Theme } from "@mui/material";

type Props = {
  leftScroll: () => void;
  rightScroll: () => void;
  sx: SxProps<Theme>;
};

export default function ArrowButtons({ sx, leftScroll, rightScroll }: Props) {
  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <IconButton onClick={leftScroll}>
        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="55" height="55" rx="27.5" fill="#A262F3" fillOpacity="0.3" />
          <path d="M30 35L23 27.5L30 20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </IconButton>
      <IconButton onClick={rightScroll}>
        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="55" height="55" rx="27.5" transform="matrix(-1 0 0 1 55 0)" fill="#A262F3" fillOpacity="0.3" />
          <path d="M25 35L32 27.5L25 20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </IconButton>
    </Box>
  );
}
