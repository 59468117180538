import { useState } from "react";
import * as React from "react";
import {} from "react-router-dom";
import { useLocation, Link } from "react-router-dom";

import {
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  ListItem,
  AppBar,
  List,
  Toolbar,
  IconButton,
  Box,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import { setIsContactFormOpen } from "../stores/contactForm";

import SectionStyled from "./section";
import CloseIcon from "./CloseIcon";
import MenuIcon from "./MenuIcon";

import logotip from "../Icons/Logo/logoPenaHab.svg";
import logotipBlack from "../Icons/Logo/black_logo_PenaHab.svg";
import logotipMobile from "../Icons/Logo/logo_PenaHab_mobile.svg";
import logotipBlackMobile from "../Icons/Logo/black_logo_PenaHab_mobile.svg";

const buttonMenu: { path: string; title: string }[] = [
    { path: "/", title: "Все услуги" },
    { path: "/landings", title: "Разработка Landing Page",},
    { path: "/mobileapps", title: "Разработка мобильных приложений",},
    { path: "/corporatesites", title: "Разработка корпоративных сайтов",},
    { path: "/devops", title: "DevOps & CloudOps",},
    { path: "/cloudnative", title: "Разработка Cloud-Native приложений",},
    { path: "/design", title: "UX/UI дизайн",},
    { path: "/crmsystems", title: "Разработка CRM-систем", },
];

const productButton: { path: string; title: string }[] = [
    { path: "https://hub.pena.digital/", title: "PenaHub"},
    { path: "https://quiz.pena.digital/", title: "PenaQuiz"},
];

interface Props {
  theme?: "dark" | "light";
  bgColor?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },

  ref: React.Ref<unknown>
) {
  return <Slide direction={"left"} ref={ref} {...props} />;
});

const height = "80px";
export default function FullScreenDialog({
  theme = "dark",
  bgColor = "#F2F3F7",
}: Props) {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const themeMUI = useTheme();
  const isMobile = useMediaQuery(themeMUI.breakpoints.down("md"));
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [subMenuProdOpen, setSubMenuProdOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <SectionStyled
      tag="header"
      bg={bgColor}
      mwidth={"1200px"}
      sxsect={{
        minHeight: isMobile? "50px" : {height},
        position: "fixed",
        zIndex: themeMUI.zIndex.header,
      }}
      sxcont={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: isMobile ? "0 16px" : "0 23px 0 40px",
        svg: { color: theme === "dark" ? "#ffffff" : "#000000" },
      }}
    >
      <Box sx={{ bgcolor: "none", paddingTop: isMobile? "6px" : 0 }}>
        <img src={theme === "dark" ? (isMobile? logotipMobile : logotip) : (isMobile? logotipBlackMobile : logotipBlack)} alt="logotip" />
      </Box>
      {!isMobile && (
        <Button
        onClick={() => setIsContactFormOpen(true)}
          disableRipple
          sx={{
            color: theme === "dark" ? "white" : "black",
            border: theme === "dark" ? "1px solid white" : "1px solid black",
            ml: "auto",
            mr: "21px",
            textTransform: "none",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "24px",
            borderRadius: "8px",
            padding: "8px 17px",
            "&:hover": {
              background: "rgba(126, 42, 234, 0.07)",
              bgcolor: "#7E2AEA",
            },
          }}
        >
          Связаться с нами
        </Button>
      )}

      <Button disableRipple variant="text" onClick={handleClickOpen}>
        <MenuIcon />
      </Button>
      <Dialog
        fullScreen
        sx={{ width: isMobile ? "100%" : "320px", ml: "auto", height: "100%" }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            background: theme === "dark" ? "#252734" : "#F2F3F7",
            boxShadow: "none",
            height: isMobile ? "66px" : "100px",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              svg: { color: theme === "dark" ? "#ffffff" : "#000000" },
              pt: "12px",
            }}
          >
            {isMobile && (
              <Box>
                <img
                  src={theme === "dark" ? logotip : logotipBlack}
                  alt="logotip"
                />
              </Box>
            )}
            <IconButton
              sx={{ ml: "auto" }}
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List
          sx={{
            background: theme === "dark" ? "#252734" : "#F2F3F7",
            height: isMobile ? "100vh" : "calc(100vh - 100px)",

            p: "0",
          }}
        >
          <ListItem
            sx={{
              pl: "40px",
              flexDirection: "column",
              alignItems: isMobile ? "stretch" : "end",
              height: "60%",
                overflow: "auto",
            }}
          >
            <Button
                onClick={() => setSubMenuProdOpen(!subMenuProdOpen)}
                disableRipple
                variant="text"
                sx={{
                  fontWeight: "500",
                  // color: location.pathname === url ? theme.palette.purple.main : location.pathname === "/" ? "white" : "black",
                  color: theme === "dark" ? "white" : "black",
                  height: "fit-content",
                  textTransform: "none",
                  justifyContent: isMobile ? "start" : "end",
                  marginBottom: "19px",
                  fontSize: "16px",
                  "&:hover": {
                    background: "none",
                    color: "#7E2AEA",
                  },
                }}
            >
              Наши продукты
            </Button>
              <Box
                  sx={{
                      ml: "20px",
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                  }}
              >
                  {subMenuProdOpen
                      ?
                      <>
                          {productButton.map(({path, title}) =>(
                              <Button
                                  component={Link}
                                  to={path}
                                  disableRipple
                                  variant="text"
                                  target="_blank"
                                  sx={{
                                      fontWeight: "500",
                                      color: theme === "dark" ? "white" : "black",
                                      height: "fit-content",
                                      textTransform: "none",
                                      marginBottom: "19px",
                                      fontSize: "16px",
                                      "&:hover": {
                                          background: "none",
                                          color: "#7E2AEA",
                                      },
                                  }}
                              >
                                  {title}
                              </Button>
                          ))}
                      </>

                      : null
                  }
              </Box>
              <Button
                  onClick={() => setSubMenuOpen(!subMenuOpen)}
                  disableRipple
                  variant="text"
                  sx={{
                      fontWeight: "500",
                      // color: location.pathname === url ? theme.palette.purple.main : location.pathname === "/" ? "white" : "black",
                      color:  theme === "dark" ? "white" : "black",
                      height: "fit-content",
                      textTransform: "none",
                      justifyContent: isMobile ? "start" : "end",
                      marginBottom: "19px",
                      fontSize: "16px",
                      "&:hover": {
                          background: "none",
                          color: "#7E2AEA",
                      },
                  }}
              >
                  Наши услуги
              </Button>
              <Box
                  sx={{
                      ml: "20px",
                      display: "flex",
                      alignItems: isMobile ? "flex-start" : "flex-end",
                      flexDirection: "column",
                  }}
              >
                  {subMenuOpen ?
                      <>
                          {buttonMenu.filter(({ path }) => location.pathname !== path).map(({ path, title }) => (
                              <Button
                                  component={Link}
                                  to={path}
                                  disableRipple
                                  variant="text"
                                  target="_blank"
                                  sx={{
                                      fontWeight: "500",
                                      color: theme === "dark" ? "white" : "black",
                                      height: "fit-content",
                                      textTransform: "none",
                                      textAlign: isMobile ? "start" : "end",
                                      marginBottom: "19px",
                                      fontSize: "16px",
                                      "&:hover": {
                                          background: "none",
                                          color: "#7E2AEA",
                                      },
                                  }}
                              >
                                      {title}
                                  </Button>
                          ))}
                      </> : null}
              </Box>

          </ListItem>
          {isMobile ? (
            <Button
            onClick={() => setIsContactFormOpen(true)}
              variant="outlined"
              sx={{
                position: "absolute",
                bottom: 0,
                mb: "60px",
                width: "188px",
                color: theme === "dark" ? "white" : "black",
                border:
                  theme === "dark" ? "1px solid white" : "1px solid black",
                ml: "40px",
                mt: "180px",
                textTransform: "none",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "24px",
                borderRadius: "8px",
                padding: "8px 17px",
              }}
            >
              Связаться с нами
            </Button>
          ) : (
            <Box
              sx={{
                position: "fixed",
                right: "40px",
                bottom: "60px",
              }}
            >
              <img
                src={theme === "dark" ? logotip : logotipBlack}
                alt="logotip"
              />
            </Box>
          )}
        </List>
      </Dialog>
    </SectionStyled>
      <Box sx={{height: isMobile ? "50px" : {height}}} />
    </>
  );
}
