import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

import ChipsTextCard from "./ChipsTextCard";

export default function TechStack() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  const cardSx = {
    flex: "1 1 0",
    minWidth: isTablet ? "280px" : "294px",
    maxWidth: isTablet ? "auto" : "440px",
    boxShadow: `
          0px 100px 309px rgba(210, 208, 225, 0.24), 
          0px 41.7776px 129.093px rgba(210, 208, 225, 0.172525), 
          0px 22.3363px 69.0192px rgba(210, 208, 225, 0.143066), 
          0px 12.5216px 38.6916px rgba(210, 208, 225, 0.12), 
          0px 6.6501px 20.5488px rgba(210, 208, 225, 0.0969343), 
          0px 2.76726px 8.55082px rgba(210, 208, 225, 0.0674749)
      `,
  };

  return (
    <Section
      tag="section"
      bg="#F2F3F7"
      mwidth="1200px"
      sxsect={{
        p: isMobile ? "50px 0" : "100px 0",
      }}
    >
      <Typography variant={downMd ? "h4" : "h3"} sx={{ fontSize: "36px", lineHeight: "normal"}}>
        Стек технологий
      </Typography>
      <Typography variant="h4" mt={isMobile ? "40px" : "50px"}>
        DevOps
      </Typography>
      <Box
        sx={{
          mt: isMobile ? "35px" : "25px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          gap: isMobile ? "25px" : "40px",
        }}
      >
        <ChipsTextCard badges={["Контейнеризация"]} text="Docker, Podman, CRI-O" sx={cardSx} />
        <ChipsTextCard badges={["Автоматизация"]} text="Ansible, Terraform" sx={cardSx} />
        <ChipsTextCard badges={["Мониторинг"]} text="Prometheus. Grafana" sx={cardSx} />
        <ChipsTextCard badges={["Cетевой стек"]} text="Flannel, Calico, Сilium" sx={cardSx} />
        <ChipsTextCard badges={["CI/CD"]} text="Gitlab, Jenkins, ArgoCD" sx={cardSx} />
        <ChipsTextCard badges={["Управление кластером"]} text="Kubernetes, OpenShift, Rancher" sx={cardSx} />
      </Box>
    </Section>
  );
}
