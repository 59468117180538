import { Typography, Box, SxProps, Theme } from "@mui/material";

interface Props {
  indexText: string;
  text: string;
  sx?: SxProps<Theme>;
  sxHeader?: SxProps<Theme>
}

export default function BigNumberCard({ indexText, text, sx, sxHeader }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
          width: "100%",
        ...sx,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#7E2AEA",
          fontWeight: 500,
          fontSize: "50px",
          lineHeight: "59px",
        }}
      >
        {indexText}
      </Typography>
      <Typography variant="h4" sx={{ mt: "16px", color: "white", fontSize: "18px", lineHeight: "normal", ...sxHeader}}>
        {text}
      </Typography>
    </Box>
  );
}
