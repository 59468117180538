import { Typography, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

export default function Section5() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <Section
      mwidth="1200px"
      bg="#7E2AEA"
      tag="section"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
    >
      <Typography variant="h1" maxWidth="960px" color="white">
        Используйте новые возможности для бизнеса
      </Typography>
      <Typography variant="t1" sx={{mt: isMobile ? "15px" : "30px", maxWidth: "960px", color: "white"}} >
        {`С экономической точки зрения облачные технологии обеспечивают истинную ценность облака, 
                позволяя приложениям масштабироваться и развиваться в гораздо более короткие сроки, чем раньше. 
                Эта масштабируемость создает новые возможности для бизнеса с точки зрения роста доходов, 
                повышения эффективности или улучшения качества обслуживания клиентов`}
      </Typography>
    </Section>
  );
}
