import { Typography, Box, SxProps, Theme, useTheme, useMediaQuery } from "@mui/material";

import NumberIcon from "../../../kit/NumberIcon";

interface Props {
  image: string;
  indexText: string;
  headerText: string;
  text: string;
  sx?: SxProps<Theme>;
}

export default function цTextImageIndexCard({ headerText, image, indexText, text, sx }: Props) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1100));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: isMobile ? "35px" : "40px",
        ...sx,
      }}
    >
      <Box
        sx={{
          backgroundPosition: isTablet ? "center center" : "center bottom",
          backgroundImage: `url(${image})`,
          width: "100%",
          height: isMobile ? "328px" : "440px",
          backgroundColor: "#7E2AEA",
          backgroundSize: isTablet ? "cover" : "contain",
          backgroundRepeat: "no-repeat",
          position: "relative",
          p: "20px",
          borderRadius: "8px",
          overflow: "hidden",
          filter: `
                    drop-shadow(0px 100px 80px rgba(126, 42, 234, 0.07)) 
                    drop-shadow(0px 41.7776px 33.4221px rgba(126, 42, 234, 0.0503198)) 
                    drop-shadow(0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275)) 
                    drop-shadow(0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035)) 
                    drop-shadow(0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725)) 
                    drop-shadow(0px 2.76726px 2.21381px rgba(126, 42, 234, 0.0196802))
                `,
        }}
      >
        <NumberIcon number={indexText} color="#7E2AEA" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Typography variant="h4">{headerText}</Typography>
        <Typography variant="t1">{text}</Typography>
      </Box>
    </Box>
  );
}
