import axios from "axios";


const apiUrl = process.env.REACT_APP_DOMAIN + "/feedback";

export function sendContactFormRequest(body: {
    contact: string;
    whoami: string;
}) {
    return axios(apiUrl + "/callme", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: body,
    });
}