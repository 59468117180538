import { ReactNode } from "react";
import { Typography, Box, SxProps, Theme } from "@mui/material";

import SkeletonIcons from "../../../kit/SkeletonIcon";

interface Props {
  icon: ReactNode;
  text: string;
  sx?: SxProps<Theme>;
}

export default function IconTextCard({ icon, text, sx }: Props) {
  return (
    <Box
      sx={{
        p: "20px",
        display: "flex",
        gap: "30px",
        alignItems: "center",
        backgroundColor: "#262835",
        borderRadius: "12px",
        ...sx,
      }}
    >
      <SkeletonIcons
        sx={{
          backgroundColor: "#7E2AEA",
          width: "36px",
          height: "36px",
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
        }}
        src={icon}
        alt="icon"
      />
      <Typography variant="h4" fontWeight="400" color="white">
        {text}
      </Typography>
    </Box>
  );
}
