import { useTheme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";

export default function Bubbles() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  return (
    <Box
      sx={{
        position: "absolute",
        top: isMobile ? "-177px" : isTablet ? "-120px" : "0",
        right: isMobile ? "111px" : isTablet ? "104px" : "21px",
        svg: {
          width: isMobile ? "440px" : isTablet ? "625px" : "790px",
        },
      }}
    >
      <svg height="706" viewBox="0 0 790 706" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.55">
          <g opacity="0.8">
            <circle cx="484.128" cy="611.551" r="35.1276" fill="url(#paint0_linear_1139_6676)" fillOpacity="0.8" />
          </g>
          <g opacity="0.8">
            <circle cx="742.805" cy="614.745" r="46.3045" fill="url(#paint1_linear_1139_6676)" fillOpacity="0.8" />
          </g>
          <g filter="url(#filter0_d_1139_6676)">
            <circle cx="544.812" cy="594.786" r="59.0782" fill="url(#paint2_linear_1139_6676)" />
          </g>
          <g filter="url(#filter1_d_1139_6676)">
            <circle cx="584.706" cy="620.333" r="59.0782" fill="url(#paint3_linear_1139_6676)" fillOpacity="0.8" />
            <circle cx="584.706" cy="620.333" r="59.0782" fill="url(#paint4_linear_1139_6676)" />
          </g>
          <g filter="url(#filter2_f_1139_6676)">
            <circle cx="584.016" cy="621.02" r="52.2086" fill="url(#paint5_linear_1139_6676)" fillOpacity="0.8" />
          </g>
          <g filter="url(#filter3_d_1139_6676)">
            <circle cx="665.347" cy="625.922" r="59.0782" fill="url(#paint6_linear_1139_6676)" />
          </g>
          <g filter="url(#filter4_d_1139_6676)">
            <circle
              cx="619.049"
              cy="550.078"
              r="59.0782"
              fill="url(#paint7_linear_1139_6676)"
              shapeRendering="crispEdges"
            />
          </g>
          <g filter="url(#filter5_f_1139_6676)">
            <circle cx="618.359" cy="550.765" r="52.2086" fill="url(#paint8_linear_1139_6676)" />
          </g>
          <g filter="url(#filter6_d_1139_6676)">
            <circle cx="689.303" cy="584.408" r="59.0782" fill="url(#paint9_linear_1139_6676)" />
            <circle cx="689.303" cy="584.408" r="59.0782" fill="url(#paint10_linear_1139_6676)" fillOpacity="0.8" />
          </g>
          <g filter="url(#filter7_f_1139_6676)">
            <circle cx="688.613" cy="585.095" r="52.2086" fill="url(#paint11_linear_1139_6676)" fillOpacity="0.8" />
          </g>
        </g>
        <circle cx="670.706" cy="307.814" r="81.2946" fill="url(#paint12_linear_1139_6676)" fillOpacity="0.8" />
        <g filter="url(#filter8_f_1139_6676)">
          <circle cx="669.756" cy="308.76" r="71.8417" fill="url(#paint13_linear_1139_6676)" fillOpacity="0.8" />
        </g>
        <g opacity="0.6">
          <circle cx="80.4631" cy="325.576" r="74.4631" fill="url(#paint14_linear_1139_6676)" fillOpacity="0.8" />
          <g filter="url(#filter9_f_1139_6676)">
            <circle cx="79.5956" cy="326.442" r="65.8046" fill="url(#paint15_linear_1139_6676)" fillOpacity="0.8" />
          </g>
        </g>
        <circle cx="306.586" cy="333.09" r="95.6407" fill="url(#paint16_linear_1139_6676)" fillOpacity="0.8" />
        <g filter="url(#filter10_f_1139_6676)">
          <circle cx="305.469" cy="334.203" r="84.5197" fill="url(#paint17_linear_1139_6676)" />
        </g>
        <circle cx="426.641" cy="330.641" r="95.6407" fill="url(#paint18_linear_1139_6676)" fillOpacity="0.8" />
        <g filter="url(#filter11_f_1139_6676)">
          <circle cx="425.524" cy="331.753" r="84.5197" fill="url(#paint19_linear_1139_6676)" />
        </g>
        <circle cx="212.314" cy="308.497" r="117.501" fill="url(#paint20_linear_1139_6676)" fillOpacity="0.8" />
        <g filter="url(#filter12_f_1139_6676)">
          <circle cx="210.944" cy="309.864" r="103.838" fill="url(#paint21_linear_1139_6676)" fillOpacity="0.8" />
        </g>
        <circle cx="212.314" cy="308.497" r="117.501" fill="url(#paint22_linear_1139_6676)" fillOpacity="0.8" />
        <g filter="url(#filter13_f_1139_6676)">
          <circle cx="210.944" cy="309.864" r="103.838" fill="url(#paint23_linear_1139_6676)" fillOpacity="0.8" />
        </g>
        <circle cx="544.32" cy="308.497" r="117.501" fill="url(#paint24_linear_1139_6676)" />
        <g filter="url(#filter14_f_1139_6676)">
          <circle cx="542.95" cy="309.864" r="103.838" fill="url(#paint25_linear_1139_6676)" fillOpacity="0.8" />
        </g>
        <g filter="url(#filter15_d_1139_6676)">
          <circle
            cx="466.443"
            cy="170.501"
            r="117.501"
            fill="url(#paint26_linear_1139_6676)"
            fillOpacity="0.8"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter16_f_1139_6676)">
          <circle cx="465.073" cy="171.868" r="103.838" fill="url(#paint27_linear_1139_6676)" fillOpacity="0.8" />
        </g>
        <g filter="url(#filter17_d_1139_6676)">
          <circle
            cx="294.283"
            cy="215.589"
            r="117.501"
            fill="url(#paint28_linear_1139_6676)"
            fillOpacity="0.8"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter18_f_1139_6676)">
          <circle cx="292.913" cy="216.956" r="103.838" fill="url(#paint29_linear_1139_6676)" fillOpacity="0.8" />
        </g>
        <g filter="url(#filter19_d_1139_6676)">
          <circle
            cx="387.199"
            cy="282.538"
            r="117.501"
            fill="url(#paint30_linear_1139_6676)"
            fillOpacity="0.8"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter20_f_1139_6676)">
          <circle cx="385.829" cy="283.904" r="103.838" fill="url(#paint31_linear_1139_6676)" fillOpacity="0.8" />
        </g>
        <g filter="url(#filter21_d_1139_6676)">
          <circle
            cx="387.199"
            cy="282.538"
            r="117.501"
            fill="url(#paint32_linear_1139_6676)"
            fillOpacity="0.8"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter22_f_1139_6676)">
          <circle cx="385.829" cy="283.904" r="103.838" fill="url(#paint33_linear_1139_6676)" fillOpacity="0.8" />
        </g>
        <g opacity="0.3">
          <g opacity="0.8">
            <circle cx="65.321" cy="46.4197" r="10.321" fill="url(#paint34_linear_1139_6676)" fillOpacity="0.8" />
          </g>
          <g opacity="0.8">
            <circle cx="141.324" cy="47.358" r="13.6049" fill="url(#paint35_linear_1139_6676)" fillOpacity="0.8" />
          </g>
          <circle cx="83.151" cy="41.4938" r="17.358" fill="url(#paint36_linear_1139_6676)" />
          <circle cx="94.8756" cy="49" r="17.358" fill="url(#paint37_linear_1139_6676)" fillOpacity="0.8" />
          <circle cx="94.8756" cy="49" r="17.358" fill="url(#paint38_linear_1139_6676)" />
          <g filter="url(#filter23_f_1139_6676)">
            <circle cx="94.6736" cy="49.2018" r="15.3396" fill="url(#paint39_linear_1139_6676)" fillOpacity="0.8" />
          </g>
          <circle cx="118.565" cy="50.642" r="17.358" fill="url(#paint40_linear_1139_6676)" />
          <g filter="url(#filter24_d_1139_6676)">
            <circle
              cx="104.963"
              cy="28.358"
              r="17.358"
              fill="url(#paint41_linear_1139_6676)"
              shapeRendering="crispEdges"
            />
          </g>
          <g filter="url(#filter25_f_1139_6676)">
            <circle cx="104.762" cy="28.5599" r="15.3396" fill="url(#paint42_linear_1139_6676)" />
          </g>
          <g filter="url(#filter26_d_1139_6676)">
            <circle cx="125.604" cy="38.4445" r="17.358" fill="url(#paint43_linear_1139_6676)" />
            <circle cx="125.604" cy="38.4445" r="17.358" fill="url(#paint44_linear_1139_6676)" fillOpacity="0.8" />
          </g>
          <g filter="url(#filter27_f_1139_6676)">
            <circle cx="125.402" cy="38.6463" r="15.3396" fill="url(#paint45_linear_1139_6676)" fillOpacity="0.8" />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_1139_6676"
            x="474.733"
            y="530.708"
            width="144.156"
            height="144.156"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="8" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_6676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_6676" result="shape" />
          </filter>
          <filter
            id="filter1_d_1139_6676"
            x="510.628"
            y="541.255"
            width="146.156"
            height="146.156"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-1" dy="-6" />
            <feGaussianBlur stdDeviation="7" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.547943 0 0 0 0 0.287083 0 0 0 0 0.883333 0 0 0 0.35 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_6676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_6676" result="shape" />
          </filter>
          <filter
            id="filter2_f_1139_6676"
            x="518.808"
            y="555.812"
            width="130.417"
            height="130.417"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter3_d_1139_6676"
            x="595.269"
            y="561.844"
            width="144.156"
            height="144.156"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="8" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_6676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_6676" result="shape" />
          </filter>
          <filter
            id="filter4_d_1139_6676"
            x="548.971"
            y="486"
            width="144.156"
            height="144.156"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="8" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_6676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_6676" result="shape" />
          </filter>
          <filter
            id="filter5_f_1139_6676"
            x="553.15"
            y="485.556"
            width="130.417"
            height="130.417"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter6_d_1139_6676"
            x="619.225"
            y="520.329"
            width="144.156"
            height="144.156"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="8" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_6676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_6676" result="shape" />
          </filter>
          <filter
            id="filter7_f_1139_6676"
            x="623.404"
            y="519.886"
            width="130.417"
            height="130.417"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter8_f_1139_6676"
            x="584.914"
            y="223.918"
            width="169.684"
            height="169.683"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter9_f_1139_6676"
            x="0.791016"
            y="247.637"
            width="157.609"
            height="157.609"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter10_f_1139_6676"
            x="207.949"
            y="236.683"
            width="195.039"
            height="195.039"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter11_f_1139_6676"
            x="328.004"
            y="234.233"
            width="195.039"
            height="195.039"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter12_f_1139_6676"
            x="94.1055"
            y="193.025"
            width="233.678"
            height="233.677"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter13_f_1139_6676"
            x="94.1055"
            y="193.025"
            width="233.678"
            height="233.677"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter14_f_1139_6676"
            x="426.111"
            y="193.025"
            width="233.677"
            height="233.677"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter15_d_1139_6676"
            x="337.941"
            y="48"
            width="261.003"
            height="261.003"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="8" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_6676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_6676" result="shape" />
          </filter>
          <filter
            id="filter16_f_1139_6676"
            x="348.234"
            y="55.0293"
            width="233.677"
            height="233.677"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter17_d_1139_6676"
            x="165.781"
            y="93.0878"
            width="261.003"
            height="261.003"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="8" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_6676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_6676" result="shape" />
          </filter>
          <filter
            id="filter18_f_1139_6676"
            x="176.074"
            y="100.117"
            width="233.677"
            height="233.677"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter19_d_1139_6676"
            x="258.697"
            y="160.036"
            width="261.003"
            height="261.003"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="8" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_6676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_6676" result="shape" />
          </filter>
          <filter
            id="filter20_f_1139_6676"
            x="268.99"
            y="167.066"
            width="233.677"
            height="233.677"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter21_d_1139_6676"
            x="258.697"
            y="160.036"
            width="261.003"
            height="261.003"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="8" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_6676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_6676" result="shape" />
          </filter>
          <filter
            id="filter22_f_1139_6676"
            x="268.99"
            y="167.066"
            width="233.677"
            height="233.677"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter23_f_1139_6676"
            x="66.334"
            y="20.8622"
            width="56.6797"
            height="56.6793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter24_d_1139_6676"
            x="76.6055"
            y="6"
            width="60.7168"
            height="60.7161"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="8" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_6676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_6676" result="shape" />
          </filter>
          <filter
            id="filter25_f_1139_6676"
            x="76.4219"
            y="0.220215"
            width="56.6797"
            height="56.6793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <filter
            id="filter26_d_1139_6676"
            x="97.2461"
            y="16.0864"
            width="60.7168"
            height="60.7161"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="8" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_6676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_6676" result="shape" />
          </filter>
          <filter
            id="filter27_f_1139_6676"
            x="97.0625"
            y="10.3066"
            width="56.6797"
            height="56.6793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_1139_6676" />
          </filter>
          <linearGradient
            id="paint0_linear_1139_6676"
            x1="498.368"
            y1="633.862"
            x2="444.618"
            y2="583.37"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1139_6676"
            x1="761.577"
            y1="644.154"
            x2="690.723"
            y2="577.597"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1139_6676"
            x1="568.762"
            y1="632.308"
            x2="478.363"
            y2="547.391"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9C5AF1" />
            <stop offset="1" stopColor="#DCC8F9" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1139_6676"
            x1="541.421"
            y1="588.162"
            x2="601.606"
            y2="669.447"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1139_6676"
            x1="701.665"
            y1="648.276"
            x2="609.915"
            y2="529.441"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="0.999576" stopColor="#BE8FFE" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1139_6676"
            x1="571.13"
            y1="693.782"
            x2="613.089"
            y2="601.635"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1139_6676"
            x1="728.417"
            y1="665.44"
            x2="651.506"
            y2="560.933"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="0.999379" stopColor="#BE8FFE" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1139_6676"
            x1="686.51"
            y1="562.852"
            x2="555.951"
            y2="521.937"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.293459" stopColor="#7E2AEA" stopOpacity="0.65" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1139_6676"
            x1="605.872"
            y1="435.514"
            x2="621.624"
            y2="584.582"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.447917" stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1139_6676"
            x1="732.015"
            y1="602.77"
            x2="642.484"
            y2="576.606"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AD7BF1" />
            <stop offset="1" stopColor="#C9A6F7" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1139_6676"
            x1="754.369"
            y1="706.156"
            x2="641.689"
            y2="574.589"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.284212" stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1139_6676"
            x1="778.315"
            y1="670.231"
            x2="643.923"
            y2="586.27"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.284212" stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1139_6676"
            x1="603.001"
            y1="169"
            x2="757.527"
            y2="283.464"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_1139_6676"
            x1="617.119"
            y1="269.638"
            x2="681.746"
            y2="358.631"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.447917" stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_1139_6676"
            x1="25.906"
            y1="285.027"
            x2="128.436"
            y2="446.434"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_1139_6676"
            x1="31.3823"
            y1="290.608"
            x2="98.4193"
            y2="381.148"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.87" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_1139_6676"
            x1="291.5"
            y1="308"
            x2="184.876"
            y2="292.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA68FF" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_1139_6676"
            x1="243.544"
            y1="288.177"
            x2="293.318"
            y2="376.337"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#9554EA" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_1139_6676"
            x1="411.555"
            y1="305.55"
            x2="304.931"
            y2="289.76"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C293FF" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_1139_6676"
            x1="374.5"
            y1="289.5"
            x2="469.079"
            y2="340.241"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#9C5DED" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_1139_6676"
            x1="34.5"
            y1="71"
            x2="230.23"
            y2="412.805"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#6229AC" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_1139_6676"
            x1="134.864"
            y1="253.318"
            x2="240.647"
            y2="396.189"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.447917" stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_1139_6676"
            x1="105"
            y1="123"
            x2="235.258"
            y2="407.101"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AE73FA" />
            <stop offset="1" stopColor="#BD89FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_1139_6676"
            x1="134.864"
            y1="253.318"
            x2="297.989"
            y2="368.214"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#9D5AF4" stopOpacity="0.69" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_1139_6676"
            x1="456.002"
            y1="232.5"
            x2="570.204"
            y2="344.951"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#CBA7F9" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_1139_6676"
            x1="473.502"
            y1="225.5"
            x2="609.826"
            y2="175.878"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" stopOpacity="0.47" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_1139_6676"
            x1="328"
            y1="192"
            x2="553.257"
            y2="275.127"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint27_linear_1139_6676"
            x1="443"
            y1="68"
            x2="421.222"
            y2="237.602"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.63" />
          </linearGradient>
          <linearGradient
            id="paint28_linear_1139_6676"
            x1="192"
            y1="130.5"
            x2="351.872"
            y2="275.43"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint29_linear_1139_6676"
            x1="216.833"
            y1="160.41"
            x2="383.095"
            y2="319.987"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.1872" stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint30_linear_1139_6676"
            x1="301.109"
            y1="218.552"
            x2="420.81"
            y2="380.221"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint31_linear_1139_6676"
            x1="309.749"
            y1="227.358"
            x2="415.532"
            y2="370.229"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.447917" stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint32_linear_1139_6676"
            x1="87.998"
            y1="-98.5"
            x2="426.403"
            y2="321.486"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint33_linear_1139_6676"
            x1="309.749"
            y1="227.358"
            x2="415.532"
            y2="370.229"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint34_linear_1139_6676"
            x1="69.5052"
            y1="52.975"
            x2="53.7124"
            y2="38.1398"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint35_linear_1139_6676"
            x1="146.839"
            y1="55.999"
            x2="126.021"
            y2="36.4435"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint36_linear_1139_6676"
            x1="90.188"
            y1="52.5185"
            x2="63.6275"
            y2="27.5683"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9C5AF1" />
            <stop offset="1" stopColor="#DCC8F9" />
          </linearGradient>
          <linearGradient
            id="paint37_linear_1139_6676"
            x1="82.1578"
            y1="39.5476"
            x2="99.8409"
            y2="63.4304"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint38_linear_1139_6676"
            x1="129.24"
            y1="57.2099"
            x2="102.282"
            y2="22.2945"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="0.999576" stopColor="#BE8FFE" />
          </linearGradient>
          <linearGradient
            id="paint39_linear_1139_6676"
            x1="90.8874"
            y1="70.5802"
            x2="103.216"
            y2="43.5063"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint40_linear_1139_6676"
            x1="137.096"
            y1="62.2531"
            x2="114.498"
            y2="31.5473"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E2AEA" />
            <stop offset="0.999379" stopColor="#BE8FFE" />
          </linearGradient>
          <linearGradient
            id="paint41_linear_1139_6676"
            x1="124.784"
            y1="32.1111"
            x2="86.4245"
            y2="20.0897"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.293459" stopColor="#7E2AEA" stopOpacity="0.65" />
            <stop offset="1" stopColor="#E9D8FF" stopOpacity="0.76" />
          </linearGradient>
          <linearGradient
            id="paint42_linear_1139_6676"
            x1="101.093"
            y1="-5.30246"
            x2="105.721"
            y2="38.4959"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.447917" stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint43_linear_1139_6676"
            x1="138.154"
            y1="43.8395"
            x2="111.848"
            y2="36.1524"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AD7BF1" />
            <stop offset="1" stopColor="#C9A6F7" />
          </linearGradient>
          <linearGradient
            id="paint44_linear_1139_6676"
            x1="144.721"
            y1="74.2161"
            x2="111.614"
            y2="35.5597"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.284212" stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
          <linearGradient
            id="paint45_linear_1139_6676"
            x1="151.758"
            y1="63.6605"
            x2="112.272"
            y2="38.9915"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.284212" stopColor="#E9D8FF" stopOpacity="0.76" />
            <stop offset="1" stopColor="#7E2AEA" stopOpacity="0.47" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
