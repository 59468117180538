import { Box } from "@mui/material";


export default function EfficiencyIcon() {

    return (
        <Box sx={{
            backgroundColor: "#5A1EA8",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
        }}>
            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M38.5244 5.33202L24.6632 26.9199L18.6494 22.9889L6.73819 40.6567" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M40.9583 16.2235L38.5269 5.33159L27.3735 7.82129" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    );
}