import {Typography, Box, Link, SxProps, Theme, useMediaQuery, useTheme} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import SkeletonImage from "../../../kit/SkeletonImage";

interface Props {
  sx?: SxProps<Theme>;
  headerText: string;
  text: string;
  LinkSx?: SxProps<Theme>;
  href?: string;
  backgroundImage: string;
}

export default function TextImageLinkCard({ sx, headerText, text, LinkSx, href, backgroundImage }: Props) {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    return (
    <SkeletonImage
      image={backgroundImage}
      width="100%"
      height="434px"
      Children={
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#434556",
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: isTablet ? "400px" : "434px",
            p: "20px",
            boxShadow: `
                0px 100px 309px rgba(37, 39, 52, 0.24), 
                0px 41.7776px 129.093px rgba(37, 39, 52, 0.172525), 
                0px 22.3363px 69.0192px rgba(37, 39, 52, 0.143066), 
                0px 12.5216px 38.6916px rgba(37, 39, 52, 0.12), 
                0px 6.6501px 20.5488px rgba(37, 39, 52, 0.0969343), 
                0px 2.76726px 8.55082px rgba(37, 39, 52, 0.0674749)
            `,
            ...sx,
          }}
        >
          <Typography variant="h4" color="white">
            {headerText}
          </Typography>
          <Typography variant="t1" color="white" mt="14px">
            {text}
          </Typography>
          <Link
            href={href}
            sx={{
              position: "absolute",
              height: "20px",
              color: "#BC8CFB",
              display: "inline-flex",
              gap: "3px",
              textDecoration: "none",
              textUnderlineOffset: "3px",
              mt: "40px",
              borderBottom: "1px solid #BC8CFB",
              pb: "23px",
              "&:hover": {
                color: "#FFFFFF",
                borderBottomColor: "#FFFFFF",
              },
              ...LinkSx,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              Закажите также разработку
            </Typography>
            <ArrowForwardIcon sx={{ height: "20px", width: "20px" }} />
          </Link>
        </Box>
      }
    />
  );
}
