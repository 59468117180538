import { isAxiosError } from "axios";

const backendErrorMessage: Record<string, string> = {
  "user not found": "Пользователь не найден",
  "invalid password": "Неправильный пароль",
  "field <password> is empty": 'Поле "Пароль" не заполнено',
  "field <login> is empty": 'Поле "Логин" не заполнено',
  "field <email> is empty": 'Поле "E-mail" не заполнено',
  "field <phoneNumber> is empty": 'Поле "Номер телефона" не заполнено',
  "user with this email or login is exist": "Пользователь уже существует",
};

const unknownErrorMessage = "Что-то пошло не так. Повторите попытку позже";

export function getMessageFromFetchError(error: any): string | null {
  if (process.env.NODE_ENV !== "production") console.log(error);

  const message = backendErrorMessage[error.response?.data?.message];
  if (message) return message;

  if (error.message === "Failed to fetch") return "Ошибка сети";

  if (isAxiosError(error)) {
    switch (error.code) {
      case "ERR_NETWORK":
        return "Ошибка сети";
      case "ERR_CANCELED":
        return null;
      case "ERR_BAD_REQUEST":
        return "Слишком много запросов";
    }
  }

  return unknownErrorMessage;
}
