import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import IconTextCard from "../../../kit/IconTextCard";
import Section from "../../../kit/section";

const iconSx = {
  fontWeight: 500,
  fontSize: "50px",
  lineHeight: "59px",
  color: "#7E2AEA",
};

export default function Section5() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const cardSx = {
    flex: "1 1 0",
    minWidth: isTablet ? "240px" : "223px",
    maxWidth: isMobile ? "286px" : "260px",
    p: 0,
    mr: isTablet ? "13px" : "98px",
    height: "auto",
  };

  const headerSx = {
    mt: "16px",
    ...(downMd && {
      fontSize: "20px",
      lineHeight: "24px",
    }),
  };

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#F2F3F7"
      sxsect={{
        pt: isMobile ? "50px" : "134px",
        pb: isMobile ? "50px" : "100px",
      }}
    >
      <Typography variant={downMd ? "h4" : "h3"} sx={{ maxWidth: "560px" }}>
        Этапы разработки
      </Typography>
      <Typography variant="t1" sx={{ maxWidth: "560px", mt: "20px" }}>
        Представляем вашему вниманию наш цикл разработки.
      </Typography>
      <Box
        sx={{
          mt: isMobile ? "40px" : "70px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: isMobile ? "" : "space-between",
          rowGap: isMobile ? "40px" : "47px",
          columnGap: "70px"
        }}
      >
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Техзадание"
          headerHeight="auto"
          sxHeader={headerSx}
          text="Сбор требований и составление технического задания."
          icon={<Typography sx={iconSx}>1</Typography>}
          skeletonDisabel={true}
          sx={{
            flex: "1 1 0",
            minWidth: isTablet ? "240px" : "223px",
            maxWidth: isMobile ? "286px" : "260px",
            p: 0,
            mr: isTablet ? "13px" : "98px",
            height: "auto",
          }}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Структура"
          headerHeight="auto"
          sxHeader={headerSx}
          text="Создание команды под ваш заказ."
          icon={<Typography sx={iconSx}>2</Typography>}
          skeletonDisabel={true}
          sx={cardSx}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Дизайн"
          headerHeight="auto"
          sxHeader={headerSx}
          text="Создание и утверждение дизайна."
          icon={<Typography sx={iconSx}>3</Typography>}
          skeletonDisabel={true}
          sx={cardSx}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Программирование"
          headerHeight="auto"
          sxHeader={headerSx}
          text="Процесс написания кода и покрытия его автотестами."
          icon={<Typography sx={iconSx}>4</Typography>}
          skeletonDisabel={true}
          sx={cardSx}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Контент"
          headerHeight="auto"
          sxHeader={headerSx}
          text="Наполнение сайта контентом, который предоставляет клиент. Мы также можем его разработать за вас."
          icon={<Typography sx={iconSx}>5</Typography>}
          skeletonDisabel={true}
          sx={cardSx}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Хостинг"
          headerHeight="auto"
          sxHeader={headerSx}
          text="Размещение сайта на вашем домене и хостинге."
          icon={<Typography sx={iconSx}>6</Typography>}
          skeletonDisabel={true}
          sx={cardSx}
        />
      </Box>
    </Section>
  );
}
