import { CSSProperties } from "react";


interface Props {
    style?: CSSProperties;
}

export default function Bubbles({ style }: Props) {

    return (
        <svg viewBox="0 0 1052 1153" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M212.74 724.988C223.886 763.893 269.525 902.131 311.847 977.5C354.169 1052.87 517.239 1077.5 575.847 999.728C645.347 907.5 824.5 961.323 835.347 937C860.29 881.07 532.344 863.94 759.289 671.688C986.235 479.437 834.064 219.991 688.085 130.678C542.106 41.364 503.781 263.392 376.804 255.047C304.734 250.31 238.497 297.255 298.863 414.169C359.23 531.082 307.478 561.403 259.923 596.872C212.368 632.342 198.807 676.358 212.74 724.988Z" fill="url(#paint0_linear_202_282)" />
            <circle cx="334.379" cy="662.246" r="224.758" transform="rotate(-72.8761 334.379 662.246)" fill="url(#paint1_linear_202_282)" />
            <path d="M321.741 706.209C306.797 737.468 309.777 768.334 321.74 793.311C344.429 840.682 411.409 871.272 463.087 840.682C509.416 821.4 540.847 850.735 585.847 856C630.847 861.266 650.12 839.5 654.847 826.5C659.574 813.5 697.347 759 521.347 668.5C504.413 659.793 476.92 644.437 424.658 641.618C372.396 638.798 340.421 667.135 321.741 706.209Z" fill="url(#paint2_linear_202_282)" />
            <path d="M594.371 133.889C626.652 159.257 667.207 227.014 683.45 257.721C698.396 284.292 850.091 511.476 629.15 584.129C408.209 656.783 390.543 445.072 424.832 390.093C459.12 335.114 470.635 329.023 448.575 281.744C426.515 234.465 418.406 185.701 453.503 140.61C488.601 95.5188 554.019 102.18 594.371 133.889Z" fill="url(#paint3_linear_202_282)" />
            <circle cx="272.266" cy="555.996" r="47.8993" transform="rotate(-72.8761 272.266 555.996)" fill="url(#paint4_linear_202_282)" />
            <circle cx="200.003" cy="382.39" r="34.2012" transform="rotate(-72.8761 200.003 382.39)" fill="url(#paint5_linear_202_282)" />
            <circle cx="363.68" cy="193.575" r="16.5805" transform="rotate(-72.8761 363.68 193.575)" fill="#7E2AEA" />
            <circle cx="644.414" cy="939.067" r="53.6488" transform="rotate(-72.8761 644.414 939.067)" fill="url(#paint6_linear_202_282)" />
            <circle cx="578.498" cy="515.498" r="131.587" transform="rotate(-72.8761 578.498 515.498)" fill="url(#paint7_linear_202_282)" />
            <defs>
                <linearGradient id="paint0_linear_202_282" x1="281.083" y1="296.863" x2="660.084" y2="612.189" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B379FD" />
                    <stop offset="0.695122" stopColor="#5613AC" />
                </linearGradient>
                <linearGradient id="paint1_linear_202_282" x1="514.88" y1="521.598" x2="334.379" y2="887.005" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AE70FF" stopOpacity="0.61" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_202_282" x1="339.847" y1="666.501" x2="620.847" y2="911.001" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BE99ED" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_202_282" x1="491.846" y1="110.013" x2="471.219" y2="583.664" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C3A1ED" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear_202_282" x1="272.266" y1="508.097" x2="272.266" y2="603.895" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
                <linearGradient id="paint5_linear_202_282" x1="200.003" y1="348.189" x2="200.003" y2="416.591" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
                <linearGradient id="paint6_linear_202_282" x1="644.414" y1="885.419" x2="644.414" y2="992.716" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#945CDB" />
                    <stop offset="1" stopColor="#945CDB" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint7_linear_202_282" x1="684.174" y1="433.154" x2="578.498" y2="647.085" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B78EEC" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}