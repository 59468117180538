import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";

import Section from "../../../kit/section";

import NumberLinkCard from "./NumberLinkCard";

import Image1 from "../../../image/img_landings/link-card1.svg";
import Image2 from "../../../image/img_landings/link-card2.svg";
import Image3 from "../../../image/img_landings/link-card3.svg";

type Props = {
  isMobile: boolean;
};

export default function Section4({ isMobile }: Props) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1200));
  const isWrap = useMediaQuery(theme.breakpoints.down(1140));

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="white"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
    >
      <Typography variant="h3">Как мы работаем</Typography>
      <Typography variant="t1" sx={{ mt: "20px", maxWidth: "659px" }}>
          Тщательно анализируем требования и рынок. Создаём сайты на голову превосходящие ваших конкурентов.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: isWrap ? "wrap" : "nowrap",
          justifyContent: isTablet ? (isWrap ? "center" : "space-between") : "",
          marginTop: "40px",
          gap: isTablet ? (isMobile ? "25px" : "10px") : "40px",
        }}
      >
        <NumberLinkCard
          headerText="Изучение рынка и ЦА"
          text="Этап сбора и аналитики требований."
          image={Image1}
          indexText="1"
          sx={{ maxWidth: isTablet ? (isMobile ? "360px" : "360px") : "360px", minHeight: isMobile ? "400px" : "440px" }}
        />
        <NumberLinkCard
          headerText="Подготовка дизайна"
          text="Создание чернового макета дизайна, утверждение дизайна, передача в разработку."
          image={Image2}
          indexText="2"
          sx={{ maxWidth: isTablet ? (isMobile ? "360px" : "360px") : "360px", minHeight: isMobile ? "400px" : "440px" }}
        />
        <NumberLinkCard
          headerText="Вёрстка"
          text="На этом этапе наши программисты превращают дизайн в готовый продукт. Далее сдаем заказ и заливаем при необходимости на ваш домен и хостинг."
          image={Image3}
          indexText="3"
          sx={{ maxWidth: isTablet ? (isMobile ? "360px" : "360px") : "360px", minHeight: isMobile ? "400px" : "440px" }}
        />
      </Box>
    </Section>
  );
}
