import { Typography, Box, SxProps, Theme } from "@mui/material";

interface Props {
  headerText: string;
  textItems: string[];
  highlighted?: boolean;
  sx?: SxProps<Theme>;
}

export default function Card({ headerText, textItems, highlighted = false, sx }: Props) {
  return (
    <Box
      sx={{
        p: "20px",
        pb: "58px",
        backgroundColor: "#434657",
        borderRadius: "12px",
        boxShadow: `
                0px 12.5216px 38.6916px rgba(37, 39, 52, 0.12), 
                0px 6.6501px 20.5488px rgba(37, 39, 52, 0.0969343), 
                0px 2.76726px 8.55082px rgba(37, 39, 52, 0.0674749)
            `,
        ...sx,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#7E2AEA",
          borderRadius: "12px",
          width: "100%",
          py: "25px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: `
                    0px 12.5216px 38.6916px rgba(37, 39, 52, 0.12), 
                    0px 6.6501px 20.5488px rgba(37, 39, 52, 0.0969343), 
                    0px 2.76726px 8.55082px rgba(37, 39, 52, 0.0674749)
                `,
          filter: highlighted
            ? `                
                        drop-shadow(0px 100px 80px rgba(126, 42, 234, 0.07)) 
                        drop-shadow(0px 41.7776px 33.4221px rgba(126, 42, 234, 0.0503198)) 
                        drop-shadow(0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275)) 
                        drop-shadow(0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035)) 
                        drop-shadow(0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725)) 
                        drop-shadow(0px 2.76726px 2.21381px rgba(126, 42, 234, 0.0196802))
                    `
            : undefined,
        }}
      >
        <Typography variant="h4" color="white">
          {headerText}
        </Typography>
      </Box>
      <Box
        component="ul"
        sx={{
          m: 0,
          mt: "40px",
          color: "white",
          pl: "28px",
          "& li:not(:last-child)": {
            marginBottom: "10px",
          },
        }}
      >
        {textItems.map((textItem, index) => (
          <li key={index}>
            <Typography variant="t1">{textItem}</Typography>
          </li>
        ))}
      </Box>
    </Box>
  );
}
