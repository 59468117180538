import { useState } from "react";
import {
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Section from "../../../kit/section";

import laptopImage from "../../../image/img_corporatesites/laptop1.png";
import laptopImageMobile from "../../../image/img_corporatesites/laptop-mobile.png";
import { setIsContactFormOpen } from "../../../stores/contactForm";

export default function () {
  const theme = useTheme();
  const isLitLg = useMediaQuery(theme.breakpoints.down(938));
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1100));
  const isLittle = useMediaQuery(theme.breakpoints.down(400));
  const isRubberSmall = useMediaQuery(theme.breakpoints.down(500));
// 153 157
  return (
    <Section
      tag="section"
      bg="#F2F3F7"
      mwidth="1200px"
      sxsect={{
        padding: isRubberSmall ? "0 0 42px 0" : isLitLg ? "0 0 42px 0" : "137px 0 157px 0",
        alignItems: isRubberSmall ? "end" : "center",
        height: isRubberSmall ? "calc(100vh - 50px)" : "auto",
      }}
      sxcont={{
        position: "relative",
        alignItems: isLitLg ? "center": "unset",
        display: "flex",
        flexDirection: "column"
      }}
    >
      {isLitLg && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position:"relative"
          }}
        >
          <img
            src={laptopImageMobile}
            alt="laptopImageMobile"
            onDragStart={(e) => e.preventDefault()}
            style={{
              width: "100%",
              maxWidth: "339px",
              objectFit: "scale-down",
              userSelect: "none",
              pointerEvents: "none",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          maxWidth: "560px",
          position: "relative",
          zIndex: theme.zIndex.content,
        }}
      >
        <Typography
          variant={isMobile ? "heroMobile" : "hero"}
          component="h1"
          maxWidth="660px"
        >
          Разрабатываем корпоративные сайты
        </Typography>
        <Typography
          variant="t1"
          sx={{
            pt: isMobile ? "20px" : "30px",
            maxWidth: "559px",
            color: "black",
          }}
        >
            Всегда мечтали о такой визитке, которую можно с гордостью  показать своим партнерам и клиентам? Тогда вы по адресу!
        </Typography>
        <Box sx={{ position: "relative"}}>
          <Button
        onClick={() => setIsContactFormOpen(true)}
            disableRipple
            variant={isMobile ? "orderServiceMobile" : "orderService"}
            sx={{
              padding: "9px 50px",
              mt: isMobile ? "30px" : "40px",
            }}
          >
            Заказать
          </Button>
        </Box>
      </Box>
      {!isLitLg && (
        <>
          <img
            src={laptopImage}
            alt="laptopImage"
            onDragStart={(e) => e.preventDefault()}
            style={{
              width: isTablet ? "1300px" : "1754px",
              position: "absolute",
              top: isTablet ? "-400px" : "-610px",
              right: isTablet ? "-662px" : "-865px",
              userSelect: "none",
              pointerEvents: "none",
            }}
          />
        </>
      )}
    </Section>
  );
}
