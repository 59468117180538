import { ReactNode } from "react";
import { Typography, Box, SxProps, Theme, useTheme, useMediaQuery } from "@mui/material";
import SkeletonIcons from "./SkeletonIcon";

interface Props {
  icon: ReactNode;
  iconSx?: SxProps<Theme>;
  skeletonDisabel?: boolean;
  headerText: string;
  headerHeight?: string;
  text?: string;
  textColor: string;
  headerColor: string;
  backgroundColor: string;
  sx?: SxProps<Theme>;
  sxHeader?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
  bottomText?: string;
  bottomTextSx?: SxProps<Theme>;
}

export default function IconTextCard({
  icon,
  skeletonDisabel = false,
  iconSx,
  headerText,
  headerHeight = "56px",
  text,
  textColor,
  backgroundColor,
  sx,
  sxHeader,
  textSx,
  headerColor,
  bottomText,
  bottomTextSx,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        borderRadius: "12px",
        backgroundColor,
        p: "20px",
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      {skeletonDisabel ? (
        <>{icon}</>
      ) : (
        <SkeletonIcons
          sx={{
            ...iconSx,
          }}
          src={icon}
          alt="icon"
        />
      )}

      <Typography
        variant="h4"
        sx={{
          mt: "14px",
          mb: "10px",
          minHeight: isMobile ? "" : headerHeight,
          color: headerColor,
          ...sxHeader,
        }}
      >
        {headerText}
      </Typography>
      <Typography variant="t1" sx={{
          color: textColor,
          flexGrow: 1,
          ...textSx
      }}
      >
        {text}
      </Typography>
      {bottomText && (
        <Typography
          sx={{
            mt: "32px",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#7E2AEA",
            ...bottomTextSx,
          }}
        >
          {bottomText}
        </Typography>
      )}
    </Box>
  );
}
