import { useState } from "react";
import { useLocation } from "react-router-dom";

import { useTheme, Box, Button, useMediaQuery } from "@mui/material";

import SectionStyled from "./section";
import { DropDownMenu } from "./DropDownMenu";

import { setIsContactFormOpen } from "../stores/contactForm";

import logotip from "../Icons/Logo/logoPenaHab.svg";
import logotipBlack from "../Icons/Logo/black_logo_PenaHab.svg";

export type MenuItem = {
  name: string;
  url: string;
  subMenu?: MenuItem[];
};

const buttonMenu: MenuItem[] = [
  {
    name: "Наши продукты",
    url: "",
    subMenu: [
      { name: "PenaHub", url: "https://hub.pena.digital/" },
      { name: "PenaQuiz", url: "https://quiz.pena.digital/" },
    ],
  },
  {
    name: "Наши услуги",
    url: "",
    subMenu: [
      { name: "Все услуги", url: "/" },
      {
        name: "Разработка Landing Page",
        url: "/landings",
      },
      {
        name: "Разработка мобильных приложений",
        url: "/mobileapps",
      },
      {
        name: "Разработка корпоративных сайтов",
        url: "/corporatesites",
      },
      {
        name: "DevOps & CloudOps",
        url: "/devops",
      },
      {
        name: "Разработка Cloud-Native приложений",
        url: "/cloudnative",
      },
      {
        name: "UX/UI дизайн",
        url: "/design",
      },
      {
        name: "Разработка CRM-систем",
        url: "/crmsystems",
      },
    ],
  },
];

interface NavbarProps {
  theme?: "dark" | "light";
  buttons?: MenuItem[];
  bgColor?: string;
}
const height = "80px";
export default function Navbar({
  theme = "dark",
  buttons = buttonMenu,
  bgColor = "#F2F3F7",
}: NavbarProps) {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [dropDownItems, setDropDownItems] = useState<MenuItem[]>([]);
  const location = useLocation();
  const themeMUI = useTheme();
  const isTablet = useMediaQuery(themeMUI.breakpoints.down(1100));

  return (
    <>
      <SectionStyled
        tag="header"
        bg={theme === "dark" ? "#252734" : "#F2F3F7"}
        mwidth={"1200px"}
        sxsect={{
          minHeight: height,
          position: "fixed",
          zIndex: themeMUI.zIndex.header,
          bgcolor: bgColor,
        }}
        sxcont={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <img src={theme === "dark" ? logotip : logotipBlack} alt="logotip" />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: isTablet ? "0" : "14px",
            ml: isTablet ? "0" : "32px",
            flexWrap: "wrap",
          }}
        >
          {buttons.map(({ name, url, subMenu = [] }) => (
            <Button
              key={name}
              sx={{
                whiteSpace: "nowrap",
                textTransform: "none",
                fontSize: "16px",
                color:
                  location.pathname === url
                    ? "#7E2AEA"
                    : theme === "dark"
                    ? "white"
                    : "black",
              }}
              onClick={({ currentTarget }) => {
                setAnchorElement(currentTarget);
                setDropDownItems(subMenu);
              }}
            >
              {name}
            </Button>
          ))}
          <DropDownMenu
            anchorElement={anchorElement}
            setAnchorElement={setAnchorElement}
            items={dropDownItems}
          />
        </Box>
        <Button
          onClick={() => setIsContactFormOpen(true)}
          disableRipple
          sx={{
            color: theme === "dark" ? "white" : "black",
            border: theme === "dark" ? "1px solid white" : "1px solid black",
            ml: "auto",
            textTransform: "none",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "24px",
            borderRadius: "8px",
            padding: "8px 17px",
            "&:hover": {
              background: "rgba(126, 42, 234, 0.07)",
              borderColor: "#7E2AEA",
            },
          }}
        >
          Связаться с нами
        </Button>
      </SectionStyled>
      <Box height={height} />
    </>
  );
}
