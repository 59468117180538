import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";

import IconTextCard from "../../../kit/IconTextCard";
import Section from "../../../kit/section";

import AutomationIcon from "../../../Icons/AutomationIcon.svg";
import ChainIcon from "../../../Icons/ChainIcon.svg";
import FlexibilityIcon from "../../../Icons/FlexibilityIcon.svg";
import IdleIcon from "../../../Icons/IdleIcon.svg";
import IndependenceIcon from "../../../Icons/IndependenceIcon.svg";
import WavyCircleCheckIcon from "../../../Icons/WavyCircleCheckIcon.svg";

export default function Section4() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const cardSx = { maxWidth: "260px", p: 0, height: "auto" };

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#F2F3F7"
      sxsect={{
        padding: isMobile ? "50px 0" : isTablet ? "100px 0 124px 0" : "100px 0",
      }}
      sxcont={{
        mt: "60px",
      }}
    >
      <Typography variant="h3" sx={{ maxWidth: "660px" }}>
        Преимущества Cloud-Native
      </Typography>
      <Typography variant="t1" sx={{ maxWidth: "660px", mt: "20px" }}>
          Безопасность, надежность, комфорт, отказоустойчивость!
      </Typography>
      <Box
        sx={{
          maxWidth: "1100px",
          mt: isMobile ? "40px" : "70px",
          justifyContent: "start",
          display: "flex",
          flexWrap: "wrap",
          columnGap: isTablet ? "7%" : "13%",
          rowGap: isMobile ? "40px" : "50px",
        }}
      >
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Независимость"
          headerHeight="auto"
          sxHeader={{ mt: isMobile? "20px" : "30px", fontSize: isMobile ? "20px" : "24px", lineHeight: "normal"}}
          text="Отсутствие привязки к конкретному железу или ОС "
          icon={IndependenceIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Отсутствие простоев"
          headerHeight="auto"
          sxHeader={{mt: isMobile? "20px" : "30px", fontSize: isMobile ? "20px" : "24px", lineHeight: "normal" }}
          text="Даже если один из серверов выйдет из строя, приложение будет запущено на другом "
          icon={IdleIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Стандартизация"
          headerHeight="auto"
          sxHeader={{ mt: isMobile? "20px" : "30px", fontSize: isMobile ? "20px" : "24px", lineHeight: "normal" }}
          text="Управление настройками вычислительного кластера из нескольких текстовых файлов"
          icon={WavyCircleCheckIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Гибкость бизнеса"
          headerHeight="auto"
          sxHeader={{mt: isMobile? "20px" : "30px", fontSize: isMobile ? "20px" : "24px", lineHeight: "normal"}}
          text="Прямая зависимость потребляемых ресурсов от использования сервиса"
          icon={FlexibilityIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Автоматизация"
          headerHeight="auto"
          sxHeader={{mt: isMobile? "20px" : "30px", fontSize: isMobile ? "20px" : "24px", lineHeight: "normal"}}
          text="Увеличение вычислительных мощностей по мере необходимости"
          icon={AutomationIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Отказоустойчивость"
          headerHeight="auto"
          sxHeader={{ mt: isMobile? "20px" : "30px", fontSize: isMobile ? "20px" : "24px", lineHeight: "normal"}}
          text="Даже если ваше приложение упало, облако его быстро поднимет."
          icon={ChainIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
      </Box>
    </Section>
  );
}
