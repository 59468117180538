import { create } from "zustand";
import { sendContactFormRequest } from "../api/contactForm";
import { getMessageFromFetchError } from "../utils/backendMessageHandler";


interface ContactFormStore {
  isModalOpen: boolean;
  isSubmitDisabled: boolean;
  nameField: string;
  phoneNumberField: string;
  telegramField: string;
  whatsappField: string;
}

const initialState: ContactFormStore = {
  isModalOpen: false,
  isSubmitDisabled: false,
  nameField: "",
  phoneNumberField: "",
  telegramField: "",
  whatsappField: "",
};

export const useContactFormStore = create<ContactFormStore>()(
    (set, get) => initialState
);

export const setIsContactFormOpen = (isModalOpen: ContactFormStore["isModalOpen"]) => useContactFormStore.setState({ isModalOpen });
export const setContactFormNameField = (nameField: ContactFormStore["nameField"]) => useContactFormStore.setState({ nameField });
export const setContactFormTelegramField = (telegramField: ContactFormStore["telegramField"]) => useContactFormStore.setState({ telegramField });
export const setIsSubmitDisabled = (isSubmitDisabled: ContactFormStore["isSubmitDisabled"]) => useContactFormStore.setState({ isSubmitDisabled });
export const setContactFormPhoneNumberField = (phoneNumberField: ContactFormStore["phoneNumberField"]) => {
  if (/^\+?\d*$/.test(phoneNumberField)) useContactFormStore.setState({ phoneNumberField });
};
export const setContactFormWhatsappField = (whatsappField: ContactFormStore["whatsappField"]) => {
  if (/^\+?\d*$/.test(whatsappField)) useContactFormStore.setState({ whatsappField });
};

export const sendContactForm = async (): Promise<string | null> => {
  const { nameField, phoneNumberField, telegramField, whatsappField } = useContactFormStore.getState();

  if (!nameField) return "Имя не указано";
  if (!phoneNumberField && !telegramField && !whatsappField) return "Ни один из контактов не указан";
  if (phoneNumberField && !/^\+\d{5,}|\d{6,}$/.test(phoneNumberField)) return "Номер телефона должен содержать минимум 6 символов";
  if (whatsappField && !/^\+\d{5,}|\d{6,}$/.test(whatsappField)) return "Номер Whatsapp должен содержать минимум 6 символов";

  let contact = `phonenumber:${phoneNumberField}`;
  if (telegramField) contact += `\ntelegram:${telegramField}`;
  if (whatsappField) contact += `\nwhatsapp:${whatsappField}`;

  try {
    useContactFormStore.setState({ isSubmitDisabled: true });
    const response = await sendContactFormRequest({
      contact,
      whoami: nameField,
    });

    if (response.status !== 200) throw new Error(response.statusText);

    useContactFormStore.setState({ ...initialState });

        return "Данные отправлены";
    } catch (error: any) {
        useContactFormStore.setState({ isSubmitDisabled: false });
        return getMessageFromFetchError(error);
    }
};