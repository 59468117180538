import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";

import IconTextCard from "../../../kit/IconTextCard";
import Section from "../../../kit/section";

import AdaptiveIcon from "./AdaptiveIcon.svg";
import ClockTimeIcon from "./ClockTimeIcon.svg";
import LightningIcon from "./LightningIcon.svg";
import PackageIcon from "./PackageIcon.svg";
import RocketIcon from "./RocketIcon.svg";
import WavyCircleCheckIcon from "./WavyCircleCheckIcon.svg";

export default function Section5() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#F2F3F7"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
    >
      <Typography variant="h3" sx={{ maxWidth: "660px" }}>
        Преимущества наших лендингов
      </Typography>
      <Typography variant="t1" sx={{ maxWidth: "660px", mt: "20px" }}>
          Все наши лендинги — это идеальный баланс между стандартами отрасли и требованиями клиента
      </Typography>
      <Box
        sx={{
          maxWidth: "1100px",
          mt: isMobile ? "40px" : "70px",
          justifyContent: isTablet ? "start" : "none",
          display: "flex",
          flexWrap: "wrap",
          columnGap: isTablet ? "88px" : "14%",
          rowGap: isMobile ? "40px" : "50px",
        }}
      >
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Лучшее качество на рынке"
          sxHeader={{ mt: isMobile ? "16px" : "29px", maxWidth: isMobile ? "100%" : "205px", fontSize: isMobile ? "20px" : "24px" }}
          text="Семантическая вёрстка, CSS, HTML, используем только лучшие практики отрасли."
          icon={WavyCircleCheckIcon}
          sx={{ maxWidth: isMobile ? "100%" : "0" || isTablet ? "243px" : "260px", p: 0, height: "auto" }}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Быстрое выполнение"
          sxHeader={{ mt: isMobile ? "16px" : "29px", fontSize: isMobile ? "20px" : "24px"  }}
          text="Мы всегда сможем найти время и свободных специалистов именно для ВАС!"
          icon={ClockTimeIcon}
          sx={{ maxWidth: isMobile ? "100%" : "0" || isTablet ? "243px" : "260px", p: 0, height: "auto" }}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Гарантии поддержки"
          sxHeader={{ mt: isMobile ? "16px" : "29px", fontSize: isMobile ? "20px" : "24px"  }}
          text="Если что-то пойдет не так, вы всегда можете написать нам, и мы совместно решим вашу проблему"
          icon={PackageIcon}
          sx={{ maxWidth: isMobile ? "100%" : "0" || isTablet ? "243px" : "260px", p: 0, height: "auto" }}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Идеально для рекламы"
          sxHeader={{ mt: isMobile ? "16px" : "29px", maxWidth: isMobile ? "100%" : "167px", fontSize: isMobile ? "20px" : "24px"  }}
          text="С нами вы сможете продать больше товара и потратить меньше бюджета на рекламу."
          icon={RocketIcon}
          sx={{ maxWidth: isMobile ? "100%" : "0" || isTablet ? "243px" : "260px", p: 0, height: "auto" }}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Адаптивная реализация"
          sxHeader={{ mt: isMobile ? "16px" : "29px", fontSize: isMobile ? "20px" : "24px"  }}
          text="Компьютерная, планшетная, мобильная версия или же версия для SMART-TV? Больше ничего не станет проблемой и все категории клиентов будут охвачены."
          icon={AdaptiveIcon}
          sx={{ maxWidth: isMobile ? "100%" : "0" || isTablet ? "243px" : "260px", p: 0, height: "auto" }}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#F2F3F7"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Высокая скорость загрузки"
          sxHeader={{ mt: isMobile ? "16px" : "29px", fontSize: isMobile ? "20px" : "24px"  }}
          text="Наши веб-приложения имеют высокую скорость загрузки. Даже при плохом качестве соединения это позволяет охватить дальние деревни и сёла, что благоприятно отражается на бизнесе наших клиентов"
          icon={LightningIcon}
          sx={{
            maxWidth: isMobile ? "100%" : "0" || isTablet ? "243px" : "260px",
            p: 0,
            height: "auto",
          }}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
      </Box>
    </Section>
  );
}
