import { ReactNode } from "react";
import { Typography, Box, useTheme, useMediaQuery, SxProps, Theme, styled } from "@mui/material";

const BoxBigCard = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    backgroundColor: "#333647",
    padding: "20px 0",
  },
}));

interface Props {
  children?: ReactNode;
  indexText: string;
  headerText: string;
  text: string;
  sx?: SxProps<Theme>;
  sxText?: SxProps<Theme>;
}

export default function BigCard({ children, headerText, indexText, text, sx, sxText }: Props) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1200));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <BoxBigCard
      sx={{
        width: "100%",
        mb: "59px",
        borderRadius: "12px",
        backgroundColor: "#252734",
        p: "20px",
        minHeight: "350px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: `
                0px 12.5216px 38.6916px rgba(37, 39, 52, 0.12), 
                0px 6.6501px 20.5488px rgba(37, 39, 52, 0.0969343), 
                0px 2.76726px 8.55082px rgba(37, 39, 52, 0.0674749)
            `,
        ...sx,
      }}
    >
      <Typography variant="h4" sx={{ color: "#7E2AEA" }}>
        {indexText}
      </Typography>
      <Typography
        component="h1"
        sx={{
          fontSize: isMobile ? "30px" : "70px",
          fontWeight: "500",
          wordWrap: "break-word",
          color: "white",
          lineHeight: isMobile ? "35px" : "83px",
        }}
      >
        {headerText}
      </Typography>
      <Typography variant="t1" sx={{ mt: "10px", color: "white", maxWidth: "840px", mb: "75px", ...sxText }}>
        {text}
      </Typography>
      <Box
        sx={{
          mt: isMobile ? "53px" : "0px",
          display: "flex",
          justifyContent: isTablet ? "center" : undefined,
          flexWrap: "wrap",
          gap: isMobile ? "20px" : "40px",
          mb: "-78px",
        }}
      >
        {children}
      </Box>
    </BoxBigCard>
  );
}
