import { useState } from "react";
import {
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Section from "../../../kit/section";

import Bubbles from "./Bubbles";
import { setIsContactFormOpen } from "../../../stores/contactForm";

export default function Section1() {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down(700));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isRubberSmall = useMediaQuery(theme.breakpoints.down(500));
// 188 316 295
  return (
    <Section
      tag="section"
      bg="#F2F3F7"
      mwidth="1200px"
      sxsect={{
        height: isRubberSmall ? "calc(100vh - 50px)" : "auto",
        padding: downLg ? "0 0 81px 0" : isTablet ? "171px 0 295px 0" : "172px 0 316px 0",
      }}
      sxcont={{
        position: "relative",
        height: isMobile ? "100%" : "auto",
        display: isMobile ? "flex" : "block",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      {downLg && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: "auto",
          }}
        >
          <Bubbles
            style={{
              width: "100%",
              height: "100%",
              maxWidth: isRubberSmall? "200px" : "284px",
              pointerEvents: "none",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          position: "relative",
          zIndex: theme.zIndex.content,
          mt: downLg ? "30px" : undefined,
        }}
      >
        <Typography
          variant={isMobile ? "heroMobile" : "hero"}
          component="h1"
          maxWidth="660px"
        >
          UX/UI дизайн
        </Typography>
        <Typography
          variant="t1"
          sx={{
            pt: "30px",
            maxWidth: isTablet ? "490px" : "559px",
            color: "black",
          }}
        >
            Лучший продуктовый дизайн на рынке. Больше вашему пользователю не придется продираться через 5-10 экранов, что бы совершить оплату.
            Мы считаем и обосновываем каждый клик.
        </Typography>
        <Box sx={{ position: "relative"}}>
          <Button
        onClick={() => setIsContactFormOpen(true)}
            disableRipple
            variant="orderService"
            sx={{
              padding: isMobile ? "9px 42px" : "9px 50px",
              mt: isMobile ? "30px" : "40px",
              "&:hover": {
                mt: isMobile ? "29px" : "39px",
              },
            }}
          >
            {isMobile ? "Заказать услугу" : "Заказать"}
          </Button>
        </Box>
      </Box>
      {!downLg && (
        <Bubbles
          style={{
            position: "absolute",
            top: "-154px",
            right: isTablet ? "26px" : "-9px",
            pointerEvents: "none",
          }}
        />
      )}
    </Section>
  );
}
