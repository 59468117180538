import { Box, Button, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";
import EmailTextButton from "./EmailTextButton";
import { setIsContactFormOpen } from "../stores/contactForm";

type EmailFormProps = {
  sx: SxProps<Theme>;
  isWrap: boolean;
  buttonSx: SxProps<Theme>;
  buttonVariant: "form" | "orderService" | "mobileForm" | "orderServiceMobile";
  EmailColor?: string;
  EmailButtonWidth?: string;
};

export default function EmailForm({
  sx,
  isWrap,
  buttonSx,
  buttonVariant,
  EmailColor,
  EmailButtonWidth = "100%",
}: EmailFormProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Box
      zIndex={theme.zIndex.content}
      sx={{
        position: "relative",
        ...sx,
      }}
    >
      <Button
        onClick={() => setIsContactFormOpen(true)}
        disableRipple
        variant={buttonVariant}
        sx={{
          ...buttonSx,
          whiteSpace: "nowrap",
        }}
      >
        Заполнить форму
      </Button>
      <Box
        sx={{
          width: EmailButtonWidth,
          display: "flex",
          justifyContent: isWrap ? "left" : "center",
          paddingRight: "18px",
          // mt: isMobile ? "65px" : "70px",
        }}
      >
        {/*<EmailTextButton color={EmailColor}>Написать на почту</EmailTextButton>*/}
      </Box>
    </Box>
  );
}
