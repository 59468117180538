import { Typography, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

export default function Section4() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Section
      mwidth="1200px"
      bg="#F2F3F7"
      tag="section"
      sxsect={{
        p: isMobile ? "50px 0" : "100px 0",
      }}
      // sxcont={{padding: "0 40px"}}
    >
      <Typography variant="h1" maxWidth="860px">
        Автоматизация бизнеса - это выгодно
      </Typography>
      <Typography variant="t1" sx={{mt: isMobile? "20px" : "30px"}} maxWidth="960px" letterSpacing="0px" lineHeight="21px">
        Как повысить эффективность труда сотрудников? Нужно дать им необходимые инструменты.
      </Typography>
      <Typography variant="t1" sx={{mt: isMobile? "10px" : "20px"}} maxWidth="960px" letterSpacing="0px" lineHeight="21px">
        В наше время возможно создать любой инструмент автоматизации для любого бизнеса. Избавьтесь от рутины и однообразных операций, управляйте бизнесом, а не отчетами.
      </Typography>
    </Section>
  );
}
