import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function MenuIcon() {
  const location = useLocation();
  return (
    <Box
      sx={{
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        "&:hover path": {
          stroke: "#7E2AEA",
        },
      }}
    >
      <svg width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26 1.15002L1 1.15002"
          stroke={location.pathname === "/" ? "white" : "black"}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M26 9.15002L1 9.15002"
          stroke={location.pathname === "/" ? "white" : "black"}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M26 17.15H1"
          stroke={location.pathname === "/" ? "white" : "black"}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </Box>
  );
}
