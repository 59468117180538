import { useTheme, useMediaQuery } from "@mui/material";

import MobileHeader from "../../kit/PhabletHeader";
import Navbar from "../../kit/header";
import Footer from "../../kit/footer";

import Section1 from "./Section1/Section1";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import Section4 from "./Section4/Section4";
import Section5 from "./Section5/Section5";
import Section6 from "./Section6/Section6";
import Section7 from "./Section7/Section7";
import Section8 from "./Section8/Section8";
import Section9 from "./Section9/Section9";

export default function Landings() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  return (
    <>
      {isTablet ? <MobileHeader theme="light" /> : <Navbar theme="light" />}
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 isMobile={isMobile} />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Footer />
    </>
  );
}
