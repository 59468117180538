import {
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Section from "../../../kit/section";

import BubblesBack from "./BubblesBack";
import BubblesFront from "./BubblesFront";

import crmImageMobile from "../../../image/img_crmsystems/crm-mobile.png";
import crmImage from "../../../image/img_crmsystems/crm.png";
import { setIsContactFormOpen } from "../../../stores/contactForm";

export default function Section1() {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const isRubberSmall = useMediaQuery(theme.breakpoints.down(500));

  return (
    <Section
      tag="section"
      bg="#F2F3F7"
      mwidth="1200px"
      sxsect={{
        height: isRubberSmall ? "calc(100vh - 50px)" : "auto",
        padding: downLg ? "0 0 42px 0" : "150px 0 193px 0",
      }}
      sxcont={{
        position: "relative",
        height: downLg ? "100%" : "auto",
        display: downLg ? "flex" : "block",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: downLg ? "center" : "unset",
        padding: isTablet ? "0 40px" : 0
      }}
    >
      {downLg && (
        <Box
          sx={{
            paddingTop: isRubberSmall ? "10px" : "73px",
            display: "flex",
            justifyContent: "center",
            mb: "-24px",
          }}
        >
          <img
            src={crmImageMobile}
            alt=""
            onDragStart={(e) => e.preventDefault()}
            style={{
              width: isRubberSmall ? "300px" : "368px",
              position: "relative",
              left: "70px",
              objectFit: "scale-down",
              userSelect: "none",
              pointerEvents: "none",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          maxWidth: "500px",
          position: "relative",
          zIndex: theme.zIndex.content,
        }}
      >
        <Typography
          variant={isMobile ? "heroMobile" : "hero"}
          component="h1"
          maxWidth="760px"
        >
          Мы создаем CRM&#8209;системы
        </Typography>
        <Typography
          variant="t1"
          sx={{
            pt: isMobile ? "20px" : "30px",
            maxWidth: isTablet ? "505px" : "559px",
            color: "black",
          }}
        >
            Хотите для своей компании внутреннюю CRM-систему или коммерческий продукт, который принесет вам миллионы? Мы готовы воплотить любую вашу мечту!
        </Typography>
        <Box sx={{ position: "relative"}}>
          <Button
        onClick={() => setIsContactFormOpen(true)}
            disableRipple
            variant={isMobile ? "orderServiceMobile" : "orderService"}
            sx={{
              padding: "9px 50px",
              mt: isMobile ? "30px" : "40px",
            }}
          >
            Оставить заявку
          </Button>
        </Box>
      </Box>
      {!downLg && (
        <>
          <BubblesBack
            style={{
              position: "absolute",
              top: isTablet ? "-170px" : "-174px",
              right: isTablet ? "-308px" : "-94px",
              pointerEvents: "none",
            }}
          />
          <img
            src={crmImage}
            alt=""
            onDragStart={(e) => e.preventDefault()}
            style={{
              width: isTablet ? "626px" : "660px",
              position: "absolute",
              top: isTablet ? "-40px" : "-71px",
              right: isTablet ? "-218px" : "-2px",
              userSelect: "none",
              pointerEvents: "none",
            }}
          />
          <BubblesFront
            style={{
              position: "absolute",
              top: isTablet ? "354px" : "363px",
              right: isTablet ? "222px" : "417px",
              pointerEvents: "none",
            }}
          />
        </>
      )}
    </Section>
  );
}
