import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// 161 179 
import SectionStyled from "../../../kit/section";

import Bubbles from "./Bubbles";
import { setIsContactFormOpen } from "../../../stores/contactForm";

import type { SectionRef } from "../../types";

export default function Hero({ sectionRef }: SectionRef) {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const isRubberSmall = useMediaQuery(theme.breakpoints.down(500));

  return (
    <div ref={sectionRef}>
      <SectionStyled
        tag={"section"}
        bg={"#F2F3F7"}
        mwidth={"1200px"}
        sxsect={{
          padding: downLg ? "0 0 42px 0" : "146px 0 179px 0",
          overflow: "hidden",
          height: isRubberSmall ? "calc(100vh - 50px)" : "auto",
          alignItems: isRubberSmall ? "end" : "center",
        }}
        sxcont={{
          position: "relative",
          height: isRubberSmall ? "100%" : "auto",
          display: isRubberSmall ? "flex" : "block",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        {downLg && (
          <Box
            sx={{
              width: "100%",
              height: "300px",
            }}
          >
            <Bubbles
              style={{
                position: "absolute",
                top: "-437px",
                right: "-514px",
                transform: "rotate(274deg) scale(0.625)",
                pointerEvents: "none",
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            position: "relative",
            zIndex: theme.zIndex.content,
          }}
        >
          <Typography
            variant={isMobile ? "heroMobile" : "hero"}
            component="h1"
            sx={{
              maxWidth: "725px",
              span: { color: "#7E2AEA" },
            }}
          >
            Мы лучшие <br/> в Dev
            <span>Ops </span>& Cloud
            <span>Ops</span>
          </Typography>
          <Typography
            sx={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "21px",
              paddingTop: isMobile ? "22px" : "30px",
              maxWidth: "660px",
            }}
          >
            Поддерживаем инфраструктуру, создаем комфортную среду для
            разработки, внедряем лучшие DevOps & CloudOps-практики
          </Typography>
          <Box sx={{ position: "relative"}}>
            <Button
        onClick={() => setIsContactFormOpen(true)}
              disableRipple
              variant={isMobile ? "orderServiceMobile" : "orderService"}
              sx={{
                mt: isMobile ? "29px" : "40px",
                padding: "9px 42px",
              }}
            >
              Заказать услугу
            </Button>
          </Box>
        </Box>
        {!downLg && (
          <Bubbles
            style={{
              position: "absolute",
              top: "-111px",
              right: isTablet ? "-542px" : "-450px",
              pointerEvents: "none",
            }}
          />
        )}
      </SectionStyled>
    </div>
  );
}
