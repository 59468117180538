import { Typography, Box, SxProps, Theme } from "@mui/material";


interface Props {
    indexText: string;
    headerText: string;
    text: string;
    sx?: SxProps<Theme>;
}

export default function IndexTextCard({ indexText, headerText, text, sx }: Props) {

    return (
        <Box sx={{
            backgroundColor: "#252734",
            borderRadius: "12px",
            p: "20px",
            display: "flex",
            maxWidth: "500px",
            minWidth: "390px",
            boxShadow: `
                0px 100px 309px rgba(37, 39, 52, 0.24), 
                0px 41.7776px 129.093px rgba(37, 39, 52, 0.172525), 
                0px 22.3363px 69.0192px rgba(37, 39, 52, 0.143066), 
                0px 12.5216px 38.6916px rgba(37, 39, 52, 0.12), 
                0px 6.6501px 20.5488px rgba(37, 39, 52, 0.0969343), 
                0px 2.76726px 8.55082px rgba(37, 39, 52, 0.0674749)
            `,
            ...sx,
        }}>
            <Box flex="1 1 0">
                <Typography variant="h4" color="#7E2AEA">{indexText}</Typography>
                <Typography variant="h4" color="white" maxWidth="190px" lineHeight={"normal"}>{headerText}</Typography>
            </Box>
            <Typography variant="t1" color="white" flex="1 1 0">{text}</Typography>
        </Box>
    );
}