import { useState } from "react";
import { Typography, Box, Button, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

import Bubbles from "./Bubbles";
import BubblesMobile from "./BubblesMobile";

import penaLogo from "../../../image/img_design/penaLogoDarkBlob.svg";
import {setIsContactFormOpen} from "../../../stores/contactForm";

export default function Section6() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(680));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#7E2AEA"
      sxsect={{
        padding: isMobile ? "50px 0" : "138px 0",
        overflow: "hidden",
      }}
      sxcont={{
        position: "relative",
        padding: isTablet ? "0 40px" : "0 20px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: theme.zIndex.content,
        }}
      >
        <Typography sx={{ lineHeight: "100%" }} variant="h1" color="white" maxWidth="1050px">
          Закажите эффективную CRM-систему в
          <img
            src={penaLogo}
            alt="pena logo"
            style={{
              height: isMobile ? "42px" : "71px",
              marginLeft: isMobile ? "0" : "15px",
              verticalAlign: "text-bottom",
            }}
          />
        </Typography>

        <Button
        onClick={() => setIsContactFormOpen(true)}
          disableRipple
          variant={ isMobile?"orderServiceWhiteMobile" : "orderServiceWhite"}
          sx={{
            mt: "40px",
            padding: "9px 50px",
            backgroundColor: "white",
            color: "black",
          }}
        >
          Заказать
        </Button>
      </Box>
      {isMobile ? (
        <BubblesMobile style={{ position: "absolute", right: "0", top: "139px" }} />
      ) : (
        <Bubbles
          style={{
            position: "absolute",
            top: isTablet ? "-141px" : "-139px",
            right: isTablet ? "-524px" : "-450px",
            pointerEvents: "none",
          }}
        />
      )}
    </Section>
  );
}
