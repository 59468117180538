import { useState } from "react";
import { Typography, Box, Button, useTheme, useMediaQuery } from "@mui/material";

import Section from "../../../kit/section";

import Bubbles from "./Bubbles";

import macbook from "../../../image/macbook/mac.png";
import penaLogo from "../../../image/img_design/penaLogoDarkBlob.svg";
import macbookMobile from "../../../image/macbook/rm405-da01-03-mockup 1.png";
import {setIsContactFormOpen} from "../../../stores/contactForm";

export default function Section8() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const isMinTablet = useMediaQuery(theme.breakpoints.down(938));
  const isPhablet = useMediaQuery(theme.breakpoints.down(1058));
  const isBubbles = useMediaQuery(theme.breakpoints.down(938));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#7E2AEA"
      sxsect={{
        padding: isMinTablet ? "50px 0" : "120px 0",
        pt: isMinTablet ? "294px" : "",
      }}
      sxcont={{
        position: "relative",
      }}
    >
      {isTablet || isMinTablet ? (
        <img
          style={{
            width: isMinTablet ? "747px" : "800px",
            position: "absolute",
            bottom: isMinTablet ? "183px" : "212px",
            left: "-123px",
            zIndex: "1",
          }}
          src={macbookMobile}
          alt="macbookMobile"
        />
      ) : (
        <></>
      )}
      <Box
        sx={{
          position: "relative",
          zIndex: theme.zIndex.content,
        }}
      >
        {isMinTablet === false ? (
          <>
            <Typography
              variant={isMinTablet ? "tellOrderMobile" : "tellOrder"}
              component="h1"
              color="white"
              sx={{ maxWidth: isPhablet ? "720px" : "760px", lineHeight: "62px" }}
            >
              Закажите в
              <img
                src={penaLogo}
                alt="pena logo"
                style={{
                  height: isMinTablet ? "42px" : "71px",
                  marginLeft: "15px",
                  verticalAlign: "text-bottom",
                }}
              />
              корпоративный сайт
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant="h1"
              color="white"
              sx={{ maxWidth: isPhablet ? "700px" : "760px", fontSize: isMinTablet ? "42px" : "70px", }}
            >
              Закажите корпоративный сайт в
              <img
                src={penaLogo}
                alt="pena logo"
                style={{
                  height: isMinTablet ? "42px" : "71px",
                  marginLeft: "15px",
                  verticalAlign: "text-bottom",
                }}
              />
            </Typography>
          </>
        )}

        <Button
        onClick={() => setIsContactFormOpen(true)}
          disableRipple
          variant={isMobile ? "orderServiceWhiteMobile" : "orderServiceWhite"}
          sx={{
            mt: "40px",
            padding: "9px 50px",
            backgroundColor: "white",
            color: "black",
          }}
        >
          Заказать
        </Button>
      </Box>
      <Bubbles
        style={{
          position: "absolute",
          top: isPhablet ? (isBubbles ? "-801px" : "-406px") : "-448px",
          right: isPhablet ? (isBubbles ? "-250px" : "-578px") : "-556px",
          width: isPhablet ? (isBubbles ? "700px" : undefined) : undefined,
          pointerEvents: "none",
        }}
      />

      <img
        style={{
          width: "895px",
          display: isMobile || isMinTablet ? "none" : "",
          userSelect: "none",
          pointerEvents: "none",
          objectPosition: "right center",
          position: "absolute",
          top: isPhablet ? "-396px" : "-435px",
          right: isPhablet ? "-320px" : "-303px",
        }}
        src={macbook}
        alt="macbook"
      />
    </Section>
  );
}
