import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export default function BubblesMobile({ style }: Props) {
  return (
    <svg
      width="249"
      height="165"
      viewBox="0 0 249 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <path
        d="M100.682 240.719C132.323 180.692 69.3611 240.516 72.4317 225.828C76.2699 207.467 88.3287 195.374 110.06 191.962C131.792 188.55 154.01 187.552 154.424 138.765C154.838 89.9778 184.682 86.0317 207.503 100.047C247.711 124.739 298.672 58.5369 331.112 113.074C363.553 167.611 368.608 279.024 260.925 302.846C236.375 308.277 224.257 312.273 219.966 315.373C227.331 318.418 234.315 322.09 240.557 325.559C256.604 328.438 274.167 331.198 266.886 336.922C263.753 339.385 253.828 332.933 240.557 325.559C226.231 322.989 213.113 320.324 219.966 315.373C204.194 308.854 186.671 305.215 170.92 312.629C138.248 328.006 72.2392 294.678 100.682 240.719Z"
        fill="url(#paint0_linear_1139_8277)"
      />
      <circle
        cx="123.181"
        cy="226.28"
        r="83.34"
        transform="rotate(-45.0813 123.181 226.28)"
        fill="url(#paint1_linear_1139_8277)"
      />
      <path
        d="M292.179 105.324C298.382 119.227 299.969 148.464 299.987 161.345C300.295 172.645 310.773 273.394 225.738 259.024C140.704 244.653 171.515 172.154 192.268 160.049C213.022 147.943 217.852 147.937 218.791 128.614C219.729 109.291 225.501 91.8936 244.81 83.1716C264.119 74.4495 284.426 87.9457 292.179 105.324Z"
        fill="url(#paint2_linear_1139_8277)"
      />
      <circle
        cx="127.491"
        cy="111.248"
        r="12.6817"
        transform="rotate(-45.0813 127.491 111.248)"
        fill="url(#paint3_linear_1139_8277)"
      />
      <circle cx="213.827" cy="77.6141" r="6.14803" transform="rotate(-45.0813 213.827 77.6141)" fill="#7E2AEA" />
      <defs>
        <linearGradient
          id="paint0_linear_1139_8277"
          x1="168.883"
          y1="97.2144"
          x2="238.68"
          y2="266.178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B379FD" />
          <stop offset="0.695122" stopColor="#5613AC" />
          <stop offset="0.994792" stopColor="#5613AC" stopOpacity="0.21" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1139_8277"
          x1="190.11"
          y1="174.128"
          x2="123.181"
          y2="309.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AE70FF" stopOpacity="0.61" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1139_8277"
          x1="262.678"
          y1="79.765"
          x2="174.014"
          y2="231.564"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3A1ED" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1139_8277"
          x1="127.491"
          y1="98.566"
          x2="127.491"
          y2="123.929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
      </defs>
    </svg>
  );
}
