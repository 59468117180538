import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

import IconTextCard from "./IconTextCard";

import AnaliticsIcon from "../../../Icons/AnaliticsIcon.svg";
import ContactsIcon from "../../../Icons/ContactsIcon.svg";
import DealsIcon from "../../../Icons/DealsIcon.svg";
import DocumentsIcon from "../../../Icons/DocumentsIcon.svg";
import ExportIcon from "../../../Icons/ExportIcon.svg";
import GoodsIcon from "../../../Icons/GoodsIcon.svg";
import HistoryOfInteractionsIcon from "../../../Icons/HistoryOfInteractionsIcon.svg";
import ManagementIcon from "../../../Icons/ManagementCrmIcon.svg";
import OthersIcon from "../../../Icons/OthersIcon.svg";
import TasksIcon from "../../../Icons/TasksCrmIcon.svg";

export default function Section7() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  const cardSx = {
    flex: "1 1 33%",
    minWidth: "320px",
    minHeight: isTablet ? (isMobile ? "104px" : "95px") : "76px",
  };
  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#38394A"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
      sxcont={{
        padding: isTablet ? "0 40px" : "0 20px",
      }}
    >
      <Typography variant={downMd ? "h4" : "h3"} sx={{ maxWidth: isMobile ? "313px" : "854px", color: "white" }}>
        Основные функции в наших CRM&#8209;системах
      </Typography>
      <Typography variant="t1" sx={{ maxWidth: "421px", mt: "20px", color: "white" }}>
        Для нас не существует слово “невозможно”.
      </Typography>
      <Box
        sx={{
          mt: isMobile ? "40px" : "50px",
          display: "flex",
          flexWrap: "wrap",
          gap: isMobile ? "10px 40px" : "30px 40px",
        }}
      >
        <IconTextCard sx={cardSx} icon={ContactsIcon} text="База контактов" />
        <IconTextCard sx={cardSx} icon={AnaliticsIcon} text="Аналитика" />
        <IconTextCard sx={cardSx} icon={GoodsIcon} text="Товары и услуги" />
        <IconTextCard sx={cardSx} icon={ManagementIcon} text="Распределение доступа" />
        <IconTextCard sx={cardSx} icon={DealsIcon} text="Управление сделками" />
        <IconTextCard sx={cardSx} icon={DocumentsIcon} text="Работа с документами" />
        <IconTextCard sx={cardSx} icon={TasksIcon} text="Задачи и напоминания" />
        <IconTextCard sx={cardSx} icon={ExportIcon} text="Экспорт и импорт информации" />
        <IconTextCard sx={cardSx} icon={HistoryOfInteractionsIcon} text="История взаимодействий" />
        <IconTextCard sx={cardSx} icon={OthersIcon} text="Другие сервисы" />
      </Box>
    </Section>
  );
}
