import { Box } from "@mui/material";


export default function LoyaltyIcon() {

    return (
        <Box sx={{
            backgroundColor: "#5A1EA8",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
        }}>
            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.26099 19.8149H12.8269V40.4111H5.26099C4.92655 40.4111 4.60582 40.2442 4.36934 39.9471C4.13285 39.6499 4 39.247 4 38.8268V21.3993C4 20.9791 4.13285 20.5761 4.36934 20.279C4.60582 19.9819 4.92655 19.8149 5.26099 19.8149V19.8149Z" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.0723 19.9375L21.041 4C22.7318 4 24.3533 4.67165 25.5488 5.86719C26.7444 7.06274 27.416 8.68424 27.416 10.375V15.1562H39.7477C40.1996 15.1551 40.6466 15.2507 41.0585 15.4367C41.4705 15.6226 41.8379 15.8945 42.136 16.2342C42.4342 16.5739 42.6562 16.9734 42.7872 17.406C42.9181 17.8386 42.955 18.2942 42.8953 18.7422L40.5047 37.8672C40.408 38.6349 40.0353 39.3413 39.4561 39.8545C38.8769 40.3677 38.1308 40.6526 37.357 40.6562H13.0723" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    );
}