import { Typography, Box, Button, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

import Card from "./Card";
import {setIsContactFormOpen} from "../../../stores/contactForm";

export default function Section4() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isSmailCard = useMediaQuery(theme.breakpoints.down(340));
  const isSmall = useMediaQuery("(max-width:1100px)");

  const cardSx = {
    height: isTablet ? (isMobile ? "438px" : "412px") : "434px",
    width: isTablet ? (isMobile ? "360px" : "440px") : "auto",
    minWidth: isSmailCard ? "auto" : "320px",
    maxWidth: "440px",
    flex: "1 1 0",
  };

  const orderNowButton = (
    <Button
    onClick={() => setIsContactFormOpen(true)}
      disableRipple
      variant="connect"
      sx={{
        padding: "9px 43px",
        mt: isMobile ? undefined : "40px",
      }}
    >
      Заказать сейчас
    </Button>
  );

  return (
    <Section
      tag="section"
      bg="#252734"
      mwidth="1200px"
      sxsect={{
        pt: isMobile ? "50px" : "100px",
        mb: isMobile ? undefined : "-34px",
        pb: isMobile ? "50px" : undefined,
      }}
      sxcont={{
        width: "100%",
        display: "flex",
        flexWrap: isSmall ? "wrap" : undefined,
        gap: isTablet ? (isMobile ? "40px" : "70px") : "40px",
      }}
    >
      <Box
        sx={{
          width: isSmall ? "100%" : undefined,
          minWidth: "320px",
          maxWidth: isSmall ? undefined : "360px",
        }}
      >
        <Typography variant={downMd ? "h4" : "h3"} color="white" sx={{ maxWidth: isTablet ? undefined : "289px" }}>
          Какие сайты мы предлагаем
        </Typography>
        <Typography variant="t1" mt="20px" color="white" sx={{ maxWidth: isTablet ? "468px" : undefined }}>
            Предоставляем услуги разработки как самописных сайтов, сделанных полностью с нуля, так и созданных на базе той или иной CMS, например, на Tilde или Wordpress
        </Typography>
        {!isMobile && orderNowButton}
      </Box>
      <Box
        sx={{
          width: isSmall ? "100%" : undefined,
          display: "flex",
          flexWrap: isSmall ? "wrap" : undefined,
          gap: isMobile ? "25px" : "40px",
          justifyContent: "space-evenly",
        }}
      >
        <Card
          headerText="С нуля"
          highlighted
          textItems={[
            "В сайт легко внести изменения. ",
            "Полная документация по сайту. ",
            "Является полностью вашей интеллектуальной собственностью. ",
            "Легко читаемый код, который будет актуален десятки лет.",
            "Можно делать все, что угодно."
          ]}
          sx={cardSx}
        />
        <Card
          headerText="CMS "
          textItems={[
            "Более низкая стоимость разработки",
            "Быстрая разработка по готовым шаблонам",
            "Инструмент, доказавший свою эффективность на протяжении десятилетий.",

          ]}
          sx={cardSx}
        />
      </Box>
      {isMobile && orderNowButton}
    </Section>
  );
}
