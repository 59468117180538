import {Typography, Box, useTheme, useMediaQuery, Button} from "@mui/material";

import Section from "../../../kit/section";
import EmailForm from "../../../kit/EmailForm";
import {setIsContactFormOpen} from "../../../stores/contactForm";

export default function Section6() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1100));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isWrap = useMediaQuery(theme.breakpoints.down(998));

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="white"
      sxsect={{
        p: isMobile ? "50px 0 66px 0" : "100px 0",
      }}
      sxcont={{
        display: "flex",
        flexWrap: "wrap",
        gap: isTablet ? "30px" : "40px",
      }}
    >
      <Box>
        <Typography
          variant={isMobile ? "tellOrderMobile" : "tellOrder"}
          component="h1"
          sx={{ maxWidth: isTablet ? "630px" : "760px" }}
        >
          Закажите консультацию
        </Typography>
        <Typography variant="t1" sx={{ maxWidth: "630px", mt: isMobile ? "20px" : "30px" }}>
          Напишите нам по поводу проекта, мы свяжемся с вами для детального обсуждения. Отвечаем на все заявки без
          исключения. Работаем вне рамок географии и часовых поясов
        </Typography>
      </Box>
      <Box
          zIndex={theme.zIndex.content}
          sx={{
            position: "relative",
            mt: isMobile ? "0" : "22px",
            display: "flex",
            flexDirection: "column",
          }}
      >
        <Button
            onClick={() => setIsContactFormOpen(true)}
            disableRipple
            variant={ isMobile?"orderServiceMobile" : "orderService"}
            sx={{
              padding: "9px 50px",
              whiteSpace: "nowrap",
            }}
        >
          Заполнить форму
        </Button>
      </Box>
    </Section>
  );
}
