import { useState } from "react";
import { Box, Skeleton, SxProps, Theme } from "@mui/material";

type SkeletonIconsProps = {
  sx?: SxProps<Theme>;
  src: any;
  alt: string;
};

const SkeletonIcons = ({ sx, src, alt }: SkeletonIconsProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      {isLoading && <Skeleton sx={{ borderRadius: "6px" }} variant="rectangular" width={36} height={36} />}
      <img src={src} alt={alt} style={{ display: isLoading ? "none" : "inline-block" }} onLoad={handleImageLoad} />
    </Box>
  );
};

export default SkeletonIcons;
