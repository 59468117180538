import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";

import Section from "../../../kit/section";

import RoundedButton from "../RoundedButton";

export default function Section3() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#F2F3F7"
      sxsect={{
        padding: `${isMobile ? "50px 0" : "100px 0"}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "54px",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">Наши проекты</Typography>
        {/*<RoundedButton sx={{ mt: "2px" }}>Смотреть портфолио</RoundedButton>*/}
      </Box>
    </Section>
  );
}
