import { Typography, Box, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";

interface Props {
  sx?: SxProps<Theme>;
  info: [[string, string], [string, string], [string, string], [string, string]];
}

export default function BigInfoCard({ sx, info }: Props) {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const downMs = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  const gridTemplateColumns = downMs ? "auto" : downLg ? "1fr 1fr" : "1fr 1fr 1fr 0.6fr";
  const gridTemplateRows = downMs ? "repeat(4, 1fr)" : downLg ? "auto auto" : "auto";

  return (
    <Box
      sx={{
        width: "100%",
        px: "20px",
        pt: "34px",
        pb: "39px",
        display: "grid",
        gridTemplateColumns,
        gridTemplateRows,
        gap: isTablet ? "20px" : "40px",
        borderRadius: "8px",
        backgroundColor: "#7E2AEA",
        position: "relative",
        zIndex: theme.zIndex.content,
        boxShadow: `
                0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275), 
                0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035),
                0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725)
            `,
        ...sx,
      }}
    >
      {info.map((infoColumn, index) => (
        <Box
          key={index}
          sx={{
            color: "white",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: downMd ? "42px" : "50px",
              lineHeight: downMd ? "50px" : "59px",
              whiteSpace: "nowrap",
            }}
          >
            {infoColumn[0]}
          </Typography>
          <Typography variant="t1" sx={{mt: "16px", maxWidth: index === 0 ? "210px" : "240px"}}>
            {infoColumn[1]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
