import { Box } from "@mui/material";


export default function IncomeIcon() {

    return (
        <Box sx={{
            backgroundColor: "#5A1EA8",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
        }}>
            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 42C25.0751 42 30 33.7173 30 23.5C30 13.2827 25.0751 5 19 5C12.9249 5 8 13.2827 8 23.5C8 33.7173 12.9249 42 19 42Z" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19 5H28.2308C34.1731 5 39 13.2865 39 23.5C39 33.7135 34.1731 42 28.2308 42H19" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 11H36" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M30 23H39" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 36H36" stroke="white" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    );
}