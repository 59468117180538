import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export default function BubblesMobile({ style }: Props) {
  return (
    <svg
      width="375"
      height="802"
      viewBox="0 0 375 802"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <path
        d="M90.516 480.303C86.4006 506.55 74.7607 514.195 79.1308 570.773C83.501 627.35 173.797 687.087 230.191 656.975C297.066 621.265 388.87 702.83 402.052 691.345C432.363 664.934 241.816 564.094 430.091 512.339C618.365 460.585 599.461 264.035 537.22 170.505C474.979 76.9746 432.56 194.398 317.488 158.528C272.221 144.418 223.465 145.436 227.098 231.738C230.731 318.041 192.143 409.077 154.022 417.055C115.901 425.032 95.6603 447.494 90.516 480.303Z"
        fill="url(#paint0_linear_1139_8896)"
      />
      <circle
        opacity="0.5"
        cx="205.43"
        cy="382.344"
        r="114.892"
        transform="rotate(-47.9776 205.43 382.344)"
        fill="url(#paint1_linear_1139_8896)"
      />
      <path
        d="M445.271 128.063C457.482 152.09 462.904 203.646 464.089 226.421C465.645 246.374 493.184 423.581 341.54 405.778C189.896 387.975 237.891 257.026 273.504 233.765C309.118 210.504 317.658 210.059 317.589 175.809C317.52 141.558 326.17 110.28 359.532 93.1302C392.894 75.9806 430.007 98.0279 445.271 128.063Z"
        fill="url(#paint2_linear_1139_8896)"
      />
      <circle
        cx="20.7695"
        cy="418.397"
        r="31.4452"
        transform="rotate(-47.9776 20.7695 418.397)"
        fill="url(#paint3_linear_1139_8896)"
        fillOpacity="0.2"
      />
      <circle
        cx="338.523"
        cy="498.524"
        r="10.9916"
        transform="rotate(-47.9776 338.523 498.524)"
        fill="url(#paint4_linear_1139_8896)"
        fillOpacity="0.45"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1139_8896"
          x1="248.932"
          y1="156.973"
          x2="387.462"
          y2="449.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B379FD" />
          <stop offset="0.432274" stopColor="#7E2AEA" stopOpacity="0" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1139_8896"
          x1="297.698"
          y1="310.447"
          x2="107.772"
          y2="381.654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AE70FF" stopOpacity="0.61" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1139_8896"
          x1="390.82"
          y1="85.5083"
          x2="247.626"
          y2="361.851"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3A1ED" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1139_8896"
          x1="20.7695"
          y1="386.952"
          x2="50.9983"
          y2="461.672"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1139_8896"
          x1="338.523"
          y1="487.532"
          x2="338.523"
          y2="509.515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
      </defs>
    </svg>
  );
}
