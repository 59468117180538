import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import IconTextCard from "../../../kit/IconTextCard";
import Section from "../../../kit/section";

import AutomationIcon from "../../../Icons/AutomationIcon.svg";
import ContainerIcon from "../../../Icons/ContainerIcon.svg";
import DeliveryIcon from "../../../Icons/DeliveryIcon.svg";
import IntegrationIcon from "../../../Icons/IntegrationIcon.svg";
import ManagementIcon from "../../../Icons/ManagementIcon.svg";
import MonitoringIcon from "../../../Icons/MonitoringIcon.svg";

export default function Skills() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  const cardSx = {
    flex: "1 1 0",
    minWidth: isMobile ? "393px" : "237px",
    maxWidth: isTablet ? "0" : "360px",
    p: 0,
    mr: isTablet ? 0 : "54px",
  };

  const cardProps = {
    backgroundColor: "#F2F3F7",
    textColor: "#4D4D4D",
    headerColor: "black",
    sx: cardSx,
  };

  const headerSx = {
    mt: isMobile ? "20px" : "30px",
    ...(downMd && {
      fontSize: "20px",
      lineHeight: "24px",
    }),
    maxWidth: "252px",
    whiteSpace: isMobile ? "nowrap" : "",
  };
  const textSx = {
    maxWidth: isMobile ? "285px" : "260px",
  }

  return (
    <Section
      tag="section"
      bg="#F2F3F7"
      mwidth="1200px"
      sxsect={{
        p: isMobile ? "50px 0 50px" : "100px 0  120px",
      }}
    >
      <Typography variant={downMd ? "h4" : "h3"} maxWidth="475px">
        Что мы умеем
      </Typography>
      <Typography variant="t1" mt="20px" maxWidth="660px">
          Наша команда освоила полный спектр компетенций в DevOps & CloudOps разработке
      </Typography>
      <Box
        sx={{
          mt: isMobile ? "38px" : "70px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
          rowGap: isMobile ? "39px" : "50px",
          columnGap: isTablet ? "93px" : "40px"
        }}
      >
        <IconTextCard
          {...cardProps}
          headerText="Непрерывная интеграция"
          sxHeader={headerSx}
          text="Повышение стабильности релизов, за счет тщательной проверки на каждом этапе"
          textSx={textSx}
          icon={IntegrationIcon}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          {...cardProps}
          headerText="Управление облаком"
          sxHeader={headerSx}
          textSx={textSx}
          text="Ресурсы вашего облака используются с максимальной эффективностью"
          icon={ManagementIcon}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          {...cardProps}
          headerText="Непрерывный мониторинг"
          sxHeader={headerSx}
          textSx={textSx}
          text="Мы используем самые передовые технологии, для того что бы максимально быстро реагировать на инциденты"
          icon={MonitoringIcon}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          {...cardProps}
          headerText="Контейнеризация"
          sxHeader={headerSx}
          textSx={textSx}
          text="Приложения исполняются в изолированных контейнерах и не имеют доступа к остальной машине"
          icon={ContainerIcon}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          {...cardProps}
          headerText="Непрерывная доставка"
          sxHeader={headerSx}
          textSx={textSx}
          text="Процесс подготовки и развертывания релиза начнется сразу, как мы убедимся в его качестве "
          icon={DeliveryIcon}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          {...cardProps}
          headerText="DevOps автоматизация"
          sxHeader={headerSx}
          textSx={textSx}
          text="Ваша команда разработки сможет сконцентрироваться исключительно на написании кода"
          icon={AutomationIcon}
          iconSx={{
            backgroundColor: "white",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
      </Box>
    </Section>
  );
}
