import {Typography, Box, useMediaQuery, useTheme, Button} from "@mui/material";

import Section from "../../../kit/section";
import EmailForm from "../../../kit/EmailForm";
import {setIsContactFormOpen} from "../../../stores/contactForm";

export default function Section8() {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isWrap = useMediaQuery(theme.breakpoints.down(999));
  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="white"
      sxsect={{
        padding: isMobile ? "50px 0 65px 0" : "100px 0",
      }}
      sxcont={{
        display: "flex",
        flexDirection: isWrap ? "column" : "row",
        gap: isTablet ? "33px" : "40px",
        padding: isTablet ? "0 40px" : "0 20px",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant={isMobile ? "tellOrderMobile" : "tellOrder"} component="h1" sx={{ maxWidth: "675px" }}>
          Оставьте заявку на консультацию
        </Typography>
        <Typography variant="t1" sx={{ maxWidth: "667px", mt: isMobile ? "20px" : "30px" }}>
          Напишите нам по поводу проекта, мы свяжемся с вами для детального обсуждения. Отвечаем на все заявки без
          исключения. Работаем вне рамок географии и часовых поясов
        </Typography>
      </Box>
        <Box
            zIndex={theme.zIndex.content}
            sx={{
                position: "relative",
                mt: isMobile ? "10px" : "22px",
                mr: isTablet ? "0" : "106px",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
            }}
        >
            <Button
                onClick={() => setIsContactFormOpen(true)}
                disableRipple
                variant={isMobile?"orderServiceMobile" : "orderService"}
                sx={{
                    padding: "9px 50px",
                    width: "254px",
                    whiteSpace: "nowrap",
                }}
            >
                Заполнить форму
            </Button>
        </Box>
    </Section>
  );
}
