import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import IconTextCard from "../../../kit/IconTextCard";
import Section from "../../../kit/section";

import CustomerIcon from "../../../Icons/CustomerIcon.svg";
import DifficultyIcon from "../../../Icons/DifficultyIcon.svg";
import EmptyIcon from "../../../Icons/EmptyIcon.svg";
import FastIcon from "../../../Icons/FastIcon.svg";
import QualityIcon from "../../../Icons/QualityIcon.svg";
import TasksIcon from "../../../Icons/TasksIcon.svg";

export default function Section2() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  const cardSx = {
    flex: "1 1 0",
    minWidth: isTablet ? "214px" : "223px",
    maxWidth: isMobile ? "320px" : "265px",
    p: 0,
    mr: isMobile ? "23px" : 0,
    height: "auto",
  };
  const headerSx = {
    mt: isMobile ? "20px" : "29px",
    ...(downMd && {
      fontSize: "20px",
      lineHeight: "24px",
    }),
  };

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#38394A"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
     // sxcont={{ padding: "0 40px" }}
    >
      <Typography variant={downMd ? "h4" : "h3"} sx={{ maxWidth: "660px", color: "white" }}>
        Что мы предлагаем
      </Typography>
      <Box
        sx={{
          mt: isMobile ? "50px" : "70px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          rowGap: isMobile ? "40px" : "50px",
          columnGap: isTablet ? "66px" : "134px"
        }}
      >
        <IconTextCard
          backgroundColor="#38394A"
          textColor="white"
          headerColor="white"
          headerText="Разработка CRM любой сложности"
          sxHeader={headerSx}
          icon={DifficultyIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "#262835",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#38394A"
          textColor="white"
          headerColor="white"
          headerText="Быстрое выполнение"
          sxHeader={headerSx}
          icon={FastIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "#262835",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#38394A"
          textColor="white"
          headerColor="white"
          headerText="Гарантия качества"
          sxHeader={headerSx}
          icon={QualityIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "#262835",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#38394A"
          textColor="white"
          headerColor="white"
          headerText="СRM под дизайн заказчика"
          sxHeader={headerSx}
          icon={CustomerIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "#262835",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#38394A"
          textColor="white"
          headerColor="white"
          headerText="Разработка дизайна с нуля"
          sxHeader={{
            mt: isMobile ? "20px" : "29px",
            maxWidth: isMobile ? "277px" : "220px",
            ...(downMd && {
              fontSize: "20px",
              lineHeight: "24px",
            }),
          }}
          icon={EmptyIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "#262835",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="#38394A"
          textColor="white"
          headerColor="white"
          headerText="Помогаем составить техническое задание"
          sxHeader={{
            mt: isMobile ? "20px" : "29px",
            maxWidth: "265px",
            ...(downMd && {
              fontSize: "20px",
              lineHeight: "24px",
            }),
          }}
          icon={TasksIcon}
          sx={cardSx}
          iconSx={{
            backgroundColor: "#262835",
            width: "59px",
            height: "59px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
      </Box>
    </Section>
  );
}
