import { Box, useTheme, Typography, useMediaQuery } from "@mui/material";

import IconTextCard from "../../../kit/IconTextCard";
import Section from "../../../kit/section";

import CaseIcon from "../../../Icons/CaseIcon.svg";
import ClientsIcon from "../../../Icons/ClientsIcon.svg";
import FireIcon from "../../../Icons/FireIcon.svg";

export default function Section2() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isWrap = useMediaQuery(theme.breakpoints.down(950));

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#333647"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
      sxcont={{
        position: "relative",
        zIndex: theme.zIndex.content,
      }}
    >
      <Typography variant="h3" sx={{ color: "white", mb: "40px", maxWidth: isMobile ? "300px" : undefined }}>
        Вам нужен лендинг, если вы хотите
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: isTablet ? (isWrap ? "wrap" : "nowrap") : "nowrap",
          justifyContent: isTablet ? "center" : "",
          gap: isTablet ? "20px 10px" : "40px",
        }}
      >
        <IconTextCard
          backgroundColor="white"
          icon={CaseIcon}
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Организовать продажи в интернете"
          headerHeight="auto"
          text="Продающий одностраничный сайт это отличный способ презентовать товар и проверить маркетинговые гипотезы"
          sx={{
            width: isTablet ? (isWrap ? "343px" : "300px") : "360px",
            height: "auto",
            minHeight: "187px"
          }}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="white"
          icon={ClientsIcon}
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Рассказать о себе"
          headerHeight="auto"
          text="Вам нужен сайт как дополнение к соц.сетям или как визитка"
          sx={{
            width: isTablet ? (isWrap ? "343px" : "300px") : "360px",
            height: "auto",
            minHeight: "187px"
          }}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          backgroundColor="white"
          icon={FireIcon}
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Новый бренд"
          headerHeight="auto"
          text="Хотите постепенно получить мощный бренд, но не знаете с чего начать, одностраничный сайт - это отличный выбор"
          sx={{
            width: isTablet ? (isWrap ? "343px" : "300px") : "360px",
            height: "auto",
            minHeight: "187px"
          }}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
      </Box>
    </Section>
  );
}
