import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import IconTextCard from "../../../kit/IconTextCard";
import Section from "../../../kit/section";

import EfficiencyIcon from "../../../Icons/EfficiencyIcon.svg";
import PositivityIcon from "../../../Icons/PositivityIcon.svg";
import SimplicityIcon from "../../../Icons/SimplicityIcon.svg";

const cardShadow = `
    0px 60px 150px rgba(210, 208, 225, 0.24), 
    0px 41.7776px 129.093px rgba(210, 208, 225, 0.172525), 
    0px 100px 309px rgba(210, 208, 225, 0.24), 
    0px 41.7776px 129.093px rgba(210, 208, 225, 0.172525), 0px 22.3363px 69.0192px rgba(210, 208, 225, 0.143066), 
    0px 12.5216px 38.6916px rgba(210, 208, 225, 0.12), 
    0px 6.6501px 20.5488px rgba(210, 208, 225, 0.0969343), 
    0px 2.76726px 8.55082px rgba(210, 208, 225, 0.0674749)
`;

export default function Section3() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  const cardSx = {
    minWidth: isMobile ? "270px" : "299px",
    maxWidth: "360px",
    height: "auto",
    flex: "1 1 0",
    pb: "41px",
    boxShadow: cardShadow,
  };

  return (
    <Section
      bg="#FFFFFF"
      mwidth="1200px"
      tag="section"
      sxsect={{
        padding: isMobile ? "70px 0 50px" : "100px 0",
      }}
    >
      <Typography variant={downMd ? "h4" : "h3"}>Ключевые преимущества наших дизайнов</Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          gap: isTablet ? (isMobile ? "25px" : "20px 10px") : "40px",
          mt: "40px",
        }}
      >
        <IconTextCard
          icon={SimplicityIcon}
          headerText="Простота"
          headerHeight="auto"
          text="Мы не делаем 100 стилей, чтобы понравиться каждой ЦА вашего продукта, мы делаем 1, который нравится всем."
          textColor="#4D4D4D"
          headerColor="black"
          backgroundColor="white"
          sx={cardSx}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          icon={EfficiencyIcon}
          headerText="Эффективность"
          headerHeight="auto"
          text="Хороший UX/UI снижает затраты на маркетинг в разы."
          textColor="#4D4D4D"
          headerColor="black"
          backgroundColor="white"
          sx={cardSx}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          icon={PositivityIcon}
          headerText="Позитив"
          headerHeight="auto"
          text="Вашим клиентам всегда будет приятно смотреть на ваши интерфейсы, что может являться залогом долгосрочного сотрудничества"
          textColor="#4D4D4D"
          headerColor="black"
          backgroundColor="white"
          sx={cardSx}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
      </Box>
    </Section>
  );
}
