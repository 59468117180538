import { Menu, Box, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import type { MenuItem } from "./header";

type DropDownMenuProps = {
  anchorElement: HTMLElement | null;
  setAnchorElement: (element: null) => void;
  items: MenuItem[];
};

export const DropDownMenu = ({
  anchorElement,
  setAnchorElement,
  items = [],
}: DropDownMenuProps) => {
  const location = useLocation();
  const theme = useTheme();

  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={() => setAnchorElement(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      sx={{
        "&.MuiMenu-root.MuiModal-root": { zIndex: "10" },
        "& .MuiPaper-root.MuiMenu-paper": {
          padding: "0 10px",
          borderRadius: "8px",
          background: "#252734",
          margin: "0",
          width: "600px"
        },
      }}
    >
      {items
        .filter(({ url }) => location.pathname !== url)
        .map(({ name, url }, index) => (
          <Box
            key={name}
            sx={{ "& a:hover": { background: "rgba(255, 255, 255, 0.1)" } }}
          >
            <Link
              style={{
                fontSize: "16px",
                lineHeight: "20px",
                display: "block",
                textDecoration: "none",
                color: theme.palette.common.white,
                padding: "20px 10px 0",
                borderRadius: "8px",
                transition: ".2s",
              }}
              target="_blank"
              to={url}
            >
              {name}
              <span
                  style={{
                    width: "100%",
                    display: "block",
                    paddingBottom: "20px",
                    borderBottom: index === items.length - 1 ? "none" : "1px solid rgba(255, 255, 255, 0.1)",
                  }}
              />
            </Link>
          </Box>
        ))}
    </Menu>
  );
};
