import { useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

import Slider from "./Slider";

import laptopPhoneImage1 from "../../../image/img_corporatesites/laptop-phone1.png";
import laptopPhoneImage2 from "../../../image/img_corporatesites/laptop-phone2.png";
import laptopPhoneImage3 from "../../../image/img_corporatesites/laptop-phone3.png";
import laptopPhoneImage4 from "../../../image/img_corporatesites/laptop-phone4.png";

export default function Section3() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Section
      tag="section"
      bg="#F2F3F7"
      mwidth="1200px"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
    >
      <Slider
        items={[
          {
            headerText: "Стилистика",
            text: "Нет понимания в каком стиле лучше себя презентовать? Наши специалисты готовы консультировать вас, пока не появится отличная концепция, которая выведет вас в топ отрасли или рынка.",
            image: laptopPhoneImage1,
          },
          {
            headerText: "Экспертность",
            text: "Всегда готовы рассказать и подсказать по всему спектру требований клиента.",
            image: laptopPhoneImage2,
          },
          {
            headerText: "Подход",
            text: "Особенный подход нашей комании к каждому проекту гарантирует высокие качество и скорость разработки.",
            image: laptopPhoneImage3,
          },
          {
            headerText: "Профессионализм",
            text: "Наша команда специалистов всегда онлайн и готова давать  обратную связь по каждому из этапов разработки.",
            image: laptopPhoneImage4,
          },
        ]}
      />
    </Section>
  );
}
