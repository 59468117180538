import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export default function Bubbles({ style }: Props) {
  return (
    <svg width="962" height="1047" viewBox="0 0 962 1047" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M122.094 455.633C97.4931 485.922 100.703 538.468 105.383 560.955C155.629 802.379 601.19 604.757 695.087 608.408C788.985 612.059 475.784 565.738 520.85 536.897C565.917 508.056 611.647 517.561 671.967 477.644C732.287 437.727 753.735 330.907 688.879 257.632C624.024 184.357 558.303 187.426 526.192 191.329C494.08 195.232 441.387 229.611 416.733 299.931C392.079 370.251 383.059 384.566 367.457 397.837C351.855 411.108 337.192 404.282 258.654 403.068C180.115 401.854 152.845 417.773 122.094 455.633Z"
        fill="url(#paint0_linear_202_187)"
      />
      <path
        d="M533.06 93.0361C643.836 109.097 745.869 161.295 783.039 185.386C1176.97 458.157 558.53 1049.43 295.381 948.195C32.2319 846.958 246.637 658.523 291.442 635.222C336.248 611.92 400.97 573.308 411.239 530.666C421.507 488.024 345.227 420.355 308.479 334.75C271.731 249.145 296.658 194.51 318.048 160.364C339.437 126.219 394.589 72.9594 533.06 93.0361Z"
        fill="url(#paint1_linear_202_187)"
      />
      <circle cx="275.95" cy="105.811" r="37.3216" fill="url(#paint2_linear_202_187)" fillOpacity="0.45" />
      <g filter="url(#filter0_d_202_187)">
        <ellipse cx="608" cy="83.5" rx="84" ry="83.5" fill="url(#paint3_linear_202_187)" />
      </g>
      <circle cx="94.2498" cy="466.25" r="94.2498" fill="url(#paint4_linear_202_187)" fillOpacity="0.35" />
      <circle
        cx="475.973"
        cy="460.973"
        r="224.758"
        transform="rotate(-72.8761 475.973 460.973)"
        fill="url(#paint5_linear_202_187)"
      />
      <circle cx="220.16" cy="312.779" r="18.6608" fill="url(#paint6_linear_202_187)" fillOpacity="0.7" />
      <path
        d="M641.47 562.486C633.927 594.563 598.332 647.001 581.477 669.211C567.194 689.078 453.161 876.341 325.539 740.112C197.916 603.883 326.487 548.408 378.492 548.408C431.5 548.408 448.5 554 484.536 506.39C509.807 473.001 544.232 451.941 588.924 462.232C633.616 472.523 650.899 522.389 641.47 562.486Z"
        fill="url(#paint7_linear_202_187)"
      />
      <circle cx="492.129" cy="384.129" r="37.1287" fill="url(#paint8_linear_202_187)" />
      <defs>
        <filter
          id="filter0_d_202_187"
          x="506"
          y="0"
          width="204"
          height="209"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="24" />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.439574 0 0 0 0 0.162483 0 0 0 0 0.795833 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_202_187" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_202_187" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_202_187"
          x1="398.68"
          y1="618.953"
          x2="13.4685"
          y2="634.678"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A1BAB" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_202_187"
          x1="293.159"
          y1="252.777"
          x2="658.693"
          y2="767.547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B47AFF" />
          <stop offset="0.559328" stopColor="#5C20A9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_202_187"
          x1="238.628"
          y1="68.4897"
          x2="301.577"
          y2="143.428"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0CDF9" stopOpacity="0.53" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_202_187"
          x1="706"
          y1="219.715"
          x2="513.499"
          y2="57.4872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" stopOpacity="0.84" />
          <stop offset="1" stopColor="#CBAAF4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_202_187"
          x1="-1.09878e-06"
          y1="443.401"
          x2="188.5"
          y2="477.674"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" stopOpacity="0.07" />
          <stop offset="1" stopColor="#B98DF1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_202_187"
          x1="656.473"
          y1="320.324"
          x2="475.973"
          y2="685.731"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9D9FF" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_202_187"
          x1="202"
          y1="284.5"
          x2="220.16"
          y2="331.44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0CDF9" stopOpacity="0.53" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_202_187"
          x1="654.493"
          y1="476.181"
          x2="313.671"
          y2="682.768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#DAC2F8" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_202_187"
          x1="491.937"
          y1="353.63"
          x2="529"
          y2="410"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C2DBC" stopOpacity="0.53" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
      </defs>
    </svg>
  );
}
