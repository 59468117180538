import { ReactNode } from "react";
import { Box, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

import AutomationIcon from "./AutomationIcon";
import DecisionIcon from "./DecisionIcon";
import EfficiencyIcon from "./EfficiencyIcon";
import IncomeIcon from "./IncomeIcon";
import LoyaltyIcon from "./LoyaltyIcon";
import UsersIcon from "./UsersIcon";

export default function Section2() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(690));
  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#7E2AEA"
      sxsect={{
        padding: isMobile ? "50px 0" : isTablet ? "82px 0 120px" : "100px 0",
      }}
    >
      <Typography variant="h3" sx={{ color: "white", mb: isMobile ? "40px" : "70px", maxWidth: isMobile ? "295px" : undefined }}>
        Зачем нужно мобильное приложение
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
          rowGap: isMobile ? "40px" : "42px",
          columnGap: isTablet ? "30px" : "123px",
        }}
      >
        <IconWithText  sxText={{maxWidth: isMobile ? "100%" : "180px"}} icon={<UsersIcon />} text="Привлечение новых клиентов" />
        <IconWithText icon={<AutomationIcon />} text="Автоматизация процессов" />
        <IconWithText icon={<IncomeIcon />} text="Увеличение прибыли" />
        <IconWithText icon={<EfficiencyIcon />} text="Рост эффективности" />
        <IconWithText icon={<DecisionIcon />} text="Ускорение принятия решений" />
        <IconWithText icon={<LoyaltyIcon />} text="Повышение лояльности" />
      </Box>
    </Section>
  );
}

function IconWithText({ icon, text, sx, sxText }: { icon: ReactNode; text: string; sx?: SxProps<Theme>, sxText?: SxProps<Theme> }) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "30px",
        maxWidth: isMobile ? "333px" : "277px",
        flexBasis: isMobile ? undefined : "291px",
        ...sx,
      }}
    >
      {icon}
      <Typography variant="h4" sx={{ color: "white", fontSize: "18px", lineHeight: "normal", ...sxText }}>
        {text}
      </Typography>
    </Box>
  );
}
