import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";

import Section from "../../../kit/section";

import BigNumberCard from "./BigNumberCard";

export default function Section6() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1200));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Section
      bg="#333647"
      mwidth="1200px"
      tag="section"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0 80px 0",
      }}
    >
      <Typography variant="h3" sx={{ maxWidth: isMobile ? "310px" : "460px", color: "white" }}>
        Этапы запуска мобильного приложения
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: isMobile ? "start" : "space-between",
          gap: "40px",
          mt: "50px",
        }}
      >
        <BigNumberCard
          indexText="1"
          headerText="Подготовка"
          text="Встреча с заказчиком (как правило, онлайн), заключение договора."
          sx={{ maxWidth: isTablet ? (isMobile ? "302px" : "199px") : "260px" }}
        />
        <BigNumberCard
          indexText="2"
          headerText="Техзадание"
          text="Написание технического задания, сбор требований."
          sx={{ maxWidth: isTablet ? (isMobile ? "302px" : "199px") : "260px" }}
        />
        <BigNumberCard
          indexText="3"
          headerText="Разработка"
          text="Дизайн приложения и разработка"
          sx={{ maxWidth: isTablet ? (isMobile ? "302px" : "199px") : "260px" }}
        />
        <BigNumberCard
          indexText="4"
          headerText="Запуск"
          text="Тестирование. Запуск приложения, post-production и сопровождение продукта."
          sx={{ maxWidth: isTablet ? (isMobile ? "302px" : "199px") : "260px" }}
        />
      </Box>
    </Section>
  );
}
