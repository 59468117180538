import { Typography, Box, useTheme, useMediaQuery, Button } from "@mui/material";

import Section from "../../../kit/section";

export default function Section7() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isWrap = useMediaQuery(theme.breakpoints.down(820));
  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#F2F3F7"
      sxsect={{
        padding: isMobile ? "50px 0 87px 0" : "100px 0",
      }}
    >
      <Typography variant="h3">Тарифные планы</Typography>
      <Box
        component="section"
        sx={{
          mt: isMobile ? "40px" : "50px",
          display: "flex",

          flexDirection: "column",
          gap: isTablet ? "25px" : "40px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#333647",
            boxShadow:
              "0px 12.5216px 38.6916px rgba(37, 39, 52, 0.12), 0px 6.6501px 20.5488px rgba(37, 39, 52, 0.0969343), 0px 2.76726px 8.55082px rgba(37, 39, 52, 0.0674749)",
            borderRadius: "12px",
            padding: isWrap ? "20px" : isTablet ? "20px 55px 30px 20px" : "20px 100px 30px 20px",
            display: "flex",
            justifyContent: isWrap ? "start" : "space-between",
            alignItems: isWrap ? "start" : "center",
            flexDirection: isWrap ? "column" : "row",
          }}
        >
          <Box width={isWrap ? "100%" : isTablet ? "340px" : "440px"}>
            <Typography
              sx={{
                fontSize: "24px",
                color: "#7E2AEA",
                fontWeight: 600,
                lineHeight: "28px",
              }}
            >
              01
            </Typography>
            <Typography
              sx={{
                fontSize: "24px",
                color: "white",
                fontWeight: 600,
                lineHeight: "28px",
              }}
            >
              Стандарт
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "left", width: "100%" }}>
              <ul
                style={{
                  color: "white",
                  fontSize: "18px",
                  lineHeight: "21px",
                  fontWeight: 400,
                  paddingLeft: isMobile ? "27px" : "20px",
                  margin: isMobile ? "31px 0px 43px 0px" : "16px 0 0 0",
                }}
              >
                <li>
                  <Typography variant="t1">Срок исполнения 10-30 дней.</Typography>
                </li>
                <li>
                  <Typography variant="t1">Над вашим заказом работает 1 верстальщик</Typography>
                </li>
                <li>
                  <Typography variant="t1">5 бесплатных правок</Typography>
                </li>
                <li>
                  <Typography variant="t1">Скидка на следующий заказ 5% </Typography>
                </li>
              </ul>
            </Box>
          </Box>

          {isWrap ? (
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <Button
                sx={{
                  width: "100%",
                  height: isMobile ? "84px" : "110px",
                  backgroundColor: "#7E2AEA",
                  borderRadius: "12px",
                  color: "white",
                    textTransform: "none",
                  fontWeight: 500,
                  fontSize: isWrap ? "24px" : "39px",
                  filter:
                    "drop-shadow(0px 100px 80px rgba(126, 42, 234, 0.07)) drop-shadow(0px 41.7776px 33.4221px rgba(126, 42, 234, 0.0503198)) drop-shadow(0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275)) drop-shadow(0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035)) drop-shadow(0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725)) drop-shadow(0px 2.76726px 2.21381px rgba(126, 42, 234, 0.0196802))",
                  "&:hover": {
                    backgroundColor: "#7E2AEA",
                    border: "solid white 1px",
                  },
                }}
              >
                от 100 000 руб.
              </Button>
            </Box>
          ) : (
            <Button
              sx={{
                width: isWrap ? "400px" : "450px",
                height: isMobile ? "84px" : "110px",
                backgroundColor: "#7E2AEA",
                borderRadius: "12px",
                  textTransform: "none",
                color: "white",
                fontWeight: 500,
                fontSize: isMobile ? "24px" : "39px",
                filter:
                  "drop-shadow(0px 100px 80px rgba(126, 42, 234, 0.07)) drop-shadow(0px 41.7776px 33.4221px rgba(126, 42, 234, 0.0503198)) drop-shadow(0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275)) drop-shadow(0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035)) drop-shadow(0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725)) drop-shadow(0px 2.76726px 2.21381px rgba(126, 42, 234, 0.0196802))",
                "&:hover": {
                  backgroundColor: "#7E2AEA",
                  border: "solid white 1px",
                },
              }}
            >
              от 100 000 руб.
            </Button>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mt: isMobile ? "27px" : isTablet ? "40px" : "50px",
          display: "flex",
          flexDirection: "column",
          gap: isTablet ? "25px" : "40px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#333647",
            boxShadow:
              "0px 12.5216px 38.6916px rgba(37, 39, 52, 0.12), 0px 6.6501px 20.5488px rgba(37, 39, 52, 0.0969343), 0px 2.76726px 8.55082px rgba(37, 39, 52, 0.0674749)",
            borderRadius: "12px",
            padding: isWrap ? "20px" : isTablet ? "20px 55px 30px 20px" : "20px 100px 30px 20px",
            display: "flex",
            justifyContent: isWrap ? "start" : "space-between",
            alignItems: isWrap ? "start" : "center",
            flexDirection: isWrap ? "column" : "row",
          }}
        >
          <Box width={isWrap ? "100%" : isTablet ? "340px" : "440px"}>
            <Typography
              sx={{
                fontSize: "24px",
                color: "#7E2AEA",
                fontWeight: 600,
                lineHeight: "28px",
              }}
            >
              02
            </Typography>
            <Typography
              sx={{
                fontSize: "24px",
                color: "white",
                fontWeight: 600,
                lineHeight: "28px",
              }}
            >
              Профи
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "left", width: "100%" }}>
              <ul
                style={{
                  color: "white",
                  fontSize: "18px",
                  lineHeight: "21px",
                  fontWeight: 400,
                  paddingLeft: isMobile ? "27px" : "20px",
                  margin: isMobile ? "31px 0px 43px 0px" : "16px 0 0 0",
                }}
              >
                <li>
                  <Typography variant="t1">Срок исполнения 5-15 дней</Typography>
                </li>
                <li>
                  <Typography variant="t1">Над вашим заказом работает 2-3 верстальщика</Typography>
                </li>
                <li>
                  <Typography variant="t1">15 бесплатных правок</Typography>
                </li>
                <li>
                  <Typography variant="t1">Скидка на следующий заказ 15%</Typography>
                </li>
              </ul>
            </Box>
          </Box>
          {isWrap ? (
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <Button
                sx={{
                  width: "100%",
                  height: isMobile ? "84px" : "110px",
                  backgroundColor: "#7E2AEA",
                  borderRadius: "12px",
                  color: "white",
                    textTransform: "none",
                  fontWeight: 500,
                  fontSize: isWrap ? "24px" : "39px",
                  filter:
                    "drop-shadow(0px 100px 80px rgba(126, 42, 234, 0.07)) drop-shadow(0px 41.7776px 33.4221px rgba(126, 42, 234, 0.0503198)) drop-shadow(0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275)) drop-shadow(0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035)) drop-shadow(0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725)) drop-shadow(0px 2.76726px 2.21381px rgba(126, 42, 234, 0.0196802))",
                  "&:hover": {
                    backgroundColor: "#7E2AEA",
                    border: "solid white 1px",
                  },
                }}
              >
                от 500 000 руб.
              </Button>
            </Box>
          ) : (
            <Button
              sx={{
                width: isMobile ? "303px" : "450px",
                height: isMobile ? "84px" : "110px",
                backgroundColor: "#7E2AEA",
                  textTransform: "none",
                borderRadius: "12px",
                color: "white",
                fontWeight: 500,
                fontSize: isMobile ? "24px" : "39px",
                filter:
                  "drop-shadow(0px 100px 80px rgba(126, 42, 234, 0.07)) drop-shadow(0px 41.7776px 33.4221px rgba(126, 42, 234, 0.0503198)) drop-shadow(0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275)) drop-shadow(0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035)) drop-shadow(0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725)) drop-shadow(0px 2.76726px 2.21381px rgba(126, 42, 234, 0.0196802))",
                "&:hover": {
                  backgroundColor: "#7E2AEA",
                  border: "solid white 1px",
                },
              }}
            >
              от 500 000 руб.
            </Button>
          )}
        </Box>
      </Box>
    </Section>
  );
}
