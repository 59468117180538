import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

export default function CloseIcon() {
  const location = useLocation();
  return (
    <Box
      sx={{
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        "&:hover path": {
          stroke: "#7E2AEA",
        },
      }}
    >
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L25 25M1 25L25 1" stroke={location.pathname === "/" ? "white" : "black"} />
      </svg>
    </Box>
  );
}
