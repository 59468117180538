import { useTheme } from "@mui/material/styles";
import { Typography, Box, Button, useMediaQuery } from "@mui/material";

import Section from "../../../kit/section";

import Bubbles from "./Bubbles";
import {setIsContactFormOpen} from "../../../stores/contactForm";
import { BubblesMobile } from "./BubblesMobile";

export default function Prices() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#333647"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
        overflow: "hidden",
      }}
      sxcont={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: theme.zIndex.content,
        }}
      >
        <Typography variant="h1" color="white" maxWidth="700px">
          Сколько стоят наши услуги
        </Typography>
        <Typography
            variant="t1"
            sx={{
                mt: "30px",
                color: "white",
                maxWidth: isTablet ? "660px" : "665px"
            }}
               >
          Точную стоимость мы назовем после оценки трудозатрат: оценим количество используемых технологических
          компонентов, состав и размер команды, сложность архитектуры проекта
        </Typography>
        <Button
        onClick={() => setIsContactFormOpen(true)}
          disableRipple
          variant={isMobile ? "connectIsMobile" : "connect"}
          sx={{
            mt: "40px",
            padding: "9px 42px",
          }}
        >
          Рассчитать стоимость
        </Button>
      </Box>
      {isMobile ? (
        <BubblesMobile
          style={{
            position: "absolute",
            bottom: "-50px",
            right: "0",
            pointerEvents: "none",
          }}
        />
      ) : (
        <Bubbles
          style={{
            position: "absolute",
            top: "-125px",
            right: isTablet ? "-562px" : "-460px",
            height: "1162px",
            pointerEvents: "none",
          }}
        />
      )}
    </Section>
  );
}
