import Box from "@mui/material/Box";
import SectionStyled from "../../kit/section";
import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EmailForm from "../../kit/EmailForm";

export default function Component() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isWrap = useMediaQuery(theme.breakpoints.down(890));

  return (
    <SectionStyled
      tag="section"
      bg={"#7E2AEA"}
      mwidth={"1200px"}
      sxsect={{
        height: isMobile ? "465px" : isTablet ? "473px" : "469px",
        // alignItems: "center",
      }}
      sxcont={{
        display: "flex",
        justifyContent: "space-between",
        minHeight: "290px",
        flexWrap: isWrap ? "wrap" : "nowrap",
        padding: isTablet ? "27px 40px 0 40px" : "27px 70px 0 20px",
      }}
    >
      <Box maxWidth="660px" zIndex={theme.zIndex.content}>
        <Typography
          variant="h2"
          sx={{
            color: "#ffffff",
            fontWeight: 500,
            fontSize: isMobile ? "32px" : "70px",
            lineHeight: "100%",
            maxWidth: isMobile ? "335px" : undefined
          }}
        >
          Ответим на все ваши вопросы
        </Typography>
        <Typography
          sx={{
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "21px",
            marginTop: isMobile ? "20px" : "30px",
          }}
        >
            Напишите нам по поводу проекта, мы свяжемся с вами для детального обсуждения. Отвечаем на все заявки без исключения. 24/7.
        </Typography>
      </Box>
      <EmailForm
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          width: isTablet ? "290px" : "363px",
          alignItems: isWrap ? "flex-start" : "center",
          paddingTop: isWrap ? "40px" : "20px",
          gap: isMobile ? "20px" : "27px",
        }}
        isWrap={isWrap}
        buttonSx={{ background: "#ffffff", color: "#000000", minWidth: "258px", padding: "10px 37px" }}
        buttonVariant={isMobile ? "mobileForm" : "form"}
      />
    </SectionStyled>
  );
}
