import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Footer from "../../kit/footer";
import MobileHeader from "../../kit/PhabletHeader";
import Navbar from "../../kit/header";

import Hero from "./Hero/Hero";
import Benefit from "./benefit";
import Stages from "./stages";
import Skills from "./Skills/Skills";
import Prices from "./Prices/Prices";
import TechStack from "./TechStack/TechStack";
import YourProduct from "./YourProduct/YourProduct";

export default function Devops() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  return (
    <>
      {isTablet ? <MobileHeader theme="light" /> : <Navbar theme="light" />}
      <Hero />
      <Benefit />
      <Stages />
      <Skills />
      <Prices />
      <TechStack />
      <YourProduct />
      <Footer />
    </>
  );
}
