import { Typography, Box, SxProps, Theme } from "@mui/material";

import SkeletonImage from "../../../kit/SkeletonImage";

interface Props {
  headerText: string;
  text?: string;
  textMaxWidth?: string;
  color: string;
  image: string;
  backgroundColor: string;
  backgroundPosition: string;

  sx?: SxProps<Theme>;
}
export default function ExampleCard({
  headerText,
  text,
  textMaxWidth = "640px",
  color,
  image,
  backgroundColor,
  backgroundPosition,
  sx,
}: Props) {
  return (
    <SkeletonImage
      sx={{ ...sx }}
      image={image}
      width="100%"
      height="400px"
      Children={
        <Box
          style={{
            backgroundImage: `url(${image})`,
          }}
          sx={{
            borderRadius: "8px",
            backgroundColor,
            backgroundPosition,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            height: "400px",
            width: "100%",
            p: "20px",

            filter: `
                    drop-shadow(0px 60px 150px rgba(210, 208, 225, 0.24)) 
                    drop-shadow(0px 41.7776px 129.093px rgba(210, 208, 225, 0.172525))
                `,
            ...sx,
          }}
        >
          <Typography variant="h4" color={color} maxWidth="440px">
            {headerText}
          </Typography>
          <Typography variant="t1" color={color} mt="14px" maxWidth={textMaxWidth}>
            {text}
          </Typography>
        </Box>
      }
    />
  );
}
