import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";

import Section from "../../../kit/section";

import TechnologyCard from "./TechnologyCard";

import AfterEffectsIcon from "../../../Icons/AfterEffectsIcon.svg";
import FigmaIcon from "../../../Icons/FigmaIcon.svg";
import IllustratorIcon from "../../../Icons/IllustratorIcon.svg";
import PhotoshopIcon from "../../../Icons/PhotoshopIcon.svg";
import PrincipleIcon from "../../../Icons/PrincipleIcon.svg";
import SketchIcon from "../../../Icons/SketchIcon.svg";

const cardSx = {
  flex: "1 1 0",
  minWidth: "280px",
};

export default function Section4() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Section
      tag="section"
      bg="#F2F3F7"
      mwidth="1200px"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
    >
      <Typography variant={downMd ? "h4" : "h3"} sx={{maxWidth: isMobile ? "260px" : undefined}}>Наши технологии для UX/UI дизайна</Typography>
      <Box
        sx={{
          mt: isMobile ? "40px" : "50px",
          display: "flex",
          flexWrap: "wrap",
          gap: isMobile ? "10px" : "30px 40px",
        }}
      >
        <TechnologyCard icon={FigmaIcon} text="Figma" sx={cardSx} />
        <TechnologyCard icon={SketchIcon} text="Sketch" sx={cardSx} />
        <TechnologyCard icon={PhotoshopIcon} text="Photoshop" sx={cardSx} />
        <TechnologyCard icon={AfterEffectsIcon} text="After Effects" sx={cardSx} />
        <TechnologyCard icon={IllustratorIcon} text="Illustrator" sx={cardSx} />
        <TechnologyCard icon={PrincipleIcon} text="Principle" sx={cardSx} />
      </Box>
    </Section>
  );
}
