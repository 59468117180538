import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import IconTextCard from "../../../kit/IconTextCard";
import NumberIcon from "../../../kit/NumberIcon";
import Section from "../../../kit/section";

export default function Section5() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmail = useMediaQuery(theme.breakpoints.down(340));

  const cardSx = {
    flex: "1 1 0",
    // minWidth: "280px",
    // maxWidth: "360px",

    minWidth: isTablet ? (isSmail ? "auto" : "296px") : "280px",
    maxWidth: isTablet ? (isMobile ? undefined : "300px") : "360px",

    mr: 0,
    height: "auto",
    boxShadow: `
        0px 100px 309px rgba(210, 208, 225, 0.24), 
        0px 41.7776px 129.093px rgba(210, 208, 225, 0.172525), 
        0px 22.3363px 69.0192px rgba(210, 208, 225, 0.143066), 
        0px 12.5216px 38.6916px rgba(210, 208, 225, 0.12), 
        0px 6.6501px 20.5488px rgba(210, 208, 225, 0.0969343), 
        0px 2.76726px 8.55082px rgba(210, 208, 225, 0.0674749) 
    `,
  };

  const headerSx = {
    mt: "14px",
    mb: isMobile ? "38px" : "10px",
    maxWidth: "300px",
  };

  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#FFFFFF"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
      sxcont={{ p: isTablet ? "0 40px" : "0 0 0 20px" }}
    >
      <Typography variant={downMd ? "h4" : "h3"} sx={{ maxWidth: "460px" }}>
        Этапы разработки CRM
      </Typography>

      <Box
        sx={{
          mt: isMobile ? "40px" : "50px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: isTablet ? "center" : "start",
          gap: isTablet ? (isMobile ? "25px 10px" : "40px 10px") : "40px",
        }}
      >
        <IconTextCard
          backgroundColor="#FFFFFF"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Сбор требований"
          sxHeader={headerSx}
          icon={<NumberIcon number="1" color="white" sx={{ backgroundColor: "#7E2AEA" }} />}
          sx={cardSx}
          skeletonDisabel={true}
          bottomText="2-3 дня"
        />
        <IconTextCard
          backgroundColor="#FFFFFF"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Создание прототипа и техзадания"
          sxHeader={{
            mt: "14px",
            mb: "10px",
            maxWidth: "271px",
          }}
          icon={<NumberIcon number="2" color="white" sx={{ backgroundColor: "#7E2AEA" }} />}
          sx={cardSx}
          skeletonDisabel={true}
          bottomText="1-3 недели"
        />
        <IconTextCard
          backgroundColor="#FFFFFF"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Отрисовка дизайна"
          sxHeader={headerSx}
          icon={<NumberIcon number="3" color="white" sx={{ backgroundColor: "#7E2AEA" }} />}
          sx={cardSx}
          skeletonDisabel={true}
          bottomText="1-2 недели"
        />
        <IconTextCard
          backgroundColor="#FFFFFF"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Разбивка на спринты"
          sxHeader={headerSx}
          icon={<NumberIcon number="4" color="white" sx={{ backgroundColor: "#7E2AEA" }} />}
          sx={cardSx}
          skeletonDisabel={true}
          bottomText="1-2 дня"
        />
        <IconTextCard
          backgroundColor="#FFFFFF"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Разработка"
          sxHeader={headerSx}
          icon={<NumberIcon number="5" color="white" sx={{ backgroundColor: "#7E2AEA" }} />}
          sx={cardSx}
          skeletonDisabel={true}
          bottomText="2-3 недели"
        />
        <IconTextCard
          backgroundColor="#FFFFFF"
          textColor="#4D4D4D"
          headerColor="black"
          headerText="Техническая поддержка и развитие"
          sxHeader={{
            mt: "14px",
            mb: "10px",
            maxWidth: "300px",
          }}
          icon={<NumberIcon number="6" color="white" sx={{ backgroundColor: "#7E2AEA" }} />}
          bottomText={" "}
          bottomTextSx={{ marginBottom: isMobile ? "20px" : 0, mt: isMobile ? "38px" : "20px" }}
          skeletonDisabel={true}
          sx={cardSx}
        />
      </Box>
    </Section>
  );
}
