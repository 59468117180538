import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

import { setIsContactFormOpen } from "../../stores/contactForm";
import SectionStyled from "../../kit/section";

import type { SectionRef } from "../types";

export default function Component({ sectionRef }: SectionRef) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isRubberSmall = useMediaQuery(theme.breakpoints.down(500));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div ref={sectionRef}>
      <SectionStyled
        tag={"section"}
        bg={"#252734"}
        mwidth={"1200px"}
        sxsect={{
          height: isRubberSmall ? "calc(100vh - 50px)" : "auto",
          alignItems: isRubberSmall ? "start" : "center",
        }}
        sxcont={{
          marginTop: isRubberSmall ? "46px" : "79px",
          marginBottom: isMobile ? "90px" : "106px",
          padding: isTablet ? "0 40px" : "0 20px",
        }}
      >
        <Box sx={{ maxWidth: "1111px" }}>
          <Typography
            sx={{
              color: "#A45EFF",
              fontWeight: 500,
              fontSize: isMobile ? "20px" : "24px",
              lineHeight: "20px",
              maxWidth: isRubberSmall ? "260px" : undefined
            }}
          >
            PENA предоставляет качественную разработку любой сложности
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              fontWeight: 500,
              marginTop: isRubberSmall ? "40px" : "30px",
              fontSize: isMobile ? "32px" : "54px",
              lineHeight: isMobile ? "32px" : "54px",
            }}
          >
              Нужен лендинг? Корпоративный сайт?
              А может не знаете кому поручить создание продукта мечты,
              который изменит мир? <br/>
              С нашей командой профессионалов слова “невозможно” больше нет!
          </Typography>
        </Box>
        <Button
          className="Connectwithus"
          onClick={() => setIsContactFormOpen(true)}
          disableRipple
          variant="connect"
          sx={{
            marginTop: "45px",
            padding: "15px 40px",
          }}
        >
          Связаться с нами
        </Button>
      </SectionStyled>
    </div>
  );
}