import { CSSProperties, useState } from "react";
import { Typography, Box, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";

import ArrowButtons from "../../../kit/ArrowButtons";
import SkeletonImage from "../../../kit/SkeletonImage";

const SLIDER_ITEMS_GAP = "168px";

interface SliderItem {
  image: string;
  headerText: string;
  text: string;
}

interface Props {
  items: SliderItem[];
  sx?: SxProps<Theme>;
}

export default function Slider({ items, sx }: Props) {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const calcRound = (step: number): number => {
    //Функция посчитает дошли ли мы до края. Если да - ведут в противоположный
    let calc = activeItemIndex + step;
    let imgCount = items.length - 1;
    if (calc > imgCount) {
      return 0;
    }
    if (calc < 0) {
      return imgCount;
    }
    return calc;
  };
  const leftScroll = () => {
    setActiveItemIndex(calcRound(-1));
  };
  const rightScroll = () => {
    setActiveItemIndex(calcRound(1));
  };

  return (
    <Box
      sx={{
        height: downMd ? "550px" : "500px",
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        overflow: "hidden",
        position: "relative",
        filter: `
                drop-shadow(0px 60px 150px rgba(210, 208, 225, 0.24)) 
                drop-shadow(0px 41.7776px 129.093px rgba(210, 208, 225, 0.172525))
            `,
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "20px",
          left: "20px",
          display: "flex",
          width: "100%",
          maxWidth: "300px",
          gap: "calc(20 / 300 * 100%)",
          zIndex: theme.zIndex.content,
        }}
      >
        {items.map((item, index) => (
          <Box
            key={item.headerText}
            onClick={() => setActiveItemIndex(index)}
            style={{
              backgroundColor: activeItemIndex === index ? "#7E2AEA" : "#F2F3F7",
              flex: activeItemIndex === index ? "1.8 0 0" : "1 0 0",
            }}
            sx={{
              height: "5px",
              p: 0,
              transitionDuration: "300ms",
              transitionTimingFunction: "ease-in-out",
              transitionProperty: "background-color, flex",
              cursor: "pointer",
              borderRadius: "9px",
            }}
          />
        ))}
      </Box>
      {items.map((sliderItem, index) => (
        <SkeletonImage
          key={sliderItem.headerText}
          width="100%"
          height="100%"
          image={sliderItem.image}
          Children={
            <SliderElement
              sliderItem={sliderItem}
              key={sliderItem.headerText}
              style={{
                overflow: "hidden",
                left: `calc((100% + ${SLIDER_ITEMS_GAP}) * ${index - activeItemIndex})`,
              }}
            />
          }
        />
      ))}
      <ArrowButtons
        leftScroll={leftScroll}
        rightScroll={rightScroll}
        sx={{
          width: isMobile ? "90%" : "140px",
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          mb: "10px",
          bottom: isMobile ? "248px" : "0",
          p: "20px",
        }}
      />
    </Box>
  );
}

function SliderElement({ sliderItem, style }: { sliderItem: SliderItem; style?: CSSProperties }) {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Box
      style={style}
      sx={{
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: downMd ? "column" : "row",
        transitionDuration: "800ms",
        transitionTimingFunction: "ease-in-out",
        transitionProperty: "left",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: isMobile ? "0" : "20px",
          pl: "20px",
          pr: "20px",
          pb: isMobile ? "0" : "20px",
          mr: "auto",
          order: downMd ? 2 : 1,
        }}
      >
        <Typography variant={downMd ? "h4" : "h3"} mt={downMd ? 0 : "45px"} maxWidth="500px">
          {sliderItem.headerText}
        </Typography>
        <Typography variant="t1" mt={"20px"} maxWidth="442px" maxHeight="280px">
          {sliderItem.text}
        </Typography>
      </Box>
      <Box
        sx={{
          display: isMobile ? "flex" : "block",
          justifyContent: isMobile ? "center" : "",
          order: downMd ? 1 : 2,
          mt: downMd ? "30px" : undefined,
          height: "auto",
          flexShrink: 1,
          width: isTablet ? "477px" : "677px",
        }}
      >
        <img
          src={sliderItem.image}
          alt=""
          style={{
            width: "1050px",
            maxHeight: downMd ? "300px" : undefined,
            height: "100%",
            objectFit: "scale-down",
            position: isMobile ? undefined : "absolute",
            right: isTablet ? "-454px" : "-247px",
          }}
        />
      </Box>
    </Box>
  );
}
