import { CSSProperties } from "react";


interface Props {
    style?: CSSProperties;
}

export default function Bubbles({ style }: Props) {

    return (
        <svg width="1052" height="1153" viewBox="0 90 1052 1153" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...style }}>
            <path d="M213.393 724.988C224.539 763.892 270.178 902.13 312.5 977.5C354.822 1052.87 517.892 1077.5 576.5 999.727C646 907.5 825.153 961.322 836 937C860.943 881.069 532.997 863.939 759.942 671.688C986.888 479.436 834.717 219.991 688.738 130.677C542.759 41.3636 504.434 263.392 377.458 255.046C305.387 250.309 239.15 297.255 299.517 414.168C359.883 531.081 308.131 561.402 260.576 596.872C213.021 632.342 199.46 676.357 213.393 724.988Z" fill="url(#paint0_linear_202_1965)" />
            <circle cx="335.033" cy="662.246" r="224.758" transform="rotate(-72.8761 335.033 662.246)" fill="url(#paint1_linear_202_1965)" />
            <path d="M322.394 706.208C307.45 737.467 310.431 768.333 322.394 793.311C345.082 840.681 412.063 871.271 463.74 840.681C510.07 821.4 541.5 850.734 586.5 856C631.5 861.266 650.773 839.5 655.5 826.5C660.227 813.5 698 759 522 668.5C505.067 659.793 477.574 644.436 425.311 641.617C373.049 638.798 341.074 667.134 322.394 706.208Z" fill="url(#paint2_linear_202_1965)" />
            <path d="M586.104 161.15C618.386 186.518 658.941 254.275 675.183 284.982C690.129 311.553 841.825 538.736 620.883 611.39C399.942 684.044 382.277 472.333 416.565 417.354C450.854 362.375 462.369 356.284 440.308 309.005C418.248 261.726 410.139 212.962 445.237 167.871C480.334 122.78 545.753 129.441 586.104 161.15Z" fill="url(#paint3_linear_202_1965)" />
            <circle cx="272.92" cy="555.996" r="47.8993" transform="rotate(-72.8761 272.92 555.996)" fill="url(#paint4_linear_202_1965)" />
            <circle cx="200.656" cy="382.39" r="34.2012" transform="rotate(-72.8761 200.656 382.39)" fill="url(#paint5_linear_202_1965)" />
            <circle cx="364.333" cy="193.575" r="16.5805" transform="rotate(-72.8761 364.333 193.575)" fill="#7E2AEA" />
            <circle cx="645.067" cy="939.067" r="53.6488" transform="rotate(-72.8761 645.067 939.067)" fill="url(#paint6_linear_202_1965)" />
            <circle cx="603.498" cy="528.498" r="131.587" transform="rotate(-72.8761 603.498 528.498)" fill="url(#paint7_linear_202_1965)" />
            <defs>
                <linearGradient id="paint0_linear_202_1965" x1="281.736" y1="296.863" x2="660.737" y2="612.189" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B379FD" />
                    <stop offset="0.695122" stopColor="#5613AC" />
                </linearGradient>
                <linearGradient id="paint1_linear_202_1965" x1="515.533" y1="521.597" x2="335.033" y2="887.004" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AE70FF" stopOpacity="0.61" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_202_1965" x1="340.5" y1="666.5" x2="621.5" y2="911" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BE99ED" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_202_1965" x1="483.58" y1="137.274" x2="462.952" y2="610.925" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C3A1ED" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear_202_1965" x1="272.92" y1="508.096" x2="272.92" y2="603.895" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
                <linearGradient id="paint5_linear_202_1965" x1="200.656" y1="348.188" x2="200.656" y2="416.591" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
                <linearGradient id="paint6_linear_202_1965" x1="645.067" y1="885.418" x2="645.067" y2="992.716" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#945CDB" />
                    <stop offset="1" stopColor="#945CDB" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint7_linear_202_1965" x1="709.174" y1="446.154" x2="603.498" y2="660.085" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B78EEC" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}