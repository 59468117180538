import {Typography, Box, SxProps, Theme, useTheme, useMediaQuery} from "@mui/material";


interface Props {
    indexText: string;
    headerText: string;
    text: string;
    sx?: SxProps<Theme>;
}

export default function BigNumberCard({ indexText, headerText, text, sx }: Props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            ...sx,
        }}>
            <Typography
                variant="h4"
                sx={{
                    color: "#7E2AEA",
                    fontWeight: 500,
                    fontSize: "50px",
                    lineHeight: "59px",
                }}
            >{indexText}</Typography>
            <Typography variant="h4" sx={{ mt: "16px", mb: "10px", color: "white", fontSize: isMobile ? "20px" : "24px"}}>{headerText}</Typography>
            <Typography variant="t1" sx={{ color: "white" }}>{text}</Typography>
        </Box>
    );
}