import { useState } from "react";
import { Typography, Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Bubbles from "./Bubbles";

import Section from "../../../kit/section";
import { setIsContactFormOpen } from "../../../stores/contactForm";

export default function Section1() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isRubberSmall = useMediaQuery(theme.breakpoints.down(500));


  return (
    <Section
      tag="section"
      bg="#F2F3F7"
      mwidth="1200px"
      sxsect={{
        height: isRubberSmall ? "calc(100vh - 50px)" : "auto",
        padding: downMd ? "0 0 30px 0" : isTablet ? "160px 0 164px 0" : "154px 0 210px 0",
      }}
      sxcont={{
        position: "relative",
        height: isMobile ? "100%" : "auto",
        display: isMobile ? "flex" : "block",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "-81px",
          right: "-276px",
          pointerEvents: "none",
          [theme.breakpoints.down("md")]: {
            position: "relative",
            top: "34px",
            right: "-243px",
            height: "390px",
          },
        }}
      >
        <Bubbles />
      </Box>
      <Box
        sx={{
          position: "relative",
          zIndex: theme.zIndex.content,
        }}
      >
        <Typography
          variant={isMobile ? "heroMobile" : "hero"}
          component="h1"
          maxWidth="660px"
        >
          Cloud-Native приложения
        </Typography>
        <Typography
          variant="t1"
          sx={{
            pt: isMobile ? "20px" : "30px",
            maxWidth: isMobile ? "none" : "310px",
            color: "black",
          }}
        >
            Самый безопасный в мире подход
            к разработке WEB-приложений.
        </Typography>
        <Box sx={{ position: "relative"}}>
          <Button
          onClick={() => setIsContactFormOpen(true)}
            disableRipple
            variant={isMobile ? "orderServiceMobile" : "orderService"}
            sx={{
              padding: "9px 50px",
              mt: isMobile ? "30px" : isTablet ? "18px" : "40px",
            }}
          >
            Заказать
          </Button>
        </Box>
      </Box>
    </Section>
  );
}
