import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import IconTextCard from "../../../kit/IconTextCard";
import Section from "../../../kit/section";

import BigCard from "./BigCard";

import RocketIcon from "../../../Icons/RocketMobile.svg";
import PositiveIcon from "../../../Icons/PositivityIcon.svg";
import EasyIntegrationIcon from "../../../Icons/EasyIntegrationIcon.svg";
import CheapIcon from "../../../Icons/CheapIcon.svg";
import lightningIcon from "../../../Icons/lightning.svg";
import PlatformsIcon from "../../../Icons/PlatformsIcon.svg";

export default function Section4() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1200));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Section
      bg="#333647"
      mwidth="1200px"
      tag="section"
      sxsect={{
        padding: isMobile ? "50px 0" : "100px 0",
      }}
      sxcont={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          color: "white",
          fontSize: isMobile ? "30px" : "36px",
          mb: isMobile ? "20px" : "50px",
          maxWidth: isMobile ? "300px" : "auto",
          lineHeight: "normal",
          fontWeight: 500,
        }}
      >
        Какие мобильные приложения мы создаем
      </Typography>
      <BigCard
        indexText="01"
        headerText="Нативные"
        text={`Лучшая производительность для high-load продуктов и проектов. `}
        sx={{ mb: isMobile ? "0" : isTablet? "169px" : "150px" }}
      >
        <IconTextCard
          icon={RocketIcon}
          headerText="Высокая производительность"
          textColor="white"
          headerColor="white"
          backgroundColor="#45465B"
          sx={{
            width: isTablet ? (isMobile ? "343px" : "410px") : "346px",
            height: isMobile ? "151px" : "146px",
            minHeight: isMobile ? "150px" : "auto",
          }}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <IconTextCard
          icon={PositiveIcon}
          headerText="Качественный пользовательский опыт"
          textColor="white"
          headerColor="white"
          backgroundColor="#45465B"
          sx={{
            width: isTablet ? (isMobile ? "343px" : "410px") : "346px",
            height: isMobile ? "151px" : "146px",
            minHeight: isMobile ? "150px" : "auto",
          }}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <Box sx={{ width: isTablet ? "100%" : "auto", display: "flex", justifyContent: isTablet ? "center" : "" }}>
          <IconTextCard
            icon={EasyIntegrationIcon}
            headerText="Простота интеграции"
            textColor="white"
            headerColor="white"
            backgroundColor="#45465B"
            sx={{
              width: isTablet ? (isMobile ? "343px" : "410px") : "346px",
              height: isMobile ? "151px" : "146px",
              minHeight: isMobile ? "150px" : "auto",
              mb: isMobile ? "99px" : "0",
            }}
            iconSx={{
              backgroundColor: "#7E2AEA",
              width: "36px",
              height: "36px",
              borderRadius: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexShrink: 0,
            }}
          />
        </Box>
      </BigCard>
      <BigCard
        indexText="02"
        headerText="Кроссплатформенные"
        sxText={{marginBottom: isMobile ? 0 : "75px"}}
        text={`Хочется иметь свой сервис на всех платформах, но не хочется переплачивать за один и тот же функционал? Горят сроки? Кроссплатформенная разработка — это то, что вам нужно.`}
      >
        <IconTextCard
          icon={CheapIcon}
          headerText="Низкая стоимость разработки"
          textColor="white"
          headerColor="white"
          backgroundColor="#45465B"
          sx={{
            width: isTablet ? (isMobile ? "343px" : "410px") : "346px",
            height: isMobile ? "151px" : "146px",
            minHeight: isMobile ? "150px" : "auto",
          }}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />

        <IconTextCard
          icon={lightningIcon}
          headerText="Быстрая разработка"
          textColor="white"
          headerColor="white"
          backgroundColor="#45465B"
          sx={{
            width: isTablet ? (isMobile ? "343px" : "410px") : "346px",
            height: isMobile ? "151px" : "146px",
            minHeight: isMobile ? "150px" : "auto",
          }}
          iconSx={{
            backgroundColor: "#7E2AEA",
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        />
        <Box sx={{ width: isTablet ? "100%" : "auto", display: "flex", justifyContent: isTablet ? "center" : "" }}>
          <IconTextCard
            icon={PlatformsIcon}
            headerText="Реализация на всех платформах"
            textColor="white"
            headerColor="white"
            backgroundColor="#45465B"
            sx={{
              width: isTablet ? (isMobile ? "343px" : "410px") : "346px",
              height: isMobile ? "151px" : "146px",
              minHeight: isMobile ? "150px" : "auto",
            }}
            iconSx={{
              backgroundColor: "#7E2AEA",
              width: "36px",
              height: "36px",
              borderRadius: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexShrink: 0,
            }}
          />
        </Box>
      </BigCard>
    </Section>
  );
}
