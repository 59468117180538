import Section from "../../../kit/section";
import {useMediaQuery, useTheme} from "@mui/material";
import Slider from "./Slider";
import Bubbles from "./Bubbles";

import sliderImage1 from "../../../image/img_crmsystems/slider1.png";
import sliderImage2 from "../../../image/img_crmsystems/slider2.png";
import sliderImage3 from "../../../image/img_crmsystems/slider3.png";
import sliderImage4 from "../../../image/img_crmsystems/slider4.png";
import sliderImage5 from "../../../image/img_crmsystems/slider5.png";
import sliderImage6 from "../../../image/img_crmsystems/slider6.png";
import sliderImage7 from "../../../image/img_crmsystems/slider7.png";

export default function Section3() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Section
      tag="section"
      bg="#7E2AEA"
      mwidth="1200px"
      sxsect={{
        p: isMobile? "100px 0 70px 0" : "100px 0",
        overflow: "hidden",
      }}
      sxcont={{
        position: "relative",
      }}
    >
      <Slider
        images={[sliderImage1, sliderImage2, sliderImage3, sliderImage4, sliderImage5, sliderImage6, sliderImage7]}
      />
      <Bubbles
        style={{
          position: "absolute",
          top: "-71px",
          right: "-800px",
          pointerEvents: "none",
        }}
      />
    </Section>
  );
}
