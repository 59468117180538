import { ListItemText, Typography, useMediaQuery, useTheme, Box } from "@mui/material";

import SectionStyled from "../../kit/section";

import BgImage1 from "../../image/img_devops/Card_1.png";
import BgImage2 from "../../image/img_devops/BgImage2.png";
import BgImage3 from "../../image/img_devops/BgImage3.png";

const CardArray: { numb: string; title: string; text1: string; text2: string; text3: string; image: any }[] = [
  {
    numb: "1",
    title: "Подготовка",
    text1: "Проводим глубокую оценку проблем, оцениваем цели",
    text2: "Определяем правильную стратегию, сроки и объем работ",
    text3: "Оцениваем риски",
    image: BgImage1,
  },
  {
    numb: "2",
    title: "Внедрение",
    text1: "Создаем среду для управления версиями кода",
    text2: "Внедряем автоматизацию",
    text3: "Разрабатываем сценарии и настраиваем инфраструктуру проекта",
    image: BgImage2,
  },
  {
    numb: "3",
    title: "Проверка",
    text1: "Проводим проверку качества продукта",
    text2: "Настраиваем процесс непрерывного тестирования",
    text3: "Внедряем обновления и осуществляем релиз продукта",
    image: BgImage3,
  },
];

interface Props {
  numb: string;
  title: string;
  text1: string;
  text2: string;
  text3: string;
  image: any;
  mtUl: string;
}

function BigCard(props: Props) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <>
      <Box
        sx={{
          padding: isTablet ? "20px 18px 20px 20px" : "20px",
          minWidth: "299px",
          maxWidth: isTablet ? (isMobile ? "360px" : "300px") : "360px",
          flex: "1 1 0",
          backgroundImage: `url(${props.image})`,
          backgroundColor: "#333647",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "8px",
          color: "#ffffff",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px"
            }}
        >
          <Typography
            sx={{
              color: "#7E2AEA",
              fontWeight: 500,
              fontSize: "70px",
              lineHeight: "normal"
            }}
          >
            {props.numb}
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontWeight: 500,
              fontSize: "36px",
              lineHeight: "42.66px",
            }}
          >
            {props.title}
          </Typography>
        </Box>
        <Box
          component="ul"
          sx={{
            m: 0,
            mt: props.mtUl,
            //mt: isTablet ? (isMobile ? "46px" : "54px") : "77px",
            mb: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            color: "white",
            pl: "28px",
            "& li:not(:last-child)": {
              marginBottom: "10px",
            },
          }}
        >
          <li
            style={{
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "21.33px",
            }}
          >
            {props.text1}
          </li>
          <li
              style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "21.33px",
              }}
          >
            {props.text2}
          </li>
          <li
              style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "21.33px",
              }}
          >
            {props.text3}
          </li>
        </Box>
      </Box>
    </>
  );
}

export default function Stages() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <SectionStyled
      tag={"section"}
      bg={"#ffffff"}
      mwidth={"1200px"}
      sxsect={{
        p: isMobile ? "50px 0" : "100px 0",
      }}
    >
      <Box>
        <Typography variant={downMd ? "h4" : "h3"}>Этапы работы</Typography>
      </Box>
      <Box
        sx={{
          mt: "40px",
          display: "flex",
          gap: isTablet ? "20px 10px" : "40px",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {CardArray.map(({ numb, title, text1, text2, text3, image }, index) => {
            let mtUl = isTablet ? (isMobile ? "46px" : "54px") : "77px"
            if (title == "Проверка") {mtUl = isTablet ? (isMobile ? "46px" : "32px") : "77px"}
            return(
                <BigCard key={title} numb={numb} title={title} text1={text1} text2={text2} text3={text3} image={image} mtUl={mtUl}/>
            )
        })}
      </Box>
    </SectionStyled>
  );
}
