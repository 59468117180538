import {useEffect, useRef, useState, } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Section from "../../../kit/section";

import phoneImage from "../../../image/img_mobile/phone1.png";
import phoneMobil from "../../../image/img_mobile/img_phone_bubbles_1.png";
import { setIsContactFormOpen } from "../../../stores/contactForm";


export default function Section1() {
  const theme = useTheme();

  const elementRef = useRef<HTMLElement>(null)
  const [paddingHero, setPaddingHero] = useState("");
  const [imgHero, setImgHero] = useState("");

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMinTablet = useMediaQuery(theme.breakpoints.down(886));
  const isRubberSmall = useMediaQuery(theme.breakpoints.down(500));

    useEffect(() => {
      const element = elementRef?.current;
      console.log(elementRef.current)
      if (!element) return;

      const observer = new ResizeObserver((e, u) => {
        console.log(e)
        console.log(u)
        const heightElement = e[0].contentRect.height

        setPaddingHero(heightElement > 650 ? "0 0 32px 0" : "0 0 10px 0");
        setImgHero(heightElement > 650 ? "400px" : "360px");
        console.log(heightElement)
      });

      observer.observe(element);
      return () => {
        observer.disconnect();
      };
    }, [])

    return (
      <Box ref={elementRef}>
    <Section

      tag="section"
      bg="#F2F3F7"
      mwidth="1200px"
      sxsect={{
        height: isRubberSmall ? "calc(100vh - 50px)" : "auto",
        padding: isMinTablet ? (isRubberSmall ? paddingHero : "0 0 32px 0") : "120px 0 110px 0",
        alignItems: isMinTablet ? "end" : "center"
      }}
      sxcont={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: isMinTablet ? "center": "unset"
      }}
    >
      {isMinTablet ? (
        <Box
          component="section"
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Box
            component="img"
            src={phoneMobil}
            sx={{ display: "block", width: imgHero, mt: "-15px", mb: "-62px" }}
          />
        </Box>
      ) : (
        <img
          src={phoneImage}
          alt="phoneImage"
          onDragStart={(e) => e.preventDefault()}
          style={{
            position: "absolute",
            width: isTablet ? "761px" : "955px",
            top: isTablet ? "-162px" : "-202px",
            right: isTablet ? "-72px" : "-120px",
            userSelect: "none",
            pointerEvents: "none",
          }}
        />
      )}
      <Box
        sx={{
          maxWidth: "550px",
          position: "relative",
          zIndex: theme.zIndex.content,
        }}
      >
        <Typography variant={isMobile ? "heroMobile" : "hero"} component="h1">
          Разрабатываем мобильные приложения
        </Typography>
        <Typography
          variant="t1"
          sx={{
            pt: "30px",
            maxWidth: "460px",
            color: "black",
          }}
        >
          Frontend, backend, fullstack, DevOps, UI/UX, разработка дерева метрик. Вы можете положиться на нас в любом вопросе.
        </Typography>
        <Box
            sx={{
                position: "relative"
            }}>
          <Button
        onClick={() => setIsContactFormOpen(true)}
            disableRipple
            variant={isMobile ? "orderServiceMobile" : "orderService"}
            sx={{
              padding: "9px 50px",
              mt: "40px",
            }}
          >
            Заказать
          </Button>
        </Box>
      </Box>
    </Section>
    </Box>
  );
}
