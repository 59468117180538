import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import Home from "./pages/home/home";
import Devops from "./pages/devops/devops";
import MobileApps from "./pages/MobileApps/MobileApps";
import Landings from "./pages/Landings/Landings";
import CloudNative from "./pages/CloudNative/CloudNative";
import UxUiDesign from "./pages/UxUiDesign/UxUiDesign";
import CorporateSites from "./pages/CorporateSites/CorporateSites";
import CrmSystems from "./pages/CrmSystems/CrmSystems";

import theme from "./kit/theme";
import ScrollToTop from "./kit/ScrollToTop";
import { ContactFormModal } from "./kit/ContactForm";
import { SnackbarProvider } from "notistack";

const A = () => {
  return <iframe style={{width:"99vw", height:"99vh"}} src="https://hbpn.link/9fafc6c9-6ccf-42b6-b400-0ee9f5f0fa16"></iframe>
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Box overflow="hidden">
        <BrowserRouter>
          <ContactFormModal />
          <SnackbarProvider />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/a" element={<A />} />
            <Route path="/devops" element={<Devops />} />
            <Route path="/mobileapps" element={<MobileApps />} />
            <Route path="/landings" element={<Landings />} />
            <Route path="/cloudnative" element={<CloudNative />} />
            <Route path="/design" element={<UxUiDesign />} />
            <Route path="/corporatesites" element={<CorporateSites />} />
            <Route path="/crmsystems" element={<CrmSystems />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  </React.StrictMode>
);
