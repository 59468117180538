import { Box, SxProps, Theme, Typography } from "@mui/material";

export default function NumberIcon({ number, color, sx }: { number: string; color: string; sx?: SxProps<Theme> }) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        borderRadius: "6px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        ...sx,
      }}
    >
      <Typography variant="h4" sx={{ color }}>
        {number}
      </Typography>
    </Box>
  );
}
