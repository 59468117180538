import {Box, useMediaQuery, useTheme} from "@mui/material";
import Bubbles from "./Bubbles";

export default function Section9() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    return (
        <Box
            component="section"
            sx={{
                width: "100%",
                height: isTablet ? (isMobile ? "70px" : "208px") : "219px",
                backgroundColor: "#F2F3F7",
                position: "relative",
            }}
        >
            <Bubbles style={{
                position: "absolute",
                top: isTablet? (isMobile ? "-60px" : "-117px") : "-103px",
                left: isTablet? (isMobile ? "calc(50% - (1918px / 2) + 25px)" : "calc(50% - (1918px / 2) + 230px)") : "calc(50% - (1918px / 2) + 30px)",
                height: isMobile ? "340px" : undefined
            }} />
        </Box>
    )
}