import { Typography, Box, SxProps, Theme, useTheme, useMediaQuery } from "@mui/material";

interface Props {
  image: string;
  indexText: string;
  headerText: string;
  text: string;
  sx?: SxProps<Theme>;
}

export default function NumberLinkCard({ image, indexText, headerText, text, sx }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        backgroundColor: "#7E2AEA",
        borderRadius: "8px",
        boxShadow: `
                0px 100px 80px rgba(126, 42, 234, 0.07),
                0px 41.7776px 33.4221px rgba(126, 42, 234, 0.0503198), 
                0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275), 
                0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035),
                0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725), 
                0px 2.76726px 2.21381px rgba(126, 42, 234, 0.0196802)
            `,
        ...sx,
      }}
    >
      <NumberIcon number={indexText} />
      <img
        src={image}
        alt=""
        onDragStart={(e) => e.preventDefault()}
        style={{
          userSelect: "none",
          display: "block",
          width: "100%",
          height: isMobile ? "221px" : "",
            marginTop: isMobile ? "-33px" : "-47px"
        }}
      />

      <Typography variant="h4" sx={{ ml: "20px", mr: "20px", color: "white", mt: isMobile ? "-18px" : "-27px" }}>
        {headerText}
      </Typography>
      <Typography variant="t1" sx={{ m: "20px", color: "white" }}>
        {text}
      </Typography>
    </Box>
  );
}

function NumberIcon({ number }: { number: string }) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        borderRadius: "6px",
        mt: "20px",
        ml: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <Typography variant="h4" sx={{ color: "#7E2AEA" }}>
        {number}
      </Typography>
    </Box>
  );
}
