import { useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import ArrowButtons from "../../../kit/ArrowButtons";
import SkeletonImage from "../../../kit/SkeletonImage";

const SLIDER_ITEMS_GAP = "10px";

interface Props {
  images: string[];
}

export default function Slider({ images }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));

  const [activeItemIndex, setActiveItemIndex] = useState<number>(Math.floor(images.length / 2));
  const calcRound = (step: number): number => {
    //Функция посчитает дошли ли мы до края. Если да - ведут в противоположный
    let calc = activeItemIndex + step;
    let imgCount = images.length - 1;
    if (calc > imgCount) {
      return 0;
    }
    if (calc < 0) {
      return imgCount;
    }
    return calc;
  };
  const leftScroll = () => {
    setActiveItemIndex(calcRound(-1));
  };
  const rightScroll = () => {
    setActiveItemIndex(calcRound(1));
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        zIndex: theme.zIndex.content,
      }}
    >
      <ArrowButtons
        leftScroll={leftScroll}
        rightScroll={rightScroll}
        sx={{
          width: isMobile || isTablet ? "100%" : "660px",
          minWidth: isMobile || isTablet ? "" : "660px",

          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          top: isMobile ? "49px" : "32%",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          aspectRatio: "760 / 400",
          maxWidth: isMobile ? undefined : "600px",
          width: isMobile ? "475px" : "100%",
          position: "relative",
        }}
      >
        {images.map((image, index) => (
          <SkeletonImage
            sx={{
              width: "100%",
              height: "100%",
              ml: isMobile ? "85px" : "0",
              borderRadius: "8px",
              position: "absolute",
              right: "10px",
              top: isMobile ? "-33px" : 0,
              transitionDuration: "800ms",
              transitionTimingFunction: "ease-in-out",
              transitionProperty: "left",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            key={index}
            image={image}
            width="100%"
            height="100%"
            Children={
              <Box
                key={index}
                style={{
                  left: `calc((100% + ${SLIDER_ITEMS_GAP}) * ${index - activeItemIndex})`,
                  backgroundImage: `url(${image})`,
                }}
                sx={{
                  width: "100%",
                  height: "100%",
                  ml: isMobile ? "85px" : "0",
                  borderRadius: "8px",
                  position: "absolute",
                  right: "10px",
                  top: isMobile ? "-33px" : 0,
                  transitionDuration: "800ms",
                  transitionTimingFunction: "ease-in-out",
                  transitionProperty: "left",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  filter: `
                                drop-shadow(0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275)) 
                                drop-shadow(0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035)) 
                                drop-shadow(0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725))
                            `,
                }}
              />
            }
          />
        ))}
      </Box>

      <Box
        sx={{
          mt: isMobile ? "-17px" : "30px",
          display: "flex",
          width: "100%",
          maxWidth: "510px",
          gap: "calc(20 / 510 * 100%)",
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            onClick={() => setActiveItemIndex(index)}
            style={{
              backgroundColor: activeItemIndex === index ? "#FFFFFF" : "#FFFFFF33",
              flex: activeItemIndex === index ? "1.8 0 0" : "1 0 0",
            }}
            sx={{
              height: "5px",
              p: 0,
              transitionDuration: "300ms",
              transitionTimingFunction: "ease-in-out",
              transitionProperty: "background-color, flex",
              cursor: "pointer",
              borderRadius: "9px",
            }}
          />
        ))}
      </Box>
    </Box>
  );
}
