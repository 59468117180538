import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export default function Bubbles({ style }: Props) {
  return (
    <svg
      width="1321"
      height="1364"
      viewBox="0 0 1321 1364"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <path
        d="M343.063 872.53C401.682 653.759 240.968 895.022 240.105 844.943C239.026 782.343 269.337 734.068 337.549 706.898C405.762 679.728 477.342 660.058 442.627 500.955C407.911 341.852 502.133 306.947 586.773 335.696C735.897 386.347 852.834 133.195 998.738 286.728C1144.64 440.26 1243.45 799.163 910.561 956.298C834.668 992.122 798.179 1014.09 786.505 1027.35C812.728 1031.81 838.172 1038.61 861.056 1045.28C915.414 1042.79 974.622 1038.79 955.152 1062.81C946.775 1073.14 909.702 1059.47 861.056 1045.28C812.524 1047.51 767.859 1048.53 786.505 1027.35C730.35 1017.79 670.625 1018.9 624.835 1054.67C529.859 1128.87 290.371 1069.19 343.063 872.53Z"
        fill="url(#paint0_linear_202_881)"
      />
      <circle
        cx="405.62"
        cy="808.9"
        r="278.17"
        transform="rotate(-57.8761 405.62 808.9)"
        fill="url(#paint1_linear_202_881)"
      />
      <path
        d="M376.427 857.408C348.549 889.991 342.225 927.845 348.526 961.536C360.475 1025.43 430.75 1083.46 502.327 1063.44C563.889 1055.23 592.066 1100.37 644.176 1121.08C696.285 1141.79 726.297 1121.94 736.113 1107.92C745.928 1093.89 808.542 1040.84 627.129 876.268C609.675 860.435 581.727 833.27 520.152 813.159C458.577 793.048 411.275 816.68 376.427 857.408Z"
        fill="url(#paint2_linear_202_881)"
      />
      <path
        d="M866.282 290.281C896.747 330.948 923.525 424.94 933.106 466.852C942.463 503.405 1051.04 823.587 763.636 839.669C476.235 855.752 522.933 596.999 581.535 542.257C640.137 487.515 655.854 483.922 644.626 420.335C633.398 356.747 639.325 295.854 695.726 253.192C752.128 210.529 828.2 239.448 866.282 290.281Z"
        fill="url(#paint3_linear_202_881)"
      />
      <circle
        cx="334.62"
        cy="431.298"
        r="42.3288"
        transform="rotate(-57.8761 334.62 431.298)"
        fill="url(#paint4_linear_202_881)"
      />
      <circle
        cx="590.775"
        cy="258.004"
        r="20.5208"
        transform="rotate(-57.8761 590.775 258.004)"
        fill="url(#paint3_linear_202_881)"
      />
      <circle
        cx="613.722"
        cy="1152.04"
        r="86.7243"
        transform="rotate(-57.8761 613.722 1152.04)"
        fill="url(#paint5_linear_202_881)"
      />
      <circle
        cx="567.602"
        cy="57.6014"
        r="33.8022"
        transform="rotate(-57.8761 567.602 57.6014)"
        fill="url(#paint6_linear_202_881)"
        fillOpacity="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_202_881"
          x1="458.976"
          y1="355.024"
          x2="811.053"
          y2="853.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B379FD" />
          <stop offset="0.695122" stopColor="#5613AC" />
          <stop offset="0.994792" stopColor="#5613AC" stopOpacity="0.21" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_202_881"
          x1="629.015"
          y1="634.828"
          x2="405.62"
          y2="1087.07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AE70FF" stopOpacity="0.61" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_202_881"
          x1="410.792"
          y1="815.738"
          x2="668.399"
          y2="1198.04"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BE99ED" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_202_881"
          x1="751.365"
          y1="228.896"
          x2="574.983"
          y2="788.525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3A1ED" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_202_881"
          x1="334.62"
          y1="388.969"
          x2="334.62"
          y2="473.627"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_202_881"
          x1="613.722"
          y1="1065.32"
          x2="613.722"
          y2="1238.77"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#945CDB" />
          <stop offset="1" stopColor="#945CDB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_202_881"
          x1="567.601"
          y1="23.7992"
          x2="567.601"
          y2="91.4037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
      </defs>
    </svg>
  );
}
