import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

import ExampleCard from "./ExampleCard";
import Slider, { SliderItem } from "./Slider";

import exampleCardImage1 from "../../../image/img_design/card4.png";
import exampleCardImage2 from "../../../image/img_design/card5.png";
import exampleCardImage3 from "../../../image/img_design/card6.png";
import exampleCardImage4 from "../../../image/img_design/card7.png";
import exampleCardImage5 from "../../../image/img_design/card8.png";
import exampleCardImage6 from "../../../image/img_design/card9.png";

export default function Section6() {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const downMd = useMediaQuery(theme.breakpoints.down(600));
  const isRight = useMediaQuery(theme.breakpoints.down(896));

  const cardData: SliderItem[] = [
    {
      headerText: "Корпоративный сайт “Тугантэл”",
      image: exampleCardImage1,
      color: "white",
      backgroundColor: "#7E2AEA",
      backgroundPosition: "bottom",
      sx: {
        backgroundSize: "853px",
        backgroundPosition: "bottom left 67%",
      },
    },
    {
      headerText: "Мобильный криптокошелек “Satellite”",
      image: exampleCardImage2,
      color: "white",
      backgroundColor: "#333647",
      backgroundPosition: "center bottom",
      sx: {
        backgroundSize: "contain",
        backgroundPosition: isRight ? (downMd ? "bottom" : "right") : "bottom",
      },
    },
    {
      headerText: "CRM базы отдыха",
      image: exampleCardImage3,
      color: "black",
      backgroundColor: "#FFFFFF",
      backgroundPosition: "center bottom",
      sx: {
        backgroundSize: "697px",
        backgroundPosition: "bottom",
      },
    },
    {
      headerText: "Корпоративный сайт “Клио”",
      image: exampleCardImage4,
      color: "white",
      backgroundColor: "#7E2AEA",
      backgroundPosition: "center bottom",
      sx: {
        backgroundSize: "697px",
        backgroundPosition: "bottom",
      },
    },
    {
      headerText: "Лендинг грантового конкурса FESCO",
      image: exampleCardImage5,
      color: "white",
      backgroundColor: "#363848",
      backgroundPosition: "center bottom",
      sx: {
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      },
    },

    {
      headerText: "Интернет магазин ассоциации рыбаков Приморского края",
      image: exampleCardImage6,
      color: "black",
      backgroundColor: "#FFFFFF",
      backgroundPosition: "center bottom",
      sx: {
        backgroundSize: "767px",
        backgroundPosition: "bottom right 30%",
      },
    },
  ];

  return (
    <Section
      tag="section"
      bg="#F2F3F7"
      mwidth="1200px"
      sxsect={{
        padding: downMd ? "50px 0" : "100px 0",
      }}
    >
      <Typography variant={downLg ? "h4" : "h3"}>Примеры работ</Typography>
      {downMd ? (
        <Slider
          items={cardData}
          sx={{
            mt: "45px",
          }}
        />
      ) : (
        <Box
          sx={{
            mt: downMd ? "40px" : "50px",
            display: "grid",
            gap: "40px",
            gridTemplateColumns: downLg ? "auto" : "1fr 1fr 1fr",
          }}
        >
          <ExampleCard
            {...cardData[0]}
            sx={{
              gridColumn: downLg ? undefined : "span 3",
            }}
          />
          <ExampleCard {...cardData[1]} />
          <ExampleCard
            {...cardData[2]}
            sx={{
              gridColumn: downLg ? undefined : "span 2",
            }}
            textMaxWidth={"480px"}
          />
          <ExampleCard
            {...cardData[3]}
            sx={{
              gridColumn: downLg ? undefined : "span 2",
              p: "34px 20px 20px 20px"
            }}
            textMaxWidth={"480px"}
          />
          <ExampleCard {...cardData[4]} />
          <ExampleCard
            {...cardData[5]}
            sx={{
              gridColumn: downLg ? undefined : "span 3",
            }}
          />
        </Box>
      )}
      <Box mt="55px">
        <Typography color="black" display="inline" fontSize={"18px"}>
          и множество других проектов
        </Typography>
        <Typography
          sx={{
            color: "#7E2AEA",
            display: "inline-block",
            left: "8px",
            position: "relative",
          }}
        >
          *
        </Typography>
      </Box>
    </Section>
  );
}
