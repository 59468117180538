import { useEffect, useState } from "react";
import { Skeleton, SxProps, Theme } from "@mui/material";

type SkeletonImageProps = {
  sx?: SxProps<Theme>;
  width: string;
  height: string;
  image: string;
  Children: JSX.Element;
};

const SkeletonImage = ({ sx, width, height, image, Children }: SkeletonImageProps) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = image;

    backgroundImage.onload = () => {
      setIsLoading(false);
    };

    if (backgroundImage.complete) {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Skeleton sx={{ borderRadius: "8px", ...sx }} variant="rectangular" width={width} height={height} />
      ) : (
        Children
      )}
    </>
  );
};

export default SkeletonImage;
