import { Typography, Box, SxProps, Theme } from "@mui/material";

interface Props {
  badges: string[];
  text: string;
  sx?: SxProps<Theme>;
}

export default function ChipsTextCard({ badges, text, sx }: Props) {
  return (
    <Box
      sx={{
        p: "20px",
        borderRadius: "8px",
        background: "var(--Bg_2, #FFF)",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {badges.map((badgeText, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: "6px",
              p: "7.5px 12px",
              backgroundColor: "#0000000D",
            }}
          >
            <Typography variant="t1" color="#7E2AEA" fontWeight="500">
              {badgeText}
            </Typography>
          </Box>
        ))}
      </Box>
      <Typography variant="h4" mt="14px">
        {text}
      </Typography>
    </Box>
  );
}
