import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export default function BubblesBack({ style }: Props) {
  return (
    <svg
      width="775"
      height="794"
      viewBox="0 0 775 794"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <circle
        cx="551.847"
        cy="757.847"
        r="28.6748"
        transform="rotate(-72.8761 551.847 757.847)"
        fill="url(#paint0_linear_202_7190)"
      />
      <circle
        cx="493.116"
        cy="449.024"
        r="224.9"
        transform="rotate(-72.8761 493.116 449.024)"
        fill="url(#paint1_linear_202_7191)"
      />
      <circle
        cx="272.831"
        cy="191.831"
        r="135.853"
        transform="rotate(-72.8761 272.831 191.831)"
        fill="url(#paint2_linear_202_7192)"
      />
      <circle
        opacity="0.4"
        cx="589.055"
        cy="70.0546"
        r="56.0387"
        transform="rotate(-72.8761 589.055 70.0546)"
        fill="url(#paint3_linear_202_7193)"
        fillOpacity="0.7"
      />
      <g filter="url(#filter0_d_202_719)">
        <circle
          cx="471.437"
          cy="501.677"
          r="81.7865"
          transform="rotate(-72.8761 471.437 501.677)"
          fill="url(#paint4_linear_202_7194)"
        />
      </g>
      <circle
        cx="25.842"
        cy="181.216"
        r="20.5655"
        transform="rotate(-72.8761 25.842 181.216)"
        fill="url(#paint5_linear_202_7195)"
        fillOpacity="0.2"
      />
      <defs>
        <filter
          id="filter0_d_202_719"
          x="370.629"
          y="415.868"
          width="189.617"
          height="189.617"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-6" dy="9" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.570394 0 0 0 0 0.317274 0 0 0 0 0.895833 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_202_719" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_202_719" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_202_7190"
          x1="574.875"
          y1="739.903"
          x2="551.847"
          y2="786.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B78EEC" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_202_7191"
          x1="680.514"
          y1="456.044"
          x2="411.855"
          y2="723.504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_202_7192"
          x1="402.477"
          y1="97.6765"
          x2="272.831"
          y2="327.685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9D9FF" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_202_7193"
          x1="589.055"
          y1="14.0158"
          x2="589.055"
          y2="126.093"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_202_7194"
          x1="556.369"
          y1="465.111"
          x2="377.857"
          y2="544.361"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" stopOpacity="0.87" />
          <stop offset="1" stopColor="#C3A1ED" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_202_7195"
          x1="25.842"
          y1="160.65"
          x2="25.842"
          y2="201.781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
      </defs>
    </svg>
  );
}
