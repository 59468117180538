import {} from "@mui/material/styles";
import {Typography, Box, useMediaQuery, useTheme, Button} from "@mui/material";

import phoneImage from "../../../image/img_mobile/phone5.png";
import phoneMobil from "../../../image/img_mobile/img_phone_bubbles_2.png";
import EmailForm from "../../../kit/EmailForm";
import {setIsContactFormOpen} from "../../../stores/contactForm";

export default function Section7() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  return (
    <Box
      component="section"
      sx={{
        maxWidth: "1200px",
        fontFamily: "Rubik",
        backgroundColor: "#F2F3F7",

        position: "relative",
        ml: "auto",
        mr: "auto",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
          alignItems: "center",
        },
      }}
    >
      {isMobile ? (
        <Box sx={{ width: "100%", bgcolor: "white", display: "flex", justifyContent: "center" }}>
          <Box component="img" src={phoneMobil} sx={{ display: "block", mt: "15px", mb: "20px" }} />
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "13px",
            left: isTablet ? "204px" : "143px",
            width: "100%",
            height: "100%",
          }}
        >
          <img
            src={phoneImage}
            alt="phoneImage"
            onDragStart={(e) => e.preventDefault()}
            style={{
              position: "absolute",
              width: isTablet ? "1150px" : "1300px",
              top: isTablet ? "-258px" : "-346px",
              left: isTablet ? "108px" : "180px",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.down("md")]: {
            pl: "16px",
            pr: "16px",
          },
        }}
      >
        <Box
          sx={{
            maxWidth: "1160px",
            width: "100%",
            p: isMobile ? "10px 0 50px" : isTablet ? "100px 40px" : "100px 0px",
            position: "relative",
            zIndex: theme.zIndex.content,
          }}
        >
          <Typography variant={isMobile ? "tellOrderMobile" : "tellOrder"} component="h1" sx={{ maxWidth: "660px" }}>
            Закажите консультацию
          </Typography>
          <Typography variant="t1" sx={{ maxWidth: "560px", mt: "30px" }}>
              Напишите нам по поводу проекта, мы свяжемся с вами для детального обсуждения. Отвечаем на все заявки без исключения. Работаем 24/7.
          </Typography>
            <Box
                zIndex={theme.zIndex.content}
                sx={{
                    position: "relative",
                    display: "flex",
                    gap: "30px",
                    mt: "40px",
                    alignItems: "center",
                    flexWrap: "wrap"
                }}
            >
                <Button
                    onClick={() => setIsContactFormOpen(true)}
                    disableRipple
                    variant={isMobile ? "orderServiceMobile" : "orderService"}
                    sx={{
                        padding: "9px 50px",
                        whiteSpace: "nowrap",
                    }}
                >
                    Заполнить форму
                </Button>
            </Box>
        </Box>
      </Box>
    </Box>
  );
}
