import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export default function Bubbles({ style }: Props) {
  return (
    <svg
      width="1378"
      height="1389"
      viewBox="0 0 1378 1389"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M314.72 850.306C307.821 894.305 288.309 907.122 295.635 1001.97C302.961 1096.81 454.328 1196.95 548.865 1146.47C660.971 1086.61 814.867 1223.34 836.964 1204.09C887.776 1159.81 568.353 990.769 883.968 904.01C1199.58 817.251 1167.89 487.765 1063.55 330.975C959.216 174.186 888.107 371.028 695.206 310.899C619.323 287.245 537.59 288.951 543.68 433.624C549.769 578.298 485.082 730.907 421.178 744.28C357.274 757.653 323.344 795.306 314.72 850.306Z"
        fill="url(#paint0_linear_3_67)"
      />
      <circle
        cx="548.009"
        cy="694.009"
        r="192.599"
        transform="rotate(-47.9776 548.009 694.009)"
        fill="url(#paint1_linear_3_67)"
      />
      <path
        d="M950.065 267.743C970.535 308.022 979.624 394.448 981.61 432.626C984.219 466.075 1030.38 763.136 776.175 733.293C521.966 703.449 602.423 483.932 662.123 444.938C721.824 405.944 736.14 405.199 736.025 347.783C735.91 290.367 750.409 237.933 806.335 209.184C862.262 180.436 924.477 217.395 950.065 267.743Z"
        fill="url(#paint2_linear_3_67)"
      />
      <circle
        cx="645.415"
        cy="1096.06"
        r="59.0404"
        transform="rotate(-47.9776 645.415 1096.06)"
        fill="url(#paint3_linear_3_67)"
      />
      <circle
        cx="238.448"
        cy="754.447"
        r="52.7132"
        transform="rotate(-47.9776 238.448 754.447)"
        fill="url(#paint4_linear_3_67)"
        fillOpacity="0.2"
      />
      <circle
        cx="427.023"
        cy="339.023"
        r="18.4258"
        transform="rotate(-47.9776 427.023 339.023)"
        fill="url(#paint5_linear_3_67)"
        fillOpacity="0.45"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_67"
          x1="580.281"
          y1="308.291"
          x2="812.506"
          y2="798.654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B379FD" />
          <stop offset="0.432274" stopColor="#7E2AEA" stopOpacity="0" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_67"
          x1="702.683"
          y1="573.485"
          x2="384.299"
          y2="692.853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AE70FF" stopOpacity="0.61" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3_67"
          x1="858.786"
          y1="196.407"
          x2="618.741"
          y2="659.655"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3A1ED" />
          <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3_67"
          x1="645.415"
          y1="1037.02"
          x2="645.415"
          y2="1155.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#945CDB" />
          <stop offset="1" stopColor="#945CDB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3_67"
          x1="238.448"
          y1="701.734"
          x2="289.122"
          y2="826.991"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3_67"
          x1="427.023"
          y1="320.597"
          x2="427.023"
          y2="357.449"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4A4ED" />
          <stop offset="1" stopColor="#7E2AEA" />
        </linearGradient>
      </defs>
    </svg>
  );
}
