import { CSSProperties } from "react";


interface Props {
    style?: CSSProperties;
}

export default function Bubbles({ style }: Props) {

    return (
        <svg width="947" height="1020" viewBox="0 0 947 1020" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...style }}>
            <path d="M265.517 724.989C265.516 541.989 190.539 763.894 179.393 724.989C165.46 676.358 179.021 632.343 226.576 596.873C274.131 561.404 325.883 531.083 265.517 414.169C205.15 297.256 271.387 250.311 343.458 255.048C470.434 263.393 508.759 41.3649 654.738 130.679C800.717 219.992 952.888 479.438 725.942 671.689C674.203 715.519 650.317 740.293 643.98 753.084C665.379 751.085 686.658 751.065 705.914 751.49C747.817 738.178 793.19 722.677 783.017 745.489C778.639 755.305 746.848 752.393 705.914 751.49C668.502 763.375 633.857 773.515 643.98 753.084C598.154 757.365 551.773 770.72 523.517 808.216C464.91 885.989 265.518 889.489 265.517 724.989Z" fill="url(#paint0_linear_202_804)" />
            <circle cx="301.032" cy="662.247" r="224.758" transform="rotate(-72.8761 301.032 662.247)" fill="url(#paint1_linear_202_804)" />
            <path d="M288.394 706.21C273.45 737.469 276.431 768.335 288.394 793.312C311.082 840.683 378.063 871.273 429.74 840.683C476.07 821.401 507.5 850.736 552.5 856.001C597.5 861.267 616.773 839.501 621.5 826.501C626.227 813.501 664 759.002 488 668.501C471.067 659.794 443.573 644.438 391.311 641.619C339.049 638.799 307.074 667.136 288.394 706.21Z" fill="url(#paint2_linear_202_804)" />
            <path d="M552.104 161.152C584.386 186.519 624.941 254.276 641.183 284.984C656.129 311.554 807.825 538.738 586.883 611.391C365.942 684.045 348.277 472.334 382.565 417.355C416.854 362.377 428.369 356.286 406.308 309.006C384.248 261.727 376.139 212.963 411.237 167.872C446.334 122.781 511.753 129.442 552.104 161.152Z" fill="url(#paint3_linear_202_804)" />
            <circle cx="422.879" cy="495.88" r="47.8993" transform="rotate(-72.8761 422.879 495.88)" fill="url(#paint4_linear_202_804)" />
            <circle cx="166.656" cy="382.391" r="34.2012" transform="rotate(-72.8761 166.656 382.391)" fill="url(#paint5_linear_202_804)" />
            <circle cx="330.333" cy="193.576" r="16.5805" transform="rotate(-72.8761 330.333 193.576)" fill="#7E2AEA" />
            <circle cx="535.207" cy="886.536" r="70.0723" transform="rotate(-72.8761 535.207 886.536)" fill="url(#paint6_linear_202_804)" />
            <defs>
                <linearGradient id="paint0_linear_202_804" x1="247.759" y1="296.858" x2="626.76" y2="612.184" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B379FD" />
                    <stop offset="0.695122" stopColor="#5613AC" />
                    <stop offset="0.994792" stopColor="#5613AC" stopOpacity="0.21" />
                </linearGradient>
                <linearGradient id="paint1_linear_202_804" x1="481.533" y1="521.599" x2="301.032" y2="887.006" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AE70FF" stopOpacity="0.61" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_202_804" x1="306.5" y1="666.502" x2="587.5" y2="911.002" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BE99ED" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_202_804" x1="449.58" y1="137.275" x2="428.952" y2="610.927" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C3A1ED" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear_202_804" x1="422.879" y1="447.981" x2="422.879" y2="543.779" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
                <linearGradient id="paint5_linear_202_804" x1="166.656" y1="348.19" x2="166.656" y2="416.592" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
                <linearGradient id="paint6_linear_202_804" x1="535.207" y1="816.464" x2="535.207" y2="956.608" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#945CDB" />
                    <stop offset="1" stopColor="#945CDB" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}