import { Typography, useMediaQuery, useTheme, Box } from "@mui/material";

import SectionStyled from "../../kit/section";

import MiniIconCard from "../../kit/MiniIconCard";

const cardSx = {
  flex: "1 1 0",
  minWidth: "280px",
  maxWidth: "360px",
  boxShadow: `
        0px 100px 80px rgba(126, 42, 234, 0.07), 
        0px 41.7776px 33.4221px rgba(126, 42, 234, 0.0503198), 
        0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275), 
        0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035), 
        0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725), 
        0px 2.76726px 2.21381px rgba(126, 42, 234, 0.0196802)
    `,
};

const CardArray: { icon: string; title: string }[] = [
  { icon: "1", title: "Сокращение Time To Market" },
  { icon: "2", title: "Топовый мониторинг вашей системы" },
  { icon: "3", title: "Быстрая аналитика инцидентов" },
  { icon: "4", title: "Ваши релизы становятся более стабильными" },
  { icon: "5", title: "Повышение безопасности данных" },
  { icon: "6", title: "Улучшение управления системами" },
];

export default function Benefit() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  return (
    <SectionStyled
      tag={"section"}
      bg={"#7E2AEA"}
      mwidth={"1200px"}
      sxsect={{
        p: isMobile ? "50px 0" : "100px 0",
      }}
    >
      <Box>
        <Typography variant={isMobile ? "h4" : "h3"} color="white">
          Что вы получите?
        </Typography>
        <Typography
          sx={{
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "21px",
            marginTop: "20px",
            maxWidth: "420px",
          }}
        >
          Наша команда нашла золотой баланс между иновационностью подхода и стоимостью разработки
        </Typography>
      </Box>
      <Box
        sx={{
          mt: isMobile ? "40px" : "45px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          gap: isMobile ? "25px 10px" : isTablet ? "40px 10px" : "40px 40px",
        }}
      >
        {CardArray.map((elem, index) => {
            let maxWtext = "275px"
            if (index == 5) {maxWtext = "283px"}
            if (index == 1) {maxWtext = "260px"}
            return (
                <MiniIconCard
                    key={index}
                    icon={elem.icon}
                    title={elem.title}
                    sxbox={{
                            flex: "1 1 0",
                            minWidth: "280px",
                            maxWidth: "360px",
                            minHeight: isMobile ? "144px" : isTablet ? "177px" : "147px",
                            padding: isTablet ? "20px 12px 20px 20px" : "20px 20px",
                            boxShadow: `
                                    0px 100px 80px rgba(126, 42, 234, 0.07), 
                                    0px 41.7776px 33.4221px rgba(126, 42, 234, 0.0503198), 
                                    0px 22.3363px 17.869px rgba(126, 42, 234, 0.0417275), 
                                    0px 12.5216px 10.0172px rgba(126, 42, 234, 0.035), 
                                    0px 6.6501px 5.32008px rgba(126, 42, 234, 0.0282725), 
                                    0px 2.76726px 2.21381px rgba(126, 42, 234, 0.0196802)
                                `,
                        }}
                    sxtitle={{maxWidth: maxWtext}}
                />
            )

        })}
      </Box>
    </SectionStyled>
  );
}