import { CSSProperties } from "react";


interface Props {
    style?: CSSProperties;
}

export default function Bubbles({ style }: Props) {

    return (
        <svg width="1052" height="1153" viewBox="0 0 1052 1153" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...style }}>
            <path d="M213.392 724.988C224.538 763.893 270.177 902.131 312.499 977.5C354.821 1052.87 517.892 1077.5 576.499 999.728C645.999 907.5 825.152 961.323 835.999 937C860.942 881.07 532.996 863.94 759.942 671.688C986.887 479.437 834.717 219.991 688.737 130.678C542.758 41.364 504.433 263.392 377.457 255.047C305.386 250.31 239.15 297.255 299.516 414.169C359.882 531.082 308.13 561.403 260.575 596.872C213.02 632.342 199.459 676.358 213.392 724.988Z" fill="url(#paint0_linear_201_1780)" />
            <circle cx="335.032" cy="662.246" r="224.758" transform="rotate(-72.8761 335.032 662.246)" fill="url(#paint1_linear_201_1780)" />
            <path d="M322.393 706.209C307.449 737.468 310.43 768.334 322.393 793.311C345.082 840.682 412.062 871.272 463.739 840.682C510.069 821.4 541.499 850.735 586.499 856C631.499 861.266 650.772 839.5 655.499 826.5C660.226 813.5 697.999 759 521.999 668.5C505.066 659.793 477.573 644.437 425.31 641.618C373.048 638.798 341.073 667.135 322.393 706.209Z" fill="url(#paint2_linear_201_1780)" />
            <path d="M586.103 161.151C618.385 186.518 658.94 254.275 675.182 284.983C690.128 311.553 841.824 538.737 620.882 611.39C399.941 684.044 382.276 472.333 416.564 417.354C450.853 362.376 462.368 356.285 440.307 309.005C418.247 261.726 410.138 212.962 445.236 167.871C480.333 122.78 545.752 129.441 586.103 161.151Z" fill="url(#paint3_linear_201_1780)" />
            <circle cx="272.918" cy="555.996" r="47.8993" transform="rotate(-72.8761 272.918 555.996)" fill="url(#paint4_linear_201_1780)" />
            <circle cx="200.655" cy="382.39" r="34.2012" transform="rotate(-72.8761 200.655 382.39)" fill="url(#paint5_linear_201_1780)" />
            <circle cx="364.332" cy="193.575" r="16.5805" transform="rotate(-72.8761 364.332 193.575)" fill="#8D47E7" />
            <circle cx="645.066" cy="939.067" r="53.6488" transform="rotate(-72.8761 645.066 939.067)" fill="url(#paint6_linear_201_1780)" />
            <defs>
                <linearGradient id="paint0_linear_201_1780" x1="281.736" y1="296.863" x2="660.736" y2="612.189" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B379FD" />
                    <stop offset="0.695122" stopColor="#5613AC" />
                </linearGradient>
                <linearGradient id="paint1_linear_201_1780" x1="515.532" y1="521.598" x2="335.032" y2="887.005" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AE70FF" stopOpacity="0.61" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_201_1780" x1="340.499" y1="666.501" x2="621.499" y2="911.001" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BE99ED" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_201_1780" x1="483.579" y1="137.274" x2="462.951" y2="610.926" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C3A1ED" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear_201_1780" x1="272.918" y1="508.097" x2="272.918" y2="603.895" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
                <linearGradient id="paint5_linear_201_1780" x1="200.655" y1="348.189" x2="200.655" y2="416.591" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4A4ED" />
                    <stop offset="1" stopColor="#7E2AEA" />
                </linearGradient>
                <linearGradient id="paint6_linear_201_1780" x1="645.066" y1="885.419" x2="645.066" y2="992.716" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#945CDB" />
                    <stop offset="1" stopColor="#945CDB" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}