import { ReactNode } from "react";
import { Typography, Box, SxProps, Theme } from "@mui/material";

import SkeletonIcons from "../../../kit/SkeletonIcon";

interface Props {
  icon: ReactNode;
  text: string;
  sx?: SxProps<Theme>;
}

export default function TechnologyCard({ icon, text, sx }: Props) {
  return (
    <Box
      sx={{
        borderRadius: "8px",
        backgroundColor: "#7E2AEA",
        p: "20px",
        display: "flex",
        gap: "30px",
        alignItems: "center",
        ...sx,
      }}
    >
      <SkeletonIcons
        src={icon}
        alt="icon"
        sx={{
          backgroundColor: "#5A1EA8",
          width: "59px",
          height: "59px",
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
        }}
      />
      <Typography
        variant="h4"
        sx={{
          color: "white",
            fontWeight: 400,
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
